/*
 * 50/50 Snippet
 *
 * following modifiers go on .grid-2col-container element
 *
 * reverse             : swaps the two columns
 * grid-#-#            : overwrites column sizes
 * column-size-swap    : swaps the column sizes for viewport between 640px and 768px (this is for wider images)
 * divider             : horizontal divider
 * grid-vertical-align : vertical align content
 */

.grid-2col-container {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    grid-template-areas:
        "img"
        "text"
}
.grid-2col-container.grid-vertical-align > .grid-col {
    -ms-grid-row-align: center;
    align-self: center;
}
.grid-2col-container > .grid-img-col {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
    grid-area: img;
}
.grid-2col-container > .grid-text-col {
    -ms-grid-row: 2;
    -ms-grid-column: 1;
    grid-area: text;
    padding: 15px;
}
.grid-img-col > a {
    display: block;
}

.grid-2col-container + .grid-2col-container,
.text-wrap-container + .grid-2col-container,
.grid-2col-container + .text-wrap-container,
.text-wrap-container + .text-wrap-container {
    border-top: 1px solid #ccc;
    padding: 15px 0;
}

@media (min-width: 640px) {
    .grid-2col-container {
        padding: 15px 0;
        -ms-grid-columns: (1fr)[2];
        grid-template-columns: repeat(2, 1fr);
        grid-template-areas:
            "img text";
    }
    
    .grid-2col-container > .grid-img-col,
    .grid-2col-container.reverse > .grid-col.grid-text-col {
        -ms-grid-row: 1;
        -ms-grid-column: 1;
        grid-area: img;
    }
    .grid-2col-container > .grid-text-col,
    .grid-2col-container.reverse > .grid-col.grid-img-col {
        -ms-grid-row: 1;
        -ms-grid-column: 2;
        grid-area: text;
    }
}

@media (min-width: 900px) {
    .grid-2col-container:not(.divider),
    .grid-2col-container:not(.divider) + .grid-2col-container {
        padding: 0;
        border-top: none;
    }
}

/*
 * Grid 2 Cols Modifiers
 */
.grid-2-10,
.grid-3-9,
.grid-4-8,
.grid-5-7,
.grid-6-6,
.grid-7-5,
.grid-8-4,
.grid-9-3,
.grid-10-2 {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
}
@media (min-width: 640px) {
    .grid-1-11 {
        -ms-grid-columns: 1fr 11fr;
        grid-template-columns: 1fr 11fr;
    }
    .grid-2-10 {
        -ms-grid-columns: 2fr 10fr;
        grid-template-columns: 2fr 10fr;
    }
    .grid-3-9 {
        -ms-grid-columns: 3fr 9fr;
        grid-template-columns: 3fr 9fr;
    }
    .grid-4-8 {
        -ms-grid-columns: 4fr 8fr;
        grid-template-columns: 4fr 8fr;
    }
    .grid-5-7 {
        -ms-grid-columns: 5fr 7fr;
        grid-template-columns: 5fr 7fr;
    }
    .grid-6-6 {
        -ms-grid-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
    }
    .grid-7-5 {
        -ms-grid-columns: 7fr 5fr;
        grid-template-columns: 7fr 5fr;
    }
    .grid-8-4 {
        -ms-grid-columns: 8fr 4fr;
        grid-template-columns: 8fr 4fr;
    }
    .grid-9-3 {
        -ms-grid-columns: 9fr 3fr;
        grid-template-columns: 9fr 3fr;
    }
    .grid-10-2 {
        -ms-grid-columns: 10fr 2fr;
        grid-template-columns: 10fr 2fr;
    }
    .grid-11-1 {
        -ms-grid-columns: 11fr 1fr;
        grid-template-columns: 11fr 1fr;
    }
}

@media (min-width: 640px) and (max-width: 768px) {
    .grid-2-10.column-size-swap {
        -ms-grid-columns: 10fr 2fr;
        grid-template-columns: 10fr 2fr;
    }
    .grid-3-9.column-size-swap {
        -ms-grid-columns: 9fr 3fr;
        grid-template-columns: 9fr 3fr;
    }
    .grid-4-8.column-size-swap {
        -ms-grid-columns: 8fr 4fr;
        grid-template-columns: 8fr 4fr;
    }
    .grid-5-7.column-size-swap {
        -ms-grid-columns: 7fr 5fr;
        grid-template-columns: 7fr 5fr;
    }
    .grid-6-6.column-size-swap {
        -ms-grid-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
    }
    .grid-7-5.column-size-swap {
        -ms-grid-columns: 5fr 7fr;
        grid-template-columns: 5fr 7fr;
    }
    .grid-8-4.column-size-swap {
        -ms-grid-columns: 4fr 8fr;
        grid-template-columns: 4fr 8fr;
    }
    .grid-9-3.column-size-swap {
        -ms-grid-columns: 3fr 9fr;
        grid-template-columns: 3fr 9fr;
    }
    .grid-10-2.column-size-swap {
        -ms-grid-columns: 2fr 10fr;
        grid-template-columns: 2fr 10fr;
    }
}
