/* Shared Styling between Cruise Personalizer and Cruise Search Book app for their respective Packages pages. */
.packages-inner {
    margin: 0 20px;
}
.packages-header {
    margin-bottom: 32px;
}
.package-selector-wrapper {
    width: 50%;
}
.package-selector-wrapper button {
    width: fit-content;
}
.package-selector-header {
    font-size: 30px;
    font-family: var(--red-hat-text);
    margin: 0;
}
.package-selector-pricing {
    display: inline-flex;
    flex-flow: wrap;
}
.package-selector-saving {
    color: #D7005B;
}
.package-offers-table-row {
    min-height: 90px;
    border-bottom: 1px solid rgba(108, 108, 120, 0.30);
}
.package-offers-table-cell-eyebrow {
    font-family: var(--red-hat-text-bold);
}
.package-offers-table-cell-details {
    margin-top: 8px;
}
.skip-the-savings-wrapper {
    text-align: right;
}