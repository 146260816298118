@charset "utf-8";
/* CSS Document */

/* normalize.css 2011-11-04T15:38 UTC - http://github.com/necolas/normalize.css
    AND
    a few added elements from HTML5 Boilerplate: h5bp.com/css
    AND
    a whole lot of styles for princess
*/

/* =============================================================================
   HTML5 display definitions
   ========================================================================== */

/*
 * Correct `block` display not defined in IE6/7/8/9 & FF3
 */

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
    display: block;
}

/*
 * Correct `inline-block` display not defined in IE6/7/8/9 & FF3
 */

audio,
canvas,
video,
picture {
    display: inline-block;
    *display: inline;
    *zoom: 1;
}

/*
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */

audio:not([controls]) {
    display: none;
    height: 0;
}

/*
 * Addresses styling for 'hidden' attribute not present in IE7/8/9, FF3, S4
 * Known issue: no IE6 support
 */

[hidden] {
    display: none;
}


/* =============================================================================
   Base
   ========================================================================== */

/*
 * 1. To use the responsive column grid from bootstrap3, we need to reset box-sizing to border-box.
 * 2. To prevent the box-sizing from affecting existing non-responsive pages, we apply a class of .rwd to the new pages to filter out old pages
 */
.border-box-sizing *, .border-box-sizing *:before, .border-box-sizing *:after,
.rwd *, .rwd *:before, .rwd *:after {
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}

/*
 * various flex classes
 */

.flex {
    display: flex;
}
.justify-content-space-between {
    justify-content: space-between;
}
.full-width {
    width: 100%;
}
.full-height {
    height: 100%;
}
.gap-8 {
    gap: 8px;
}
.gap-16 {
    gap: 16px
}
.gap-20 {
    gap: 20px
}
.gap-40 {
    gap: 40px;
}
.align-items-center {
    align-items: center;
}

/*
 * 1. Corrects text resizing oddly in IE6/7 when body font-size is set using em units
 *    http://clagnut.com/blog/348/#c790
 * 2. Keeps page centred in all browsers regardless of content height (Force vertical scrollbar in non-IE)
 * 3. Prevents iOS text size adjust after orientation change, without disabling user zoom
 *    www.456bereastreet.com/archive/201012/controlling_text_size_in_safari_for_ios_without_disabling_user_zoom/
 */

html {
    font-size: 100%; /* 1 */
    overflow-y: scroll; /* 2 */
    -webkit-text-size-adjust: 100%; /* 3 */
    -ms-text-size-adjust: 100%; /* 3 */
}
html.overflow-y-auto {
    overflow-y: auto;
}

/*
 * Addresses margins handled incorrectly in IE6/7
 */

body {
    margin: 0;
    font: 87.5%/1.285714285714286 var(--red-hat-text); /*Translate to 14px/18px*/
    background: #232323;
}
.web-font body {
    font: 87.5%/1.285714285714286 var(--red-hat-text); /*Translate to 14px/18px*/
    letter-spacing: 0.1px;
}

/*
 * Addresses font-family inconsistency between 'textarea' and other form elements.
 */

body,
button,
input,
select,
textarea {
    font-family: var(--red-hat-text);
    color: #000;
}

.web-font body,
.web-font button,
.web-font input,
.web-font select,
.web-font textarea {
    font-family: var(--red-hat-text);
}

/*
 * Remove text-shadow in selection highlight: h5bp.com/i
 * These selection declarations have to be separate
 * Also: hot pink! (or customize the background color to match your design)
 */

::-moz-selection {
    background: #3c5e8c; color: #fff; text-shadow: none;
}
::selection {
    background: #3c5e8c; color: #fff; text-shadow: none;
}


/* =============================================================================
   Links
   ========================================================================== */


/**
 * 1. Remove the gray background on active links in IE 10.
 * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
 */
a {
    color: var(--primary-blue);
    background-color: transparent; /* 1 */
    -webkit-text-decoration-skip: objects; /* 2 */
}

/**
 * Default styles for handling display of focus, sources from Bootstrap 4
 */
a:hover, a:focus {
    text-decoration: none;
}
a:focus,
a:focus-visible{
    outline: 2px dotted rgba(255, 255, 255, .8);
    outline-offset: -2px;
    box-shadow: inset 0 0 0 2px rgba(0, 0, 0, .8);
}
a:focus img, a:focus picture {
    clip-path: inset(2px 2px 2px 2px);
}
a:not([href]):not([tabindex]) {
    color: inherit;
    text-decoration: none;
}
a:not([href]):not([tabindex]):focus, a:not([href]):not([tabindex]):hover {
    color: inherit;
    text-decoration: none;
}
a:not([href]):not([tabindex]):focus {
    outline: 0;
}
.no-focus {
    outline: none;
    box-shadow: none;
}


/*
 * Improve readability when focused and also mouse hovered in all browsers.
 * people.opera.com/patrickl/experiments/keyboard/test
 */
a:hover,
a:active {
    outline: 0;
    box-shadow: none;
}

/*
 * Change the text-decoration
 */
.invert-decoration, h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
    text-decoration: none;
}
.invert-decoration:hover, h1 a:hover, h2 a:hover, h3 a:hover, h4 a:hover, h5 a:hover, h6 a:hover,
.invert-decoration:focus, h1 a:focus, h2 a:focus, h3 a:focus, h4 a:focus, h5 a:focus, h6 a:focus  {
    text-decoration: underline;
}
.no-decoration, .no-decoration:hover, .no-decoration:focus {
    text-decoration: none;
}
.line-through {
    text-decoration: line-through;
}

/* =============================================================================
   Typography
   ========================================================================== */

/*
 * Neutralize smaller font-size in 'section' and 'article' in FF4+, Chrome, S5
 */
h1 {
    font-size: 1.38461538461538em; /*18px*/
    font-weight: 400;
    margin: .6666666em 0 .5555555em; /*12px 0 10px*/
}
h2 {
    color: #000; /*#2e2e2e*/
    font-size: 1.38461538461538em;/* 18px old was 16px*/
    font-weight: 700;
    margin: .8125em 0 0; /*13px 0 0*/
    /*clear: both;*/
}
h3 {
    color: #000;
    font-size: 1.07692307692308em; /* 14px */
    font-weight: 700;
    margin: .8125em 0 .5em;
}
h4 {
    color: #000;
    font-size: 1em; /* 13px */
    font-weight: 700;
    margin: 0 5px;
    margin: .8125em 0 0;
}
h5 {
    color: #000;
    font-size: .916em; /* 11px */
    font-weight: 700;
    margin: .8125em 0 0;
}
h6 {
    font-size:.916em; /* 11px */
    line-height: 1.27;
    margin: .8125em 0 0;
}
h1:first-child, h2:first-child, h3:first-child, h4:first-child, h5:first-child, h6:first-child {
    margin-top: 0;
}
.web-font h1,
.web-font h2,
.web-font h3,
.web-font h4,
.web-font h5,
.web-font h6 {
    font-family:  var(--roboto-condensed);
    font-weight: 400;
    letter-spacing: 0;
}
.web-font h1 {
    font-size: 1.714285714285714em; /* 24px */
}
.web-font h2,
.web-font h3 {
    font-size: 1.285714285714286em; /* 18px */
}
.web-font h4,
.web-font h5,
.web-font h6 {
    font-size: 1.142857142857143em; /* 16px */
}
.headline-gray-e6-bg {
    background: #e6e6e6;
    color: #000;
    padding: .5555555555555556em .8333333333333333em;
}
.headline-white-fff-bg {
    background: #fff;
    color: #000;
    padding: .5555555555555556em .8333333333333333em;
}
.headline-gray-999-bg {
    background: #999;
    color: #fff;
    padding: .5555555555555556em .8333333333333333em;
}
.headline-gray-666-bg {
    background: #666;
    color: #fff;
    padding: .5555555555555556em .8333333333333333em;
}
.headline-blue-007ba3-bg {
    background: #007ba3;
    color: #fff;
    padding: .5555555555555556em .8333333333333333em;
}
.headline-green-638325-bg {
    background: #638325;
    color: #fff;
    padding: .5555555555555556em .8333333333333333em;
}
.headline-blue-gradient-bg, .headline-in-box {
    background: #007ba0;
    background: -moz-linear-gradient(top, #3d9ab7 0%, #007ba0 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#3d9ab7), color-stop(100%,#007ba0));
    background: -webkit-linear-gradient(top, #3d9ab7 0%, #007ba0 100%);
    background: -o-linear-gradient(top, #3d9ab7 0%, #007ba0 100%);
    background: -ms-linear-gradient(top, #3d9ab7 0%, #007ba0 100%);
    background: linear-gradient(to bottom, #3d9ab7 0%, #007ba0 100%);
    color: #fff;
    padding: .5555555555555556em .8333333333333333em;
}

/* Headline Bar */
.headline-bar {
    padding: 30px 0;
    position: relative;
    text-align: center;
}
.headline-bar-wrapper {
    position: relative;
}
.headline-bar > .headline-gray-e6-bg,
.headline-bar > .headline-white-fff-bg,
.headline-bar > .headline-gray-999-bg,
.headline-bar > .headline-gray-666-bg,
.headline-bar > .headline-blue-007ba3-bg,
.headline-bar > .headline-green-638325-bg,
.headline-bar > .headline-blue-gradient-bg,
.headline-bar > .headline-in-box {
    padding: .7142857142857143em 1.6em;
    position: relative;
    z-index: 1;
}

.full-width .headline-bar > .headline-gray-e6-bg:before,
.full-width .headline-bar > .headline-white-fff-bg:before,
.full-width .headline-bar > .headline-gray-999-bg:before,
.full-width .headline-bar > .headline-gray-666-bg:before,
.full-width .headline-bar > .headline-blue-007ba3-bg:before,
.full-width .headline-bar > .headline-green-638325-bg:before,
.full-width .headline-bar > .headline-blue-gradient-bg:before,
.full-width .headline-bar > .headline-in-box:before {
    content: "";
    display: block;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
}
@media screen and (min-width: 61.875em) {
    .full-width .headline-bar > .headline-gray-e6-bg:before,
    .full-width .headline-bar > .headline-white-fff-bg:before,
    .full-width .headline-bar > .headline-gray-999-bg:before,
    .full-width .headline-bar > .headline-gray-666-bg:before,
    .full-width .headline-bar > .headline-blue-007ba3-bg:before,
    .full-width .headline-bar > .headline-green-638325-bg:before,
    .full-width .headline-bar > .headline-blue-gradient-bg:before,
    .full-width .headline-bar > .headline-in-box:before {
        left: 50%;
        margin-left: -50%; /* for browsers that don't support calc, we're setting the margin-left to -50% to offset the left position */
        margin-left: calc(-100vw / 2);
        max-width: 1700px;
        width: 100vw;
    }
}
@media screen and (min-width: 106.25em) {
    .full-width .headline-bar > .headline-gray-e6-bg:before,
    .full-width .headline-bar > .headline-white-fff-bg:before,
    .full-width .headline-bar > .headline-gray-999-bg:before,
    .full-width .headline-bar > .headline-gray-666-bg:before,
    .full-width .headline-bar > .headline-blue-007ba3-bg:before,
    .full-width .headline-bar > .headline-green-638325-bg:before,
    .full-width .headline-bar > .headline-blue-gradient-bg:before,
    .full-width .headline-bar > .headline-in-box:before {
        margin-left: -850px;
    }
}
.full-width .headline-bar > .headline-gray-e6-bg:before {
    background-color: #e6e6e6;
}
.full-width .headline-bar > .headline-white-fff-bg:before {
    background-color: #fff;
}
.full-width .headline-bar > .headline-gray-999-bg:before {
    background-color: #999;
}
.full-width .headline-bar > .headline-gray-666-bg:before {
    background-color: #666;
}
.full-width .headline-bar > .headline-blue-007ba3-bg:before {
    background-color: #007ba3;
}
.full-width .headline-bar > .headline-green-638325-bg:before {
    background-color: #638325;
}
.full-width .headline-bar > .headline-blue-gradient-bg:before, .full-width .headline-bar > .headline-in-box:before {
    background: #007ba0;
    background: -moz-linear-gradient(top, #3d9ab7 0%, #007ba0 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#3d9ab7), color-stop(100%,#007ba0));
    background: -webkit-linear-gradient(top, #3d9ab7 0%, #007ba0 100%);
    background: -o-linear-gradient(top, #3d9ab7 0%, #007ba0 100%);
    background: -ms-linear-gradient(top, #3d9ab7 0%, #007ba0 100%);
    background: linear-gradient(to bottom, #3d9ab7 0%, #007ba0 100%);
}

.two-line > .headline-gray-e6-bg:first-child,
.two-line > .headline-white-fff-bg:first-child,
.two-line > .headline-gray-999-bg:first-child,
.two-line > .headline-gray-666-bg:first-child,
.two-line > .headline-blue-007ba3-bg:first-child,
.two-line > .headline-green-638325-bg:first-child,
.two-line > .headline-blue-gradient-bg:first-child,
.two-line > .headline-in-box:first-child,
.two-line > div:first-child {
    padding-bottom: 0;
}
.two-line > .headline-gray-e6-bg:last-child,
.two-line > .headline-white-fff-bg:last-child,
.two-line > .headline-gray-999-bg:last-child,
.two-line > .headline-gray-666-bg:last-child,
.two-line > .headline-blue-007ba3-bg:last-child,
.two-line > .headline-green-638325-bg:last-child,
.two-line > .headline-blue-gradient-bg:last-child,
.two-line > .headline-in-box:last-child,
.two-line > div:last-child {
    padding: .5em 2.8em 1.6em;
}

/* Accolades */
.accolade {
    display: inline-block;
    margin: 0 auto;
    padding: .5em 3em;
    position: relative;
}
.accolade > .accolade-txt {
    font-family:  var(--red-hat-text);
    font-size: 1.285714285714286em;
    letter-spacing: 1px;
    text-transform: uppercase;
}
.accolade > .laurel-left, .accolade > .laurel-right {
    position: absolute;
    margin-top: -24px;
    top: 50%;
}
.accolade > .laurel-left {
    left: 0;
}
.accolade > .laurel-right {
    right: 0;
}
.svg-icon.svg-icon-laurel {
    width: 70px;
    height: 70px;
    position: absolute;
    margin-top:-25px;
    top: 40%;
}
.svg-icon.svg-icon-laurel.svg-left {
    -moz-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    left: -15px;
}
.svg-icon.svg-icon-laurel.svg-right {
    right: -15px;
}
/* Live Text Accolades */
.accolade .accolade-txt,
.accolade .accolade-src {
    color: #000;
}
.accolade-container {
    padding-top: 15px;
}
.accolade-container .svg-icon.svg-icon-laurel {
    height: 60px;
    margin-top: -16px;
}
@media (min-width: 40em) {
    .accolade-container {
        background: #333;
        color: #fff;
        padding-bottom: 15px;
    }
    .accolade-container > .accolade .accolade-txt,
    .accolade-container > .accolade .accolade-src {
        color: #fff;
    }
}


p {
    margin: 1em 0;
}
h2 + p, h3 + p, h4 + p,
h2 + ul, h3 + ul, h4 + ul {
    margin-top: .5em;
}
.caps, .web-font .caps {
    text-transform:uppercase;
}
.no-caps, .web-font .no-caps {
    text-transform:none;
}
.lowercase {
    text-transform: lowercase;
}

/*
 * Web Fonts
 * 03/27/2023 - Global Styling is undergoing an overhaul. To accomodate for the interim period,
 * these Gotham classes will be defined with other values. This is to ensure where all places that are
 * currently utilizing these classes will be referring to the incoming fonts.
 */
.web-font .gotham-xn-book, .gotham-xn-book {
    font-family: var(--roboto-condensed);
}
.web-font .gotham-xn-medium, .gotham-xn-medium {
    font-family: var(--roboto-condensed);
}
.web-font .gotham-book, .gotham-book {
    font-family: var(--red-hat-text);
}
.web-font .gotham-book-it, .gotham-book-it {
    font-family: var(--red-hat-text-it);
}
.web-font .gotham-bold, .gotham-bold {
    font-family: var(--red-hat-text-bold);
}
.web-font .gotham-bold-it, .gotham-bold-it {
    font-family: var(--red-hat-text-bold-it);
}
.web-font .roboto, .roboto {
    font-family: var(--red-hat-text);
}
.web-font .default-sans-serif, .default-sans-serif {
    font-family: var(--default-sans-serif);
}

/**
 * 1. Remove the bottom border in Chrome 57- and Firefox 39-.
 * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
 */

abbr[title] {
  border-bottom: none; /* 1 */
  text-decoration: underline; /* 2 */
  text-decoration: underline dotted; /* 2 */
}

/*
 * Address style set to `bolder` in FF3+, S4/5, Chrome
*/

b,
strong {
    font-weight: bold;
}

.web-font b,
.web-font strong {
    font-family: var(--red-hat-text-bold);
}
.web-font h1 b,
.web-font h1 strong,
.web-font h1 .font-weight-700,
.web-font h2 b,
.web-font h2 strong,
.web-font h2 .font-weight-700,
.web-font h3 b,
.web-font h3 strong,
.web-font h3 .font-weight-700,
.web-font h4 b,
.web-font h4 strong,
.web-font h4 .font-weight-700,
.web-font h5 b,
.web-font h5 strong,
.web-font h5 .font-weight-700,
.web-font h6 b,
.web-font h6 strong,
.web-font h6 .font-weight-700,
.web-font .gotham-xn b,
.web-font .gotham-xn strong {
    font-family: var(--roboto-condensed);
    font-weight: 400;
}
blockquote {
    margin: 1em 40px;
}

/*
 * Address styling not present in S5, Chrome
 */

dfn {
    font-style: italic;
}

/*
 * From HTML Boilerplate
 */
hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0;
}

ins {
    background: #ff9;
    color: #000;
    text-decoration: none;
}

/*
 * Address styling not present in IE6/7/8/9
 */

mark {
    background: #ff0;
    color: #000;
}

/*
 * Corrects font family set oddly in IE6, S4/5, Chrome
 * en.wikipedia.org/wiki/User:Davidgothberg/Test59
 */

pre,
code,
kbd,
samp {
    font-family: monospace, serif;
    _font-family: 'courier new', monospace;
    font-size: 1em;
}

/*
 * Improves readability of pre-formatted text in all browsers
 */

pre {
    white-space: pre;
    white-space: pre-wrap;
    word-wrap: break-word;
}

/*
 * 1. Addresses CSS quotes not supported in IE6/7
 * 2. Addresses quote property not supported in S4
 */

/* 1 */

q {
    quotes: none;
}

/* 2 */

q:before,
q:after {
    content: '';
    content: none;
}

small {
    font-size: 75%;
}

/*
 * Prevents sub and sup affecting line-height in all browsers
 * gist.github.com/413930
 */

sub,
sup {
    font-size: 60%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}

sup {
    top: -0.7em;
}

sub {
    bottom: -0.25em;
}


/* =============================================================================
   Lists
   ========================================================================== */

ul, ol, dl {
    margin: 1em 0;
}
h3 + ul, h3 + ol {
    margin-top: .5em;
}
ul {
    padding: 0 0 0 15px;
}
ol {
    padding: 0 0 0 20px;
}
dt {
    font-weight:700;
}
dd {
    margin:0;
}
nav ul, nav ol,
.nav ul, .nav ol {
    list-style: none;
    list-style-image: none;
    margin:0;
    padding:0;
}
.no-list-style {
    list-style: none;
    list-style-image: none;
    padding: 0;
}
.list-spacing-5 > li + li {
    margin-top:5px;
}
.list-spacing-10 > li + li {
    margin-top:10px;
}
.list-spacing-15 > li + li {
    margin-top:15px;
}
.list-spacing-20 > li + li {
    margin-top:20px;
}
.list-spacing-25 > li + li {
    margin-top:25px;
}
.list-spacing-30 > li + li {
    margin-top:30px;
}


/* =============================================================================
   Embedded content
   ========================================================================== */

/*
 * 1. Remove border when inside `a` element in IE6/7/8/9, FF3
 * 2. Improves image quality when scaled in IE7
 *    code.flickr.com/blog/2008/11/12/on-ui-quality-the-little-things-client-side-image-resizing/
 */

img {
    border: 0; /* 1 */
    -ms-interpolation-mode: bicubic; /* 2 */

}
.img-responsive-wrapper {
    position: relative;
}
.img-responsive, .img-responsive-wrapper img, .img-responsive-wrapper picture {
    display: block;
    height: auto;
    margin: 0 auto;
    max-width: 100%;
}
/* Responsive iframe for all types of media use 'iframe-responsive' */
/* Responsive youtube video embed 'video-responsive' class is wrapper for youtube iframe */
.iframe-responsive, .video-responsive {
    height: 0;
    padding-bottom: 100%;
    position: relative;
}
/* set the iframe to the same width / height as it's fluid parent container */
.iframe-responsive > iframe, .video-responsive iframe, .video-responsive object, .video-responsive embed {
    height: 100% !important;
    left: 0;
    position: absolute;
    top: 0;
    width: 100% !important;
}
/* sizing */
.video-16x9, .video-responsive {
    padding-bottom: 56.25%; /* 16:9 ratio */
}
.video-4x3 {
    padding-bottom: 75%; /* 4:3 ratio */
}

/*
 * Correct overflow displayed oddly in IE9
 */

svg:not(:root) {
    overflow: hidden;
}


/* =============================================================================
   Figures
   ========================================================================== */

/*
 * Addresses margin not present in IE6/7/8/9, S5, O11
 */

figure {
    margin: 0;
}


/* =============================================================================
   Forms
   ========================================================================== */

/*
 * Corrects margin displayed oddly in IE6/7
 */

form {
    margin: 0;
}

form label {
    margin: 0;
}

/*
 * Define consistent border, margin, and padding
 */

fieldset {
    border: none;
    margin: 1em 0;
    padding: 0;
}
fieldset > p:first-child {
    margin-top: 0;
}

/*blocked out fieldset with lightblue background*/
.fieldset-block {
    clear: both;
    background: #e5f3fd;
    margin: 3em 0;
    padding: 15px;
    position: relative;
}
.lt-ie9 .fieldset-block {
    border: 1px solid #e5f3fd;
}
.required-instructions + .fieldset-block {
    margin-top: 1em;
}
/*wrapper for grouping fields togther when a fieldset is not needed*/
.field-group {
    margin: 0;
}

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Remove the padding so developers are not caught out when they zero out
 *    `fieldset` elements in all browsers.
 */
legend {
    border: 0;
    box-sizing: border-box; /* 1 */
    color: inherit; /* 2 */
    display: table; /* 1 */
    font-weight: 400;
    max-width: 100%; /* 1 */
    padding: 0; /* 3 */
    white-space: normal; /* 1 */
}
.lt-ie8 legend {
    margin-left: -7px; /* 2 */
}
.fieldset-block > legend {
    font-size: 1.142857142857143em; /*16px*/
    margin-bottom: .5em;
    position: absolute;
    top: 15px;
    left: 15px;
}
.lt-ie8 .fieldset-block legend {
    margin-left: -7px;
}
fieldset > legend + p, fieldset > legend + .form-field, fieldset > legend + .field-group, fieldset > legend + fieldset {
    margin-top: .5em;
}
.fieldset-block > legend + p, .fieldset-block > legend + .form-field, .fieldset-block > legend + .field-group, .fieldset-block > legend + fieldset {
    margin-top: 2em;
}
.lt-ie8 fieldset > legend + p, .lt-ie8 fieldset > legend + .form-field, .lt-ie8 fieldset > legend + .field-group, .lt-ie8 fieldset > legend + fieldset {
    margin-top: 0;
    padding-top: .5em;
}
.lt-ie8 .fieldset-block > legend + p, .lt-ie8 .fieldset-block > legend + .form-field, .lt-ie8 .fieldset-block > legend + .field-group, .lt-ie8 .fieldset-block > legend + fieldset {
    padding-top: 2em;
}
/* fieldset to appear like horizontal form-field label */
.horizontal-fieldset {
    overflow: hidden;
    margin: 0;
    position: relative;
}
.horizontal-fieldset > legend {
    display: block;
    position: relative;
    left: 0;
    top: 10px;
    padding-bottom: 0.769223em;
}
.horizontal-fieldset > legend + .form-field, fieldset > legend + .inline-field  {
    margin-top: 0;
}
.horizontal-fieldset > legend + .inline-field {
    margin-left: 6px;
}
.form-field.age-check {
    padding-left: 3em;
}

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */

button,
select { /* 1 */
    text-transform: none;
}

/**
 * Correct the inability to style clickable types in iOS and Safari.
 */

button,
[type="button"],
[type="reset"],
[type="submit"] {
    -webkit-appearance: button;
}

/**
 * Remove the inner border and padding in Firefox.
 */

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0;
}

    
/*
 * 1. Corrects font size not being inherited in all browsers
 * 2. Addresses margins set differently in IE6/7, FF3+, S5, Chrome
 * 3. Improves appearance and consistency in all browsers
 */

button,
input,
select,
textarea {
    font-size: 100%; /* 1 */
    margin: .25em 0; /* 2 */
    padding: 4px;
    border: 1px solid #999;
    vertical-align: baseline; /* 3 */
    *vertical-align: middle; /* 3 */
    /*
    -webkit-transition: color 0.2s linear, box-shadow 0.2s linear;
    -moz-transition: color 0.2s linear, box-shadow 0.2s linear;
    -o-transition: color 0.2s linear, box-shadow 0.2s linear;
    transition: color 0.2s linear, box-shadow 0.2s linears;
    */
}
/* for small screen devices, for the font-size to be 16 px */
@media screen and (max-width: 47.9375em) {
    input,
    select,
    textarea {
        font-size: 1.23076923076923em;
        padding-bottom: 2px;
        padding-top: 2px;
        margin-top: .1875em;
        margin-bottom: .1875em;
    }
}
input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
input[type="tel"]:focus,
input[type="checkbox"]:focus,
input[type="radio"]:focus,
input[type="number"]:focus,
input[type="date"]:focus,
select:focus,
textarea:focus {
    box-shadow: 0 0 0 2px #000000;
    outline: 2px dotted rgba(255, 255, 255, .8);
    outline-offset: 0;
}
select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
}
select {
    background-image: url('/images/global/svg-library/arrow.svg');
    background-position: 96% 51%;
    background-repeat: no-repeat;
    background-size: .6em;
    padding-right: 1.2em;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
/* For IE10 and up */
select::-ms-expand {
    display: none;
}
button:focus,
input[type="button"]:focus,
input[type="submit"]:focus,
input[type="reset"]:focus {
    box-shadow: 0 0 0 2px rgba(0, 0, 0, .8);
    outline: 2px dotted rgba(255, 255, 255, .8);
    outline-offset: 0;
}

input[readonly],
input[disabled],
select[disabled],
textarea[readonly],
textarea[disabled]  {
    background: rgba(255,255,255,.3);
    border: 1px solid rgba(153,153,153,.3);
}

input[readonly]:focus,
select[disabled]:focus,
textarea[readonly]:focus {
    border: 1px solid rgba(153,153,153,.3);
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.no-rgba input[readonly], .no-rgba input[readonly]:focus,
.no-rgba input[disabled], .no-rgba input[disabled]:focus,
.no-rgba select[disabled], .no-rgba select[disabled]:focus,
.no-rgba textarea[readonly], .no-rgba textarea[readonly]:focus,
.no-rgba textarea[disabled], .no-rgba textarea[disabled]:focus {
    background: #f7fafd;
    border: 1px solid #999;
}

/*
 * hides browser spinner
 */
input[type='number'] {
    -moz-appearance:textfield;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

option {
    font-size: 100%; /* need to correct small type previously set in other reset files */
}

/*
 * 1. FF3/4 sets line-height using !important in the UA stylesheet. This declaration is to set all other browsers to match the FF3/4 style.
 */

button,
input {
    line-height: normal; /* 1 */
}

/*
 * Reintroduce inner spacing in 'table' to avoid overlap and whitespace issues in IE6/7
 */

table button, table input {
    *overflow: auto;
}

/*
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 * and `video` controls.
 * 2. Corrects inability to style clickable `input` types in iOS.
 * 3. Improves usability and consistency of cursor style between image-type
 * `input` and others.
 * 4. Removes inner spacing in IE7 without affecting normal text inputs
 */

button,
html input[type="button"], /* 1 */
input[type="reset"],
input[type="submit"] {
    -webkit-appearance: button; /* 2 */
    cursor: pointer; /* 3 */
    *overflow: visible;  /* 4 */
}

/*
 * 1. Addresses box sizing set to content-box in IE8/9
 * 2. Removes excess padding in IE8/9
 */

input[type="checkbox"],
input[type="radio"] {
    box-sizing: border-box; /* 1 */
    padding: 0; /* 2 */
    width: 14px;
    height: 14px;
    border: none;
    margin: 0;
}

/**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
    height: auto;
}

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */

[type="search"] {
    -webkit-appearance: textfield; /* 1 */
    outline-offset: -2px; /* 2 */
}

/**
 * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
 */

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */

::-webkit-file-upload-button {
    -webkit-appearance: button; /* 1 */
    font: inherit; /* 2 */
}

/*
 * 1. Removes default vertical scrollbar in IE6/7/8/9
 * 2. Improves readability and alignment in all browsers
 * 3. Allow only vertical resizing
 */

textarea {
    overflow: auto; /* 1 */
    vertical-align: top; /* 2 */
    resize: vertical; /* 3 */
}

label {
    color: #000;
    display: block;
}
/*container for holding individual form field validation message. appears above the form label and input/select/textarea*/
.form-field-msg {
    display: inline-block;
}
.form-field > label + .form-field-msg, legend > .form-field-msg {
    margin-left: 1em;
}
.horizontal-field > .form-field-msg {
    display: block;
}
/*container for holding help information. can appear to the right or below the input/select/textarea*/
.form-field-help {
    display: inline-block;
    font-style: oblique;
}

/*container for input, select, textarea*/
.form-field, .required-instructions {
    display: block;
    padding: .76922307em 0 .76922307em .76922307em; /* .78125em 0 .78125em .9em ; .615385em */
    position: relative;
}
.lt-ie8 .form-field {
    zoom: 1;
}
.form-field.reduce-spacing {
    padding-top: .5em;
    padding-bottom: .5em;
}
.radio, .checkbox {
    padding: .7692307em 0 .7692307em 2.4em;
}
.radio > input[type="radio"], .checkbox > input[type="checkbox"] {
    float: left;
    margin-left: -1.5em;
}

/* form field required mark (*) */
.required-field sup.required, .required-field .required-mark, .required-legend .required-mark, .required-instructions .required {
    color: #bd0000;
    font-size: 1.2307em;
    font-weight: 700;
    left: .076923em; /*0*/
    position: absolute;
    top: 1.1538461em; /*1.15em*/
}
.lt-ie8 .required-field sup.required, .lt-ie8 .required-field .required-mark {
    left: auto;
    padding-right: .3em;
    position: relative;
    top: 0px;
}
.not-required {
    padding-left: 0 !important;
}
.radio.not-required, .checkbox.not-required {
    padding-left: 1.5em !important;
}
.required-legend {
    padding-left: .76922307em;
    position: relative;
}
.required-legend .required-mark {
    top: .5em;
}
legend .required {
    color: #bd0000;
    font-size: 1.2307em;
    font-weight: 700;
    padding-right: .15em;
    top: 0;
}
.required.standalone {
    font-size: 1.25em;
    font-weight: 700;
    margin-right: 2px;
    top: 0;
}

/* force full width to be the width of its container */
.full-width-field {
    width: 100%;
}
.full-width-field input[type="text"], .full-width-field input[type="password"], .full-width-field input[type="email"], .full-width-field input[type="tel"], .full-width-field input[type="number"], .full-width-field input[type="date"], .full-width-field select, .full-width-field textarea {
    width: 100%;
}
/* multiple form fields on the same line */
.inline-field {
    display: inline-block;
    margin: 0;
}
.inline-field.radio, .inline-field.checkbox {
    padding-left: 2.4em;
    vertical-align: middle;
}
.inline-field + .inline-field {
    margin-left: .5em;
}
/* label, form field and required mark on single line */
.horizontal-field {
    padding-left: 0;
}
.horizontal-field > label {
    display: inline-block;
    text-align: right;
    vertical-align: middle;
}
.horizontal-field.checkbox > label, .horizontal-field.radio > label {
    text-align: left;
}
.horizontal-field > label + input, .horizontal-field > label + select, .horizontal-field > label + textarea {
    margin-left: .5em;
}
.horizontal-field sup.required, .horizontal-field .required-mark {
    left: auto;
    padding-right: .15em;
    position: relative;
    top: auto;
}
/* stacks label on top of field element */
.label-stack  > label {
    left: 1.55em;
    pointer-events: none;
    position: absolute;
    -webkit-transition: color 0.2s linear, border 0.2s linear;
    -moz-transition: color 0.2s linear, border 0.2s linear;
    -o-transition: color 0.2s linear, border 0.2s linear;
    transition: color 0.2s linear, border 0.2s linears;
    top: 1.40625em;/*.625em*/
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    white-space: nowrap;
}
.label-stack.populated > label {
    visibility: hidden;
}
.label-stack.inline-field  > label {
    left: 1.5em;
}
.label-stack.not-required > label {
    left: .6em;
}
.label-stack.reduce-spacing > label {
    top: 1.15625em;
}
.label-stack sup.required, .label-stack .required-mark {
    left: -1.2em;
    top: .1em;
    visibility: visible;
}
.lt-ie8 .label-stack.required-field > label {
    left: .1em;
}
.ie9 .label-stack sup.required, .ie9 .label-stack .required-mark, .ie8 .label-stack sup.required, .ie8 .label-stack .required-mark {
    left: -1.4em;
}
.lt-ie8 .label-stack sup.required, .lt-ie8 .label-stack .required-mark {
    margin-right: .65em;
    top: -7px;
}
/* Button style checkboxes  */
/* TODO: For buttons implementation, this needs to be converted into a primary i believe.*/
.button-checkbox {
    display: inline-block;
    position: relative;
}
.button-checkbox input {
    width: 1px;
    height: 1px;
    position: absolute;
    clip: rect(0 0 0 0);
    overflow: hidden;
}
.button-checkbox label { /* set your button width on this element  */
    border: 1px solid #CCCCCC;
    background: #DDE6EE;
    cursor: pointer;
}
.button-checkbox label:hover, .button-checkbox label:focus {
    background: #c1d1e1;
}
.button-checkbox input:focus + label {
    outline: 1px solid #0054A0;
    box-shadow: 0 0 1px 1px #0054A0;
}
.button-checkbox input:checked + label {
    background: #2A6AA3;
    color: #ffffff;
}
.button-checkbox input:checked + label:hover, .button-checkbox input:checked + label:focus {
    background: #769ABC;
}
.button-checkbox input:disabled + label {
    background: #ffffff;
    cursor: default;
}
/* form error fields */
input.error, input.invalid, select.error, select.invalid, textarea.error, textarea.invalid {
    background-color: #fdfde0;
    border-color: #bd0000;
    color: #000;
}
input.error:focus, select.error:focus, textarea.error:focus {
    border-color: #bd0000;
    -moz-box-shadow: inset 0 0 1px 1px #bd0000;
    -webkit-box-shadow: inset 0 0 1px 1px #bd0000;
    box-shadow: inset 0 0 1px 1px #bd0000;
}
.inline-instructions, .form-field-info {
    display: inline-block;
    margin-left: .5em;
    vertical-align: middle;
}
.inline-instructions.error {
    font-weight: 700;
    font-style: oblique;
}
/* new strong pass rules */
.password-requirements li {
    min-width: 20rem;
    margin: .2rem 0px;
}
.password-requirements .checked{
	left: 1.3rem;
	list-style: none;
	position: relative;
}
.password-requirements .unCheck {
	position: relative;
	left:1.3rem;
}
.password-requirements .pwd-rules-list  {
	list-style: none;
	padding: 0px;
	margin-bottom: 10px;
}
.password-requirements .svg-icon-circle-checkmark {
	color: #638325;
	width: 15px;
	display: none;
}
.password-requirements .checked .svg-icon-circle-checkmark {
	display: inline-block;
}

.password-requirements .unCheck::before{
	content: '';
	display: inline-block;
	height: .5em;
	width: .5em;
	border-radius: 1em;
	background-size: contain;
	background-repeat: no-repeat;
	margin-right: 1.3em;
	position: absolute;
    top: .5em;
    left: -1.3rem;
	background: #000;
}
.password-requirements .checked::before{
	display: none;
}
.password-requirements span.checked {
 	color: #638325;
}
.password-requirements .checked .svg-icon-circle-checkmark {
	width: 14px;
	height: 14px;
	margin-right: 5px;
	position: absolute;
	top: 0px;
	left: -1.5rem;
}
.password-requirements #passwordStrength {
	color: #638325;
}
.password-requirements #passwordStrength .unCheck{
	color: #bd0000;
}
#password-error .unCheck::before {
	display: none;
}
#password-error .unCheck {
    margin-left: -.5rem;
}
#password-error .svg-icon-warning {
	width: 1.2rem;
	margin-right: .5rem;
}

/*******************************************/
            /* BUTTONS */
/* Default Button - Used on input, button, hrefs.

    NOTE: New styling has been implemented here and all buttons as a retrofit using the PCL Master Design System Figma as of 03/20/2023.
    It is a retrofit because the goal is to transition these, as well as all of our other global styling over to pcl-ui-core, the modernized
    pcl front-end docroot which is built based on BEM, SASS, and Components.

    For this class particularly, it's been modified to hold all styling
    which all of our CTA buttons share, such as border-radius, and acts as a default.

    Because the elements found globally which hold only the .button class are considered Secondary, Secondary CTAs act and are defined as the "default" button.
    Also includes the .secondary-btn class for projects ready to switch over to the new class name.
 */
.button,
.secondary-btn {
    /* All CTA button styling */
    border-radius: 4px;
    padding: 12px 16px;
    margin: 0;
    line-height: 150%;
    text-decoration: none;
    letter-spacing: 1px;
    display: inline-flex;
    align-items: center;
    place-content: center;
    white-space: nowrap;
    cursor: pointer;
    /* Default Button Styling for Secondaries*/
    border: solid 1px var(--primary-blue);
    background: transparent;
    color: var(--primary-blue);
}
/* Made the selectors a couple levels more specific to take priority for various problematic styling, but still overwriteable.*/
.web-font body .button,
.flexbox body .button,
.web-font body .secondary-btn {
    font-size: var(--font-size-p2);
    text-transform: uppercase;
    font-family: var(--red-hat-text);
}


    /*
        Secondary Button Dark BG - Variant of Secondary CTA Button
        For different upcoming designs, as well as the existing "CTA Bar" found on pages like p.com/learn/cruise-destinations/alaska-cruises (at the bottom),
        a dark-bg variant is defined here
     */
/* Legacy */
.cta-bar .button,
/* Modernized */
.secondary-btn-dark-bg {
    border: 1px solid var(--neutral-white);
    color: var(--neutral-white);
}

/*
    By default, all buttons signify a hover/focus with an underline. This should cover all of them, but there
    are additional definitions in the variant blocks below to cover each variants specific hover/focus.
*/
.button:not(.plain-text-btn):hover, .button:not(.plain-text-btn):focus,
.button.selected:not(.plain-text-btn):hover, .button.selected:not(.plain-text-btn):focus {
    text-decoration: underline;
}
.web-font .button {
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
}
.button:focus, .button.selected:focus {
    box-shadow: 0 0 0 2px rgba(0, 0, 0, .8);
    outline: 2px dotted rgba(255, 255, 255, .8);
    outline-offset: 0;
}
.button:active {
    box-shadow: inset 0 0 3px 0 rgba(0,0,0,.75);
}
.button.selected {
    box-shadow: inset 0 0 3px 0 rgba(0,0,0,.75);
}
/* CTA (Call to Action) Button Variants */
/*
    Retrofit of all of the old .[color]-btn classes with new cta button colors, styles, and variants.

    NOTE: A challenge to be aware of is that, in the new design standards we have primary, secondary, and tertiary buttons defined
    with variants, like small/large dark-bg/light-bg. In the new ui-core, these will be more precisely defined and implemented in BEM, with nested modifiers.
    For legacy, the naming and selectors might be a little more difficult to understand, since we're in transition.
 */
    /* Base Primary CTA -
        In most locations of the new designs,
        the Primary CTA button design will be implemented. TODO: Currently checking if stuffing all of the old color classes in here will work.
        Included here is the new class name, which will eventually be implemented everywhere, .primary-btn.
    */
/* Legacy */
.green-btn, .green-btn.selected,
.gray-btn, .gray-btn.selected,
.blue-btn, .blue-btn.selected,
.red-btn, .red-btn.selected,
/* Modernized */
.primary-btn, .primary-btn.selected {
    background: var(--primary-cta);
    color: var(--neutral-white);
}

    /* Primary CTA Base Dark BG Variant -
        TODO: unsure if dark-bg will work here, since dark-bg was only defined inside princess.pill
    */
.green-btn.btn-dark-bg,.green-btn.selected,
.white-btn, .white-btn.selected,
a:hover .white-btn, a:focus .white-btn,
a:hover .white-btn.selected, a:focus .white-btn.selected,
.primary-btn-dark-bg, .primary-btn-dark-bg.selected,
.green-btn.btn-dark-bg:hover,.green-btn.selected:hover,
.primary-btn-dark-bg:hover, .primary-btn-dark-bg.selected:hover {
    background: var(--neutral-white);
    color: var(--primary-blue);
}
    /* Primary Cta Hover & Focus */
/* Legacy */
.green-btn:hover, .green-btn:focus, .green-btn.selected:hover, .green-btn.selected:focus,
.gray-btn:hover, .gray-btn:focus, .gray-btn.selected:hover, .gray-btn.selected:focus
.blue-btn:hover, .blue-btn:focus, .blue-btn.selected:hover, .blue-btn.selected:focus,
.red-btn:hover, .red-btn:focus, .red-btn.selected:hover, .red-btn.selected:focus, .red-btn.selected:hover, .red-btn.selected:focus,
    /* Modernized */
.primary-btn:hover, .primary-btn:focus, .primary-btn.selected:hover, .primary-btn.selected:focus {
    background: var(--primary-cta-hover);
    text-decoration: underline;
}

    /* Primary Cta Deactive State -
       TODO: For future designs, CTAs will never be disabled/deactive. This is a legacy-only style. Remove in the migration.
     */
/* Legacy */
.green-btn.deactive, .green-btn.selected.deactive, .green-btn.deactive:hover, .green-btn.deactive:focus,
.gray-btn.deactive, .gray-btn.deactive:hover, .gray-btn.deactive:focus,
.blue-btn.deactive, .blue-btn.deactive:hover, .blue-btn.deactive:focus,
.red-btn.deactive, .red-btn.deactive:hover, .red-btn.deactive:focus,
.white-btn.deactive, .white-btn.deactive:hover, .white-btn.deactive:focus,
a.deactive:hover .white-btn, a.deactive:focus,
/* Modernized */
.primary-btn.deactive, .primary-btn.selected.deactive, .primary-btn.deactive:hover, .primary-btn.deactive:focus{
    background: var(--primary-medium);
    color: var(--neutral-white);
    cursor: not-allowed;
}
    /* Tertiary CTA Button Variant Styles */
/* Default plain text buttons - used on button */
.plain-text-btn,
.tertiary-btn {
    background: transparent;
    border: none;
    color: var(--primary-blue);
    font-weight: 500;
    padding: 0;
    width: auto;
    font-style: normal;
    line-height: 21px;
    text-decoration: underline;
    /* TODO: Work font sizing after discussions on how to implement. */
    /*letter-spacing: 1px;*/
    /*font-size: var(--font-size-p2);*/
    /*font-family: var(--red-hat-text);*/
}
.plain-text-btn .ir,
.tertiary-btn .ir {
    display: inline-block;
}
.plain-text-btn:hover, .plain-text-btn:focus,
.tertiary-btn:hover, .tertiary-btn:focus{
    text-decoration: none;
}
/*TODO: For future designs, CTAs will never be disabled/deactive. This is a legacy-only style. Remove in the migration.*/
.button.deactive, .button.deactive:hover, .button.deactive:active, .button.no-shadow,
.button.deactive, .plain-text-btn.deactive, a.deactive, .button.disabled,
.button.deactive:hover, .plain-text-btn.deactive:hover, a.deactive:hover, .button.disabled:hover,
.button.deactive:focus, .plain-text-btn.deactive:focus, a.deactive:focus, .button.disabled:focus{
    box-shadow: none;
    color: var(--neutral-white);
    background-color: var(--primary-medium);
    border: solid 1px var(--primary-blue);
    cursor: not-allowed;
}
/* TODO: */
.tertiary-btn-dark-bg {
    color: var(--neutral-white);
}

.inline-btn {
    font-size: .9231em;
    line-height: 2.25em;
    padding: 1px 3px;
}

/* =============================================================================
   Tables
   ========================================================================== */

/*
 * Remove most spacing between table cells
 */
table {
    background: #fff;
    border: none;
    border-collapse: collapse;
    border-spacing: 0;
    margin: 1em auto;
}
table caption {
    text-align: left;
    margin-bottom: .5em;
}
.block-caption {
    background: #fafafa;
    font-size: 1.2307em;
    margin-bottom: 0;
    padding: .25em .6em;
}
.web-font .block-caption {
    font-family: var(--red-hat-text);
    font-size: 1.285714285714286em; /*18px*/
    letter-spacing: 1px;
}
.light-border > .block-caption {
    border: 1px solid #d0d1d2;
    border-bottom: none;
}
.medium-border > .block-caption {
    border: 1px solid #b2b2b2;
    border-bottom: none;
}
/*
 * Improve alignment in all browsers
 */
td, th {
    vertical-align: top;
}
th {
    font-weight: 400;
    text-align: left;
}
.bold-th {
    font-size: 1.142857142857143em;
    font-weight: 700;
}
.table-spacing th, .table-spacing td {
    padding: .25em .75em;
}
.total-row > th, .total-row > td {
    padding: .75em;
}
.light-border .onboard-credit-row > th, .light-border .onboard-credit-row > td,
.medium-border .onboard-credit-row > th, .medium-border .onboard-credit-row > td {
    border-top-width: 3px;
}
.alt-table-row tbody > tr:nth-child(even) td {
    background: #f6f6f6;
}
.lt-ie8.alt-table-row tbody > .even td {
    background: #f1f1f1;
}

@media (max-width: 870px) {
  .table-responsive {
    width: 100%;
    margin: 1em auto;
    overflow-x: scroll;
    overflow-y: hidden;
    border: 1px solid #d2d2d2;
  }
  .table-responsive > table {
    margin: 0;
  }
  .table-responsive > table > thead > tr > th,
  .table-responsive > table > tbody > tr > th,
  .table-responsive > table > tfoot > tr > th,
  .table-responsive > table > thead > tr > td,
  .table-responsive > table > tbody > tr > td,
  .table-responsive > table > tfoot > tr > td {
    white-space: nowrap;
  }
 }
/* =============================================================================
   Princess Style Guide
   ========================================================================== */

/*
 * Page Layout
 */
#container {
    background-color: #0d2f56;
    position: relative;
    margin: 0 auto;
    max-width: 1700px;
    box-shadow: 0 0 15px rgba(0,0,0,.6);
}
#container.default-img-bg, .default-img-bg {
    background-image: url(/images/global/background/Home_background.jpg);
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
    background-attachment:fixed;
}
#content {
    position: relative;
    width: 960px;
    margin: 0 auto;
    padding: 15px 0 0;
    overflow: visible;
}
#container.white-bg, #content.white-bg {
    background: #fff;
}
.rwd #content {
    max-width: 768px;
    width: auto;
}
@media screen and (min-width: 798px) and (max-width: 1020px) {
    .rwd #content {
        margin-left: 15px;
        margin-right: 15px;
    }
}
@media screen and (min-width: 798px) {
    .rwd #content {
        max-width: 990px;
    }
}
/* note .full-width pages can only be used on rwd pages */
.full-width  #content{
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
}


/*
 * Article Template (Header, Container and Aside) for v3 page template
 */
.cms-bg, .main-bg {
    position: relative;
}
.article-hero-banner + #content {
    padding-top: 0;
}
.article-hero-banner {
    background: #fff;
    overflow: hidden;
}
.article-hero-banner img {
    width: 100vw;
    max-width: 1700px;
}
/* default hero banner will be large */
.article-hero-banner,
.article-hero-banner.large-banner,
.article-hero-banner.large-spacing,
.article-hero-banner.homepage-banner {
    aspect-ratio: 768 / 440;
}
.article-hero-banner.medium-banner,
.article-hero-banner.medium-spacing {
    aspect-ratio: 768 / 320;
}
.article-hero-banner.small-banner,
.article-hero-banner.small-spacing {
    aspect-ratio: 768 / 440;
}
@media screen and (min-width: 48em) {
    .article-hero-banner,
    .article-hero-banner.large-banner,
    .article-hero-banner.large-spacing,
    .article-hero-banner.homepage-banner {
        aspect-ratio: 1100 / 630;
    }
    .article-hero-banner.medium-banner,
    .article-hero-banner.medium-spacing {
        aspect-ratio: 1100 / 320;
    }
    .article-hero-banner.small-banner,
    .article-hero-banner.small-spacing {
        aspect-ratio: 1100 / 320;
    }
}
@media screen and (min-width: 60em) {
    .article-hero-banner.homepage-banner {
        aspect-ratio: 1700 / 537;
    }
    .article-hero-banner,
    .article-hero-banner.large-banner,
    .article-hero-banner.large-spacing {
        aspect-ratio: 1600 / 490;
    }
    .article-hero-banner.medium-banner,
    .article-hero-banner.medium-spacing {
        aspect-ratio: 1600 / 320;
    }
    .article-hero-banner.small-banner,
    .article-hero-banner.small-spacing {
        aspect-ratio: 1600 / 160;
    }
}
@media screen and (min-width: 60em) {
    .article-hero-banner.homepage-banner {
        aspect-ratio: 1700 / 537;
    }
    .article-hero-banner,
    .article-hero-banner.large-banner,
    .article-hero-banner.large-spacing {
        aspect-ratio: 1600 / 490;
    }
    .article-hero-banner.medium-banner,
    .article-hero-banner.medium-spacing {
        aspect-ratio: 1600 / 320;
    }
    .article-hero-banner.small-banner,
    .article-hero-banner.small-spacing {
        aspect-ratio: 1600 / 160;
    }
}
/* extended article-hero-banner to support v2 tpl in cms */
.cms-bg.tpl-v2 {
    padding-top: 1px;
}
.tpl-v2 > .article-hero-banner {
    background: transparent;
    margin-top: -1px;
    position: absolute;
    text-align: center;
    width: 100%;
}
.tpl-v2 > .article-hero-banner,
.tpl-v2 > .article-hero-banner.large-banner,
.tpl-v2 > .article-hero-banner.large-spacing,
.tpl-v2 > .article-hero-banner.medium-banner,
.tpl-v2 > .article-hero-banner.medium-spacing,
.tpl-v2 > .article-hero-banner.small-banner,
.tpl-v2 > .article-hero-banner.small-spacing {
    aspect-ratio: auto;
}
.tpl-v2 > .article-hero-banner img {
    margin-left: -28.5083%;
    position: relative;
    max-width: 1600px;
    width: 157.01668%;
}
@media screen and (min-width: 63.75em) {
    .tpl-v2 > .article-hero-banner img {
        left: 50%;
        margin-left: -800px;
        width: 1600px;
    }
}
.tpl-v2 > .article-hero-banner.small-banner + #content,
.tpl-v2 > .article-hero-banner.small-spacing + #content {
    margin-top: 30px;
    padding-top: 0;
}
.tpl-v2 > .article-hero-banner.medium-banner + #content,
.tpl-v2 > .article-hero-banner.medium-spacing + #content {
    margin-top: 38.48837209302326%;
    padding-top: 0;
}
.tpl-v2 > .article-hero-banner + #content,
.tpl-v2 > .article-hero-banner.large-banner + #content,
.tpl-v2 > .article-hero-banner.large-spacing + #content {
    margin-top: 38.48837209302326%;
    padding-top: 0;
}
@media screen and (max-width: 48.0625em) {
    .tpl-v2 > .article-hero-banner.small-banner + #content,
    .tpl-v2 > .article-hero-banner.small-spacing + #content {
        margin-top: 0;
    }
}
@media screen and (min-width:40em) {
    .tpl-v2 > .article-hero-banner.medium-banner + #content,
    .tpl-v2 > .article-hero-banner.medium-spacing + #content {
        margin-top: 246px;
    }
}
@media screen and (min-width:53.75em) {
    .tpl-v2 > .article-hero-banner + #content,
    .tpl-v2 > .article-hero-banner.large-banner + #content,
    .tpl-v2 > .article-hero-banner.large-spacing + #content {
        margin-top: 331px;
    }
}


.article-header {
    background: #e6e6e6;
    padding: 15px;
    position:relative;
}
.full-width .article-title.light-bg, .full-width .article-title.medium-bg, .full-width .article-title.dark-bg {
    background: transparent;
    color: #000;
}
.article-header h1 {
    font-size: 2.4285714285714294em; /* 34px */
}
.article-header h2 {
    font-size: 1.142857142857143em; /* 16px */
    letter-spacing: normal;
}
.article-header h1, .article-header h2 {
    line-height: 1.125; /* 18px */
    margin: 0;
}
.article-header h1 + h2 {
    margin-top: .5em;
}
.article-header.header-border-radius {
    -webkit-border-radius: 10px;
    border-radius: 10px;
}
@media screen and (min-width: 960px) {
    .full-width .article-header {
        padding-left: 0;
        padding-right: 0;
    }
    .full-width .small-spacing .article-title, .full-width .medium-spacing .article-title, .full-width .large-spacing .article-title {
        left: 0;
        padding: 1em 0 1.25em;
        position: absolute;
        text-align: center;
        right: 0;
        top: -1px;
        width: 100%;
    }
    .full-width .small-spacing .article-title {
        margin-top: -15.9375%;
    }
    .full-width .medium-spacing .article-title {
        margin-top: -23.75%;
    }
    .full-width .large-spacing .article-title {
        margin-top: -30.956848030019%;
    }
    .full-width .article-title.light-bg {
        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0.9+0,0.9+55,0.4+100 */
        /* IE9 SVG, needs conditional override of 'filter' to 'none' */
        background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZmZmZmZiIgc3RvcC1vcGFjaXR5PSIwLjkiLz4KICAgIDxzdG9wIG9mZnNldD0iNTUlIiBzdG9wLWNvbG9yPSIjZmZmZmZmIiBzdG9wLW9wYWNpdHk9IjAuOSIvPgogICAgPHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjZmZmZmZmIiBzdG9wLW9wYWNpdHk9IjAuNCIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
        background: -moz-linear-gradient(top,  rgba(255,255,255,0.9) 0%, rgba(255,255,255,0.8) 55%, rgba(255,255,255,0.5) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top,  rgba(255,255,255,0.9) 0%,rgba(255,255,255,0.8) 55%,rgba(255,255,255,0.5) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom,  rgba(255,255,255,0.9) 0%,rgba(255,255,255,0.8) 55%,rgba(255,255,255,0.5) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e6ffffff', endColorstr='#66ffffff',GradientType=0 ); /* IE6-8 */
    }
    .full-width .article-title.medium-bg {
        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#707070+0,707070+100&0.9+0,0.9+55,0.4+100 */
        /* IE9 SVG, needs conditional override of 'filter' to 'none' */
        background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzcwNzA3MCIgc3RvcC1vcGFjaXR5PSIwLjkiLz4KICAgIDxzdG9wIG9mZnNldD0iNTUlIiBzdG9wLWNvbG9yPSIjNzA3MDcwIiBzdG9wLW9wYWNpdHk9IjAuOSIvPgogICAgPHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjNzA3MDcwIiBzdG9wLW9wYWNpdHk9IjAuNCIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
        background: -moz-linear-gradient(top,  rgba(112,112,112,0.9) 0%, rgba(112,112,112,0.8) 55%, rgba(112,112,112,0.5) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top,  rgba(112,112,112,0.9) 0%,rgba(112,112,112,0.8) 55%,rgba(112,112,112,0.5) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom,  rgba(112,112,112,0.9) 0%,rgba(112,112,112,0.8) 55%,rgba(112,112,112,0.5) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e6707070', endColorstr='#66707070',GradientType=0 ); /* IE6-8 */
    }
    .full-width .article-title.dark-bg {
        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0.9+0,0.9+55,0.4+100 */
        /* IE9 SVG, needs conditional override of 'filter' to 'none' */
        background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIwLjkiLz4KICAgIDxzdG9wIG9mZnNldD0iNTUlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuOSIvPgogICAgPHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAuNCIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
        background: -moz-linear-gradient(top,  rgba(0,0,0,0.9) 0%, rgba(0,0,0,0.8) 55%, rgba(0,0,0,0.5) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top,  rgba(0,0,0,0.9) 0%,rgba(0,0,0,0.8) 55%,rgba(0,0,0,0.5) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom,  rgba(0,0,0,0.9) 0%,rgba(0,0,0,0.8) 55%,rgba(0,0,0,0.5) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e6000000', endColorstr='#66000000',GradientType=0 ); /* IE6-8 */
    }
    .full-width .article-title > h1, .full-width .article-title > h2 {
        max-width: 990px;
        margin-left: auto;
        margin-right: auto;
    }
    .full-width .article-title.medium-bg > h1, .full-width .article-title.medium-bg > h2,
    .full-width .article-title.dark-bg > h1, .full-width .article-title.dark-bg > h2 {
        color: #fff;
    }
}
@media screen and (min-width: 770px) {
    .article-header {
        -webkit-border-radius: 10px 10px 0 0;
        border-radius: 10px 10px 0 0;
    }
    .mfp-content .article-header, .full-width .article-header {
        -webkit-border-radius: 0;
        border-radius: 0;
    }
}
.article-container, .article-media {
    position: relative;
}
.article-aside > div:last-child {
    margin-bottom: -30px;
}

/*
 * Modifications to the global template for v4
 */

/*
Overflow hidden is added at the <main> to prevent awkward issue where extra tab-nav-items would flow outside of the the tab-nav-wrapper.
Had to add at this high level to prevent conflict that was now letting tab-subnav-menu display.
When overflow was applied to the tab-nav-menu, or it's wrapper, tab-submenu-nav would not be displayed.
*/
main.tpl-v4 {
    overflow: hidden;
}
.tpl-v4 #content.no-spacing {
    padding-top: 0;
    margin-bottom: 0;
    margin-top: -1px;
}
.tpl-v4 ~ #princess-footer {
    margin-top: 0;
}
.tpl-v4 .article-header {
    background: transparent;
    padding: 0;
}
.tpl-v4 .article-title {
    background: #fff;
    margin: 0 auto;
    padding: 30px 15px 15px;
    text-align: center;
    width: 100%;
}
.tpl-v4.full-width .article-title {
    left: auto;
    margin: 0 auto;
    padding: 30px 15px 15px;
    position: relative;
    right: auto;
    top: auto;
}
/* special offer modal and page header styling*/

.tpl-v4 #special-offers-form .article-title h1{
    font-size:var(--size-display-headline-small);
    font-family: var(--domaine-display-bold);
}
    
.mfp-entry-point .tpl-v4 #special-offers-form .article-title h1{
    font-size:var(--size-display-headline-xsmall);
    font-family: var(--domaine-display-bold);
}

.tpl-v4 #special-offers-form .article-title h2{
    font-size:var(--size-display-headline-small);
    font-family: var(--domaine-display-bold);
}
    
.mfp-entry-point .tpl-v4 #special-offers-form .article-title h2{
    font-size:var(--size-display-headline-xsmall);
    font-family: var(--domaine-display-bold);
}


#registration-confirmation-modal h1{
    font-size:44px;
    font-family: var(--domaine-display-bold);
}

#registration-confirmation-modal h2{
    font-size:44px;
    font-family: var(--domaine-display-bold);
}

#registration-confirmation-modal p{
        font-family: var(--proxima-nova-adobe);
        font-weight: 400;
}

#special-offers-form *{
        font-size: 16px;
        font-family: var(--proxima-nova-adobe);
        font-weight: 400;
}

#special-offers-form label{
    font-size:18px;
    padding-left:16px;
    padding-right:16px;
}

#special-offers-form input[type="text"] {
    border-radius: 92px;
    font-size:18px;
    height:52px;
    padding-left:16px;
    padding-right:16px;
    width:100%;
}

#special-offers-form .form-field.no-spacing.inline-field.radio{
    display:inline-flex;
    align-items:center;
}

/* CSS added to remove the light-blue color that is applied from the Target*/
#special-offers-form .light-blue-bg{
    background: none;
}
 /*Special modala styling end*/       
    
.tpl-v4 .article-title h1 {
    font-size: 2.61538461538462em;
}
.tpl-v4 .article-title h2 {
    font-size: 1.23076923076923em;
}
.tpl-v4 .content + .article-aside {
    background-color: #fff;
}
.tpl-v4 .article-aside > div:last-child {
    margin-bottom: 0;
}

/* Anchor nav */
.article-nav {
    margin-top: 1em;
}
.article-title .article-nav {
    font-family:var(--red-hat-text-bold-it);
}
.article-nav a {
    color: #127ba3;
}
.anchor-btn {
    white-space: nowrap;
}


/*
 * Text & Image Rows
 */
.row-divider {
    padding-top: 15px;
    padding-bottom: 15px;
}
.row-divider:last-child {
    padding-bottom: 0;
}
.row-divider + .row-divider, .row-divider + script + .row-divider {
    border-top: 1px solid #d0d1d2;
}
@media (max-width: 640px) {
    /* for small screens, add extra spacing between each img-text row */
    .img-txt-row {
        padding-bottom: 15px;
    }
    .img-txt-row:last-child {
        padding-bottom: 0;
    }
    .img-txt-row + .img-txt-row {
        padding-top: 15px;
    }
}

@media (min-width: 640px) and (max-width: 900px) {
    /* turn on row dividers for all versions of img-text rows for medium sized screens */
    .img-txt-row {
        padding-bottom: 15px;
    }
    .img-txt-row:last-child {
        padding-bottom: 0;
    }
    .img-txt-row + .img-txt-row {
        border-top: 1px solid #d0d1d2;
        padding-top: 15px;
    }
}

/*
 * Cards Layout
 */
/* Update card layout - sourced from contenthub */
.article-item > a {
    color: #000;
    display: block;
    position: relative;
    text-decoration: none;
}
.article-item > a h2,
.article-item > a h3,
.article-item > a .cta-text {
    color: #0054A0;
}
.article-item > a:hover h2,
.article-item > a:focus h2,
.article-item > a:hover h3,
.article-item > a:focus h3 {
    text-decoration: underline;
}
.article-item > a .cta-text {
    display: block;
    text-decoration: underline;
}
.article-item > a:hover .cta-text,
.article-item > a:focus .cta-text {
    text-decoration: none;
}
.article-item a:focus img {
    clip-path: inset(2px 2px 0px 2px);
}
.article-caption h1 {
    margin-bottom: 0;
}
.article-caption p {
    margin-top: .6153846em;
    margin-bottom: 0;
}
/* special offer modal and page header styling*/

.button-left{
    display:inline-block;
    padding-left:20px;
}
.tpl-v4.full-width #special-offers-form .article-title{
    padding: 15px 15px 10px;
}

.tpl-v4 #special-offers-form .article-title h1{
    font-size:var(--size-display-headline-small);
    font-family: var(--domaine-display-bold);
}

.mfp-entry-point #container{
    background-color:transparent;
    box-shadow: none;
}
.mfp-entry-point .tpl-v4 #special-offers-form .article-title h1{
    font-size:var(--size-display-headline-xsmall);
    font-family: var(--domaine-display-bold);
}

.tpl-v4 #special-offers-form .article-title h2{
    font-size:var(--size-display-headline-small);
    font-family: var(--domaine-display-bold);
}
    
.mfp-entry-point .tpl-v4 #special-offers-form .article-title h2{
    font-size:var(--size-display-headline-xsmall);
    font-family: var(--domaine-display-bold);
}


#registration-confirmation-modal h1{
    font-size:var(--size-display-headline-small);
    font-family: var(--domaine-display-bold);
}

#registration-confirmation-modal h2{
    font-size:var(--size-display-headline-small);
    font-family: var(--domaine-display-bold);
}

#registration-confirmation-modal p{
        font-family: var(--proxima-nova-adobe);
        font-weight: var(--font-weight-regular);
        font-style: var(--font-style-normal);
}

#special-offers-form *{
        font-size: 16px;
        font-family: var(--proxima-nova-adobe);
        font-weight: var(--font-weight-regular);
        font-style: var(--font-style-normal);
}

#special-offers-form label{
    font-size:18px;
    padding-left:16px;
    padding-right:16px;
}

#special-offers-form input[type="text"] {
    border-radius: 92px;
    font-size:18px;
    height:52px;
    padding-left:16px;
    padding-right:16px;
    width:100%;
}

#special-offers-form .form-field.no-spacing.inline-field.radio{
    display:inline-flex;
    align-items:center;
}

/* CSS added to remove the light-blue color that is applied from the Target*/
#special-offers-form .light-blue-bg{
    background: none;
}
 /*Special modala styling end*/       

/*
 * Borders and backgrounds
 */
.white-border, .white-border > tr > th, .white-border > thead > tr > th, .white-border > tbody > tr > th, .white-border > tr > td, .white-border > tbody > tr > td {
    border: 1px solid #fff;
}
.light-border, .light-border > tr > th, .light-border > thead > tr > th, .light-border > tbody > tr > th, .light-border > tr > td, .light-border > tbody > tr > td {
    border: 1px solid #d0d1d2;
}
.medium-border, .medium-border > tr > th, .medium-border > thead > tr > th, .medium-border > tbody > tr > th, .medium-border > tr > td, .medium-border > tbody > tr > td {
    border: 1px solid #b2b2b2;
}
.white-border-top {
    border-top: 1px solid #fff;
}
.white-border-right {
    border-right: 1px solid #fff;
}
.white-border-bottom {
    border-bottom: 1px solid #fff;
}
.white-border-left {
    border-left: 1px solid #fff;
}
.light-border-top {
    border-top: 1px solid #d0d1d2;
}
.light-border-right {
    border-right: 1px solid #d0d1d2;
}
.light-border-bottom {
    border-bottom: 1px solid #d0d1d2;
}
.light-border-left {
    border-left: 1px solid #d0d1d2;
}
.medium-border-top {
    border-top: 1px solid #b2b2b2;
}
.medium-border-right {
    border-right: 1px solid #b2b2b2;
}
.medium-border-bottom {
    border-bottom: 1px solid #b2b2b2;
}
.medium-border-left {
    border-left: 1px solid #b2b2b2;
}
.blue-border {
    border: 2px solid #3d9ab7;
}
.white-bg {
    background: #fff;
}
.red-bg {
    background: #bd0000;
}
.dark-red-bg {
    background: #5C0D10;
}
.gray-333-bg {
    background: #333;
    color: #fff;
}
.gray-666-bg, .dark-bg {
    background: #666;
    color: #fff;
}
.gray-e6-bg, .medium-bg {
    background: #e6e6e6;
}
.gray-f1-bg, .light-bg {
    background: #f1f1f1;
}
.lighter-blue-bg {
    background: #F4F7F9;
}
.light-blue-bg {
    background: #e5f3fd;
}
.blue-bg {
    background: #007ba3;
}
.dark-blue-bg {
    background: #0D2F56;
}
.dark-blue{
    color: #003595;
}    
.darker-blue-bg {
    background: #09213D;
}
.princess-blue-bg {
    background: #0054A0;
}
/* anything oceans--primary-blue */
.ocean-blue-bg {
    background: #003087;
}
.light-green-bg {
    background: #35a748;
}
.green-bg {
    background: #517C35;
}
.dark-green-bg {
    background: #DBE6D5;
}
.black-bg {
    background: #000;
}
.orange-bg{
    background: #f05a28;
}
.light-blue-gradient-bg:hover, .light-blue-gradient-bg:focus {
    background: #e5f3fd;
    background: -moz-linear-gradient(top, #d3e8f5 0%, #e5f3fd 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#d3e8f5), color-stop(100%,#e5f3fd));
    background: -webkit-linear-gradient(top, #d3e8f5 0%,#e5f3fd 100%);
    background: -o-linear-gradient(top, #d3e8f5 0%,#e5f3fd 100%);
    background: -ms-linear-gradient(top, #d3e8f5 0%,#e5f3fd 100%);
    background: linear-gradient(to bottom, #d3e8f5 0%,#e5f3fd 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d3e8f5', endColorstr='#e5f3fd',GradientType=0 );
    cursor: pointer;
}
.trans-light-blue-bg {
    background:rgba(244,249,253,.80);
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr='#80000000', endColorstr='#80000000');
}
.trans-blue-bg {
    background:rgba(9,33,61,.70);
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr='#80000000', endColorstr='#80000000');
}
.trans-black-bg {
    background:rgba(0,0,0,.75);
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr='#80000000', endColorstr='#80000000');
}
.trans-white-bg {
    background:rgba(255,255,255,.8);
    filter:progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr='#CCFFFFFF', endColorstr='#CCFFFFFF');
}
.ie9 .trans-black-bg, .ie9 .trans-white-bg, .no-filter {
    filter:none;
}
.no-border {
    border: none !important;
}
.no-border-top {
    border-top: none !important;
}
.no-border-bottom {
    border-bottom: none !important;
}
.no-border-right {
    border-right: none !important;
}
.no-border-left {
    border-left: none !important;
}

/*
 * Text alignment, colors, and styles
 */
/* Text sizing - base font is set at 13px. The following classes are intended to increase or decrease text by 1px in relation to 13px.
   The most common font sizes are: 13, 15, 18, 22/23, 28, 36 */
.font-size-m3, .web-font .font-size-m3 {
    font-size: 0.785714285714286em;    /*11px*/
}
.font-size-m2, .web-font .font-size-m2 {
    font-size: 0.857142857142857em;    /*12px*/
}
.font-size-m1, .web-font .font-size-m1 {
    font-size: 0.928571428571429em;    /*13px*/
}
.font-size-0, .font-size-m0, .font-size-p0, .web-font .font-size-0, .web-font .font-size-m0, .web-font .font-size-p0 {
    font-size: 1em;                    /*14px*/
}
.font-size-p1, .web-font .font-size-p1 {
    font-size: 1.071428571428571em;     /*15px*/
}
.font-size-p2, .web-font .font-size-p2 {
    font-size: 1.142857142857143em;     /*16px*/
}
.font-size-p3, .web-font .font-size-p3 {
    font-size: 1.214285714285714em;     /*17px*/
}
.font-size-p4, .web-font .font-size-p4 {
    font-size: 1.285714285714286em;     /*18px*/
}
.font-size-p5, .web-font .font-size-p5 {
    font-size: 1.357142857142857em;     /*19px*/
}
.font-size-p6, .web-font .font-size-p6 {
    font-size: 1.428571428571429em;     /*20px*/
}
.font-size-p7, .web-font .font-size-p7 {
    font-size: 1.5em;                   /*21px*/
}
.font-size-p8, .web-font .font-size-p8 {
    font-size: 1.571428571428571em;     /*22px*/
}
.font-size-p9, .web-font .font-size-p9 {
    font-size: 1.642857142857143em;     /*23px*/
}
.font-size-p10, .web-font .font-size-p10 {
    font-size: 1.769230769230769em;     /*24px*/
}
.font-size-p11, .web-font .font-size-p11 {
    font-size: 1.846153846153846em;     /*25px*/
}
.font-size-p12, .web-font .font-size-p12 {
    font-size: 1.857142857142857em;     /*26px*/
}
.font-size-p13, .web-font .font-size-p13 {
    font-size: 1.928571428571429em;     /*27px*/
}
.font-size-p14, .web-font .font-size-p14 {
    font-size: 2em;                     /*28px*/
}
.font-size-p15, .web-font .font-size-p15 {
    font-size: 2.071428571428571em;     /*29px*/
}
.font-size-p16, .web-font .font-size-p16 {
    font-size: 2.142857142857143em;     /*30px*/
}
.font-size-p17, .web-font .font-size-p17 {
    font-size: 2.214285714285714em;     /*31px*/
}
.font-size-p18, .web-font .font-size-p18 {
    font-size: 2.285714285714286em;     /*32px*/
}
.font-size-p19, .web-font .font-size-p19 {
    font-size: 2.357142857142857em;     /*33px*/
}
.font-size-p20, .web-font .font-size-p20 {
    font-size: 2.428571428571429em;     /*34px*/
}
.font-size-p21, .web-font .font-size-p21 {
    font-size: 2.5em;     /*35px*/
}
.font-size-p22, .web-font .font-size-p22 {
    font-size: 2.571428571428571em;     /*36px*/
}
.font-size-p23, .web-font .font-size-p23 {
    font-size: 2.642857142857143em;     /*37px*/
}

/*text styles*/
.font-weight-700 {
    font-weight: 700;
}
.web-font .font-weight-700 {
    font-family:var(--red-hat-text-bold);
    font-weight: 700;
}
.font-weight-400, .no-bold {
    font-weight: 400;
}
.font-style-oblique {
    font-style: oblique;
}
.font-style-normal {
    font-style: normal;
}
.underline {
    text-decoration: underline;
}
.disclaimer {
    clear: both;
    font-size:  0.857142857142857em; /* 12px */
}

/*text alignment of columns*/
.text-left, .align-left, .text-xs-left {
    text-align: left;
}
.text-right, .align-right, .text-xs-right {
    text-align: right;
}
.text-center, .align-center, .text-xs-center {
    text-align: center;
}
.text-justify, .text-xs-justify {
    text-align: justify;
}
.text-nowrap, .no-wrap, .text-xs-nowrap {
    white-space: nowrap;
}
.text-breakword, .break-word, .text-xs-breakword {
    word-wrap: break-word;
}
.text-truncate, .text-xs-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
@media (min-width: 40em) {
    .text-sm-left {
        text-align: left;
    }
    .text-sm-right {
        text-align: right;
    }
    .text-sm-center {
        text-align: center;
    }
    .text-sm-justify {
        text-align: justify;
    }
    .text-sm-nowrap {
        white-space: nowrap;
    }
    .text-sm-breakword {
        word-wrap: break-word;
    }
    .text-sm-truncate {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}
@media (min-width: 48em) {
    .text-md-left {
        text-align: left;
    }
    .text-md-right {
        text-align: right;
    }
    .text-md-center {
        text-align: center;
    }
    .text-md-justify {
        text-align: justify;
    }
    .text-md-nowrap {
        white-space: nowrap;
    }
    .text-md-breakword {
        word-wrap: break-word;
    }
    .text-md-truncate {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}
@media (min-width: 62em) {
    .text-lg-left {
        text-align: left;
    }
    .text-lg-right {
        text-align: right;
    }
    .text-lg-center {
        text-align: center;
    }
    .text-lg-justify {
        text-align: justify;
    }
    .text-lg-nowrap {
        white-space: nowrap;
    }
    .text-lg-breakword {
        word-wrap: break-word;
    }
    .text-lg-truncate {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.align-top {
    vertical-align: top;
}
.align-middle {
    vertical-align: middle;
}
.align-bottom {
    vertical-align: bottom;
}
/*text color*/
.gray-333 {
    color: #333;
}
.gray-666 {
    color: #666;
}
.gray, .gray-999 {
    color: #999;
}
.black, .price {
    color: #000;
}
.white {
    color: #fff;
}
.blue {
    color: #0054A0;
}
.green, .accepted, .complete, .success, .onboard-credit {
    color: #648326;
}
.purple {
    color: #845aa5;
}
.orange, .optional {
    color: #f05a28;
}
.red, .cancel, .delete, .alert, .error, .errorMessage, .incomplete, .invalid, .sale {
    color: #bd0000;
}

/*
 * Column lists - allows for a singular list to look like two columns
 */
@media (min-width:40em) {
    .column-list {
        column-count: 2;
        -moz-column-count: 2;
        -webkit-column-count: 2;
        column-gap: 30px;
        -moz-column-gap: 30px;
        -webkit-column-gap: 30px;
    }
    .column-list li {
        page-break-inside: avoid;
        break-inside: avoid;
        -webkit-column-break-inside: avoid;
    }
}

/*
 * Column layouts
 */
/*simple fix column grid*/
.col-1of2, .col-1of3, .col-1of4, .col-1of5 {
    float: left;
    margin-left: 5%;
}
.lt-ie8 .col-1of2, .lt-ie8 .col-1of3, .lt-ie8 .col-1of4, .lt-ie8 .col-1of5 {
    margin-left: 4.99%;
}
.col-1of2 {
    width: 47.5%;
}
.col-1of3 {
    width: 30%;
}
.col-1of3.span2 {
    width: 65%;
}
.col-1of4 {
    width: 21.25%;
}
.col-1of4.span2 {
    width: 47.5%;
}
.col-1of4.span3 {
    width: 73.75%;
}
.col-1of5 {
    width: 16%;
}
.col-1of5.span2 {
    width: 37%;
}
.col-1of5.span3 {
    width: 58%;
}
.col-1of5.span4 {
    width: 79%;
}
.col-divider, .lt-ie8 .col-divider {
    margin-left: 2.375%;
    padding-left: 2.375%;
    border-left: 1px solid #7d7d7d;
}
.row > .col-1of2:first-child, .row > .col-1of3:first-child, .row > .col-1of4:first-child, .row > .col-1of5:first-child {
    margin-left: 0;
    padding-left: 0;
}
/* legacy - should not use */
.twocol {
    float:left;
    width: 47.5%;
    margin: 0 2.5%;
}
.twocol.first {
    margin-left: 0;
}
.twocol.last {
    margin-right: 0;
}
.threecol {
    float:left;
    width: 30%;
    margin: 0 2.5%;
}
.threecol.first {
    margin-left: 0;
}
.threecol.last {
    margin-right: 0;
}
/* responsive column grid - to be used only on new rwd projects */
.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12 {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

/**
 * These values will not show up in content, but can be
 * queried by JavaScript to know which breakpoint is active.
 * Add or remove as many breakpoints as you like.
 * https://www.lullabot.com/articles/importing-css-breakpoints-into-javascript
 * https://davidwalsh.name/pseudo-element
 */
.rwd:before {
    content: "xs";
    display: none; /* Prevent from displaying. */
}
.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11 {
    float: left;
}
.col-xs-1 {
    width: 8.333333333333332%;
}
.col-xs-2 {
    width: 16.666666666666664%;
}
.col-xs-3 {
    width: 25%;
}
.col-xs-4 {
    width: 33.33333333333333%;
}
.col-xs-5 {
    width: 41.66666666666667%;
}
.col-xs-6 {
    width: 50%;
}
.col-xs-7 {
    width: 58.333333333333336%;
}
.col-xs-8 {
    width: 66.66666666666666%;
}
.col-xs-9 {
    width: 75%;
}
.col-xs-10 {
    width: 83.33333333333334%;
}
.col-xs-11 {
    width: 91.66666666666666%;
}
.col-xs-12 {
    width: 100%;
}
.container {
    margin: 0 auto;
}
.col-xs-push-1 {
    left: 8.333333333333332%;
}
.col-xs-push-2 {
    left: 16.666666666666664%;
}
.col-xs-push-3 {
    left: 25%;
}
.col-xs-push-4 {
    left: 33.33333333333333%;
}
.col-xs-push-5 {
    left: 41.66666666666667%;
}
.col-xs-push-6 {
    left: 50%;
}
.col-xs-push-7 {
    left: 58.333333333333336%;
}
.col-xs-push-8 {
    left: 66.66666666666666%;
}
.col-xs-push-9 {
    left: 75%;
}
.col-xs-push-10 {
    left: 83.33333333333334%;
}
.col-xs-push-11 {
    left: 91.66666666666666%;
}
.col-xs-pull-1 {
    right: 8.333333333333332%;
}
.col-xs-pull-2 {
    right: 16.666666666666664%;
}
.col-xs-pull-3 {
    right: 25%;
}
.col-xs-pull-4 {
    right: 33.33333333333333%;
}
.col-xs-pull-5 {
    right: 41.66666666666667%;
}
.col-xs-pull-6 {
    right: 50%;
}
.col-xs-pull-7 {
    right: 58.333333333333336%;
}
.col-xs-pull-8 {
    right: 66.66666666666666%;
}
.col-xs-pull-9 {
    right: 75%;
}
.col-xs-pull-10 {
    right: 83.33333333333334%;
}
.col-xs-pull-11 {
    right: 91.66666666666666%;
}
.col-xs-offset-1 {
    margin-left: 8.333333333333332%;
}
.col-xs-offset-2 {
    margin-left: 16.666666666666664%;
}
.col-xs-offset-3 {
    margin-left: 25%;
}
.col-xs-offset-4 {
    margin-left: 33.33333333333333%;
}
.col-xs-offset-5 {
    margin-left: 41.66666666666667%;
}
.col-xs-offset-6 {
    margin-left: 50%;
}
.col-xs-offset-7 {
    margin-left: 58.333333333333336%;
}
.col-xs-offset-8 {
    margin-left: 66.66666666666666%;
}
.col-xs-offset-9 {
    margin-left: 75%;
}
.col-xs-offset-10 {
    margin-left: 83.33333333333334%;
}
.col-xs-offset-11 {
    margin-left: 91.66666666666666%;
}
.col-xs-push-1 {
    left: 8.333333333333332%;
}
.col-xs-push-2 {
    left: 16.666666666666664%;
}
.col-xs-push-3 {
    left: 25%;
}
.col-xs-push-4 {
    left: 33.33333333333333%;
}
.col-xs-push-5 {
    left: 41.66666666666667%;
}
.col-xs-push-6 {
    left: 50%;
}
.col-xs-push-7 {
    left: 58.333333333333336%;
}
.col-xs-push-8 {
    left: 66.66666666666666%;
}
.col-xs-push-9 {
    left: 75%;
}
.col-xs-push-10 {
    left: 83.33333333333334%;
}
.col-xs-push-11 {
    left: 91.66666666666666%;
}
.col-xs-pull-1 {
    right: 8.333333333333332%;
}
.col-xs-pull-2 {
    right: 16.666666666666664%;
}
.col-xs-pull-3 {
    right: 25%;
}
.col-xs-pull-4 {
    right: 33.33333333333333%;
}
.col-xs-pull-5 {
    right: 41.66666666666667%;
}
.col-xs-pull-6 {
    right: 50%;
}
.col-xs-pull-7 {
    right: 58.333333333333336%;
}
.col-xs-pull-8 {
    right: 66.66666666666666%;
}
.col-xs-pull-9 {
    right: 75%;
}
.col-xs-pull-10 {
    right: 83.33333333333334%;
}
.col-xs-pull-11 {
    right: 91.66666666666666%;
}
.col-xs-offset-1 {
    margin-left: 8.333333333333332%;
}
.col-xs-offset-2 {
    margin-left: 16.666666666666664%;
}
.col-xs-offset-3 {
    margin-left: 25%;
}
.col-xs-offset-4 {
    margin-left: 33.33333333333333%;
}
.col-xs-offset-5 {
    margin-left: 41.66666666666667%;
}
.col-xs-offset-6 {
    margin-left: 50%;
}
.col-xs-offset-7 {
    margin-left: 58.333333333333336%;
}
.col-xs-offset-8 {
    margin-left: 66.66666666666666%;
}
.col-xs-offset-9 {
    margin-left: 75%;
}
.col-xs-offset-10 {
    margin-left: 83.33333333333334%;
}
.col-xs-offset-11 {
    margin-left: 91.66666666666666%;
}
.col-xs-pad-0, .col-xs-pad-0-left {
    padding-left: 0;
}
.col-xs-pad-0, .col-xs-pad-0-right {
    padding-right: 0;
}
.col-xs-pad-0-top {
    padding-top: 0;
}
.col-xs-pad-0-bottom {
    padding-bottom: 0;
}
.col-xs-pad-5, .col-xs-pad-5-left {
    padding-left: 5px;
}
.col-xs-pad-5, .col-xs-pad-5-right {
    padding-right: 5px;
}
.col-xs-pad-5-top {
    padding-top: 5px;
}
.col-xs-pad-5-bottom {
    padding-bottom: 5px;
}
.col-xs-pad-10, .col-xs-pad-10-left {
    padding-left: 10px;
}
.col-xs-pad-10, .col-xs-pad-10-right {
    padding-right: 10px;
}
.col-xs-pad-10-top {
    padding-top: 10px;
}
.col-xs-pad-10-bottom {
    padding-bottom: 10px;
}
.col-xs-pad-15, .col-xs-pad-15-left {
    padding-left: 15px;
}
.col-xs-pad-15, .col-xs-pad-15-right {
    padding-right: 15px;
}
.col-xs-pad-15-top {
    padding-top: 15px;
}
.col-xs-pad-15-bottom {
    padding-bottom: 15px;
}
.col-xs-pad-20, .col-xs-pad-20-left {
    padding-left: 20px;
}
.col-xs-pad-20, .col-xs-pad-20-right {
    padding-right: 20px;
}
.col-xs-pad-20-top {
    padding-top: 20px;
}
.col-xs-pad-20-bottom {
    padding-bottom: 20px;
}
.col-xs-pad-25, .col-xs-pad-25-left {
    padding-left: 25px;
}
.col-xs-pad-25, .col-xs-pad-25-right {
    padding-right: 25px;
}
.col-xs-pad-25-top {
    padding-top: 25px;
}
.col-xs-pad-25-bottom {
    padding-bottom: 25px;
}
.col-xs-pad-30, .col-xs-pad-30-left {
    padding-left: 30px;
}
.col-xs-pad-30, .col-xs-pad-30-right {
    padding-right: 30px;
}
.col-xs-pad-30-top {
    padding-top: 30px;
}
.col-xs-pad-30-bottom {
    padding-bottom: 30px;
}
.col-xs-pad-35, .col-xs-pad-35-left {
    padding-left: 35px;
}
.col-xs-pad-35, .col-xs-pad-35-right {
    padding-right: 35px;
}
.col-xs-pad-35-top {
    padding-top: 35px;
}
.col-xs-pad-35-bottom {
    padding-bottom: 35px;
}
.col-xs-pad-40, .col-xs-pad-40-left {
    padding-left: 40px;
}
.col-xs-pad-40, .col-xs-pad-40-right {
    padding-right: 40px;
}
.col-xs-pad-40-top {
    padding-top: 40px;
}
.col-xs-pad-40-bottom {
    padding-bottom: 40px;
}
.col-xs-pad-45, .col-xs-pad-45-left {
    padding-left: 45px;
}
.col-xs-pad-45, .col-xs-pad-45-right {
    padding-right: 45px;
}
.col-xs-pad-45-top {
    padding-top: 45px;
}
.col-xs-pad-45-bottom {
    padding-bottom: 45px;
}
.col-xs-pad-50, .col-xs-pad-50-left {
    padding-left: 50px;
}
.col-xs-pad-50, .col-xs-pad-50-right {
    padding-right: 50px;
}
.col-xs-pad-50-top {
    padding-top: 50px;
}
.col-xs-pad-50-bottom {
    padding-bottom: 50px;
}
.col-xs-margin-0, .col-xs-margin-0-left {
    margin-left: 0;
}
.col-xs-margin-0, .col-xs-margin-0-right {
    margin-right: 0;
}
.col-xs-margin-0-top {
    margin-top: 0;
}
.col-xs-margin-0-bottom {
    margin-bottom: 0;
}
.col-xs-margin-5, .col-xs-margin-5-left {
    margin-left: 5px;
}
.col-xs-margin-5, .col-xs-margin-5-right {
    margin-right: 5px;
}
.col-xs-margin-5-top {
    margin-top: 5px;
}
.col-xs-margin-5-bottom {
    margin-bottom: 5px;
}
.col-xs-margin-10, .col-xs-margin-10-left {
    margin-left: 10px;
}
.col-xs-margin-10, .col-xs-margin-10-right {
    margin-right: 10px;
}
.col-xs-margin-10-top {
    margin-top: 10px;
}
.col-xs-margin-10-bottom {
    margin-bottom: 10px;
}
.col-xs-margin-15, .col-xs-margin-15-left {
    margin-left: 15px;
}
.col-xs-margin-15, .col-xs-margin-15-right {
    margin-right: 15px;
}
.col-xs-margin-15-top {
    margin-top: 15px;
}
.col-xs-margin-15-bottom {
    margin-bottom: 15px;
}
.col-xs-margin-20, .col-xs-margin-20-left {
    margin-left: 20px;
}
.col-xs-margin-20, .col-xs-margin-20-right {
    margin-right: 20px;
}
.col-xs-margin-20-top {
    margin-top: 20px;
}
.col-xs-margin-20-bottom {
    margin-bottom: 20px;
}
.col-xs-margin-25, .col-xs-margin-25-left {
    margin-left: 25px;
}
.col-xs-margin-25, .col-xs-margin-25-right {
    margin-right: 25px;
}
.col-xs-margin-25-top {
    margin-top: 25px;
}
.col-xs-margin-25-bottom {
    margin-bottom: 25px;
}
.col-xs-margin-30, .col-xs-margin-30-left {
    margin-left: 30px;
}
.col-xs-margin-30, .col-xs-margin-30-right {
    margin-right: 30px;
}
.col-xs-margin-30-top {
    margin-top: 30px;
}
.col-xs-margin-30-bottom {
    margin-bottom: 30px;
}
.col-xs-margin-35, .col-xs-margin-35-left {
    margin-left: 35px;
}
.col-xs-margin-35, .col-xs-margin-35-right {
    margin-right: 35px;
}
.col-xs-margin-35-top {
    margin-top: 35px;
}
.col-xs-margin-35-bottom {
    margin-bottom: 35px;
}
.col-xs-margin-40, .col-xs-margin-40-left {
    margin-left: 40px;
}
.col-xs-margin-40, .col-xs-margin-40-right {
    margin-right: 40px;
}
.col-xs-margin-40-top {
    margin-top: 40px;
}
.col-xs-margin-40-bottom {
    margin-bottom: 40px;
}
.col-xs-margin-45, .col-xs-margin-45-left {
    margin-left: 45px;
}
.col-xs-margin-45, .col-xs-margin-45-right {
    margin-right: 45px;
}
.col-xs-margin-45-top {
    margin-top: 45px;
}
.col-xs-margin-45-bottom {
    margin-bottom: 45px;
}
.col-xs-margin-50, .col-xs-margin-50-left {
    margin-left: 50px;
}
.col-xs-margin-50, .col-xs-margin-50-right {
    margin-right: 50px;
}
.col-xs-margin-50-top {
    margin-top: 50px;
}
.col-xs-margin-50-bottom {
    margin-bottom: 50px;
}


/*change 768px to 640px to accommodate a new breakpoint*/
@media (min-width:40em) {
    .rwd:before {
        content: "sm";
    }
    .container {
        max-width: 768px; /*990px;*/ /*750px;*/
    }
    .col-sm-1,
    .col-sm-2,
    .col-sm-3,
    .col-sm-4,
    .col-sm-5,
    .col-sm-6,
    .col-sm-7,
    .col-sm-8,
    .col-sm-9,
    .col-sm-10,
    .col-sm-11 {
        float: left;
    }
    .col-sm-1 {
        width: 8.333333333333332%;
    }
    .col-sm-2 {
        width: 16.666666666666664%;
    }
    .col-sm-3 {
        width: 25%;
    }
    .col-sm-4 {
        width: 33.33333333333333%;
    }
    .col-sm-5 {
        width: 41.66666666666667%;
    }
    .col-sm-6 {
        width: 50%;
    }
    .col-sm-7 {
        width: 58.333333333333336%;
    }
    .col-sm-8 {
        width: 66.66666666666666%;
    }
    .col-sm-9 {
        width: 75%;
    }
    .col-sm-10 {
        width: 83.33333333333334%;
    }
    .col-sm-11 {
        width: 91.66666666666666%;
    }
    .col-sm-12 {
        width: 100%;
    }
    .col-sm-push-0 {
        left: auto;
    }
    .col-sm-push-1 {
        left: 8.333333333333332%;
    }
    .col-sm-push-2 {
        left: 16.666666666666664%;
    }
    .col-sm-push-3 {
        left: 25%;
    }
    .col-sm-push-4 {
        left: 33.33333333333333%;
    }
    .col-sm-push-5 {
        left: 41.66666666666667%;
    }
    .col-sm-push-6 {
        left: 50%;
    }
    .col-sm-push-7 {
        left: 58.333333333333336%;
    }
    .col-sm-push-8 {
        left: 66.66666666666666%;
    }
    .col-sm-push-9 {
        left: 75%;
    }
    .col-sm-push-10 {
        left: 83.33333333333334%;
    }
    .col-sm-push-11 {
        left: 91.66666666666666%;
    }
    .col-sm-pull-0 {
        right: auto;
    }
    .col-sm-pull-1 {
        right: 8.333333333333332%;
    }
    .col-sm-pull-2 {
        right: 16.666666666666664%;
    }
    .col-sm-pull-3 {
        right: 25%;
    }
    .col-sm-pull-4 {
        right: 33.33333333333333%;
    }
    .col-sm-pull-5 {
        right: 41.66666666666667%;
    }
    .col-sm-pull-6 {
        right: 50%;
    }
    .col-sm-pull-7 {
        right: 58.333333333333336%;
    }
    .col-sm-pull-8 {
        right: 66.66666666666666%;
    }
    .col-sm-pull-9 {
        right: 75%;
    }
    .col-sm-pull-10 {
        right: 83.33333333333334%;
    }
    .col-sm-pull-11 {
        right: 91.66666666666666%;
    }
    .col-sm-offset-0 {
        margin-left: 0;
    }
    .col-sm-offset-1 {
        margin-left: 8.333333333333332%;
    }
    .col-sm-offset-2 {
        margin-left: 16.666666666666664%;
    }
    .col-sm-offset-3 {
        margin-left: 25%;
    }
    .col-sm-offset-4 {
        margin-left: 33.33333333333333%;
    }
    .col-sm-offset-5 {
        margin-left: 41.66666666666667%;
    }
    .col-sm-offset-6 {
        margin-left: 50%;
    }
    .col-sm-offset-7 {
        margin-left: 58.333333333333336%;
    }
    .col-sm-offset-8 {
        margin-left: 66.66666666666666%;
    }
    .col-sm-offset-9 {
        margin-left: 75%;
    }
    .col-sm-offset-10 {
        margin-left: 83.33333333333334%;
    }
    .col-sm-offset-11 {
        margin-left: 91.66666666666666%;
    }
    .col-sm-pad-0, .col-sm-pad-0-left {
        padding-left: 0;
    }
    .col-sm-pad-0, .col-sm-pad-0-right {
        padding-right: 0;
    }
    .col-sm-pad-0-top {
        padding-top: 0;
    }
    .col-sm-pad-0-bottom {
        padding-bottom: 0;
    }
    .col-sm-pad-5, .col-sm-pad-5-left {
        padding-left: 5px;
    }
    .col-sm-pad-5, .col-sm-pad-5-right {
        padding-right: 5px;
    }
    .col-sm-pad-5-top {
        padding-top: 5px;
    }
    .col-sm-pad-5-bottom {
        padding-bottom: 5px;
    }
    .col-sm-pad-10, .col-sm-pad-10-left {
        padding-left: 10px;
    }
    .col-sm-pad-10, .col-sm-pad-10-right {
        padding-right: 10px;
    }
    .col-sm-pad-10-top {
        padding-top: 10px;
    }
    .col-sm-pad-10-bottom {
        padding-bottom: 10px;
    }
    .col-sm-pad-15, .col-sm-pad-15-left {
        padding-left: 15px;
    }
    .col-sm-pad-15, .col-sm-pad-15-right {
        padding-right: 15px;
    }
    .col-sm-pad-15-top {
        padding-top: 15px;
    }
    .col-sm-pad-15-bottom {
        padding-bottom: 15px;
    }
    .col-sm-pad-20, .col-sm-pad-20-left {
        padding-left: 20px;
    }
    .col-sm-pad-20, .col-sm-pad-20-right {
        padding-right: 20px;
    }
    .col-sm-pad-20-top {
        padding-top: 20px;
    }
    .col-sm-pad-20-bottom {
        padding-bottom: 20px;
    }
    .col-sm-pad-25, .col-sm-pad-25-left {
        padding-left: 25px;
    }
    .col-sm-pad-25, .col-sm-pad-25-right {
        padding-right: 25px;
    }
    .col-sm-pad-25-top {
        padding-top: 25px;
    }
    .col-sm-pad-25-bottom {
        padding-bottom: 25px;
    }
    .col-sm-pad-30, .col-sm-pad-30-left {
        padding-left: 30px;
    }
    .col-sm-pad-30, .col-sm-pad-30-right {
        padding-right: 30px;
    }
    .col-sm-pad-30-top {
        padding-top: 30px;
    }
    .col-sm-pad-30-bottom {
        padding-bottom: 30px;
    }
    .col-sm-pad-35, .col-sm-pad-35-left {
        padding-left: 35px;
    }
    .col-sm-pad-35, .col-sm-pad-35-right {
        padding-right: 35px;
    }
    .col-sm-pad-35-top {
        padding-top: 35px;
    }
    .col-sm-pad-35-bottom {
        padding-bottom: 35px;
    }
    .col-sm-pad-40, .col-sm-pad-40-left {
        padding-left: 40px;
    }
    .col-sm-pad-40, .col-sm-pad-40-right {
        padding-right: 40px;
    }
    .col-sm-pad-40-top {
        padding-top: 40px;
    }
    .col-sm-pad-40-bottom {
        padding-bottom: 40px;
    }
    .col-sm-pad-45, .col-sm-pad-45-left {
        padding-left: 45px;
    }
    .col-sm-pad-45, .col-sm-pad-45-right {
        padding-right: 45px;
    }
    .col-sm-pad-45-top {
        padding-top: 45px;
    }
    .col-sm-pad-45-bottom {
        padding-bottom: 45px;
    }
    .col-sm-pad-50, .col-sm-pad-50-left {
        padding-left: 50px;
    }
    .col-sm-pad-50, .col-sm-pad-50-right {
        padding-right: 50px;
    }
    .col-sm-pad-50-top {
        padding-top: 50px;
    }
    .col-sm-pad-50-bottom {
        padding-bottom: 50px;
    }
    .col-sm-margin-0, .col-sm-margin-0-left {
        margin-left: 0;
    }
    .col-sm-margin-0, .col-sm-margin-0-right {
        margin-right: 0;
    }
    .col-sm-margin-0-top {
        margin-top: 0;
    }
    .col-sm-margin-0-bottom {
        margin-bottom: 0;
    }
    .col-sm-margin-5, .col-sm-margin-5-left {
        margin-left: 5px;
    }
    .col-sm-margin-5, .col-sm-margin-5-right {
        margin-right: 5px;
    }
    .col-sm-margin-5-top {
        margin-top: 5px;
    }
    .col-sm-margin-5-bottom {
        margin-bottom: 5px;
    }
    .col-sm-margin-10, .col-sm-margin-10-left {
        margin-left: 10px;
    }
    .col-sm-margin-10, .col-sm-margin-10-right {
        margin-right: 10px;
    }
    .col-sm-margin-10-top {
        margin-top: 10px;
    }
    .col-sm-margin-10-bottom {
        margin-bottom: 10px;
    }
    .col-sm-margin-15, .col-sm-margin-15-left {
        margin-left: 15px;
    }
    .col-sm-margin-15, .col-sm-margin-15-right {
        margin-right: 15px;
    }
    .col-sm-margin-15-top {
        margin-top: 15px;
    }
    .col-sm-margin-15-bottom {
        margin-bottom: 15px;
    }
    .col-sm-margin-20, .col-sm-margin-20-left {
        margin-left: 20px;
    }
    .col-sm-margin-20, .col-sm-margin-20-right {
        margin-right: 20px;
    }
    .col-sm-margin-20-top {
        margin-top: 20px;
    }
    .col-sm-margin-20-bottom {
        margin-bottom: 20px;
    }
    .col-sm-margin-25, .col-sm-margin-25-left {
        margin-left: 25px;
    }
    .col-sm-margin-25, .col-sm-margin-25-right {
        margin-right: 25px;
    }
    .col-sm-margin-25-top {
        margin-top: 25px;
    }
    .col-sm-margin-25-bottom {
        margin-bottom: 25px;
    }
    .col-sm-margin-30, .col-sm-margin-30-left {
        margin-left: 30px;
    }
    .col-sm-margin-30, .col-sm-margin-30-right {
        margin-right: 30px;
    }
    .col-sm-margin-30-top {
        margin-top: 30px;
    }
    .col-sm-margin-30-bottom {
        margin-bottom: 30px;
    }
    .col-sm-margin-35, .col-sm-margin-35-left {
        margin-left: 35px;
    }
    .col-sm-margin-35, .col-sm-margin-35-right {
        margin-right: 35px;
    }
    .col-sm-margin-35-top {
        margin-top: 35px;
    }
    .col-sm-margin-35-bottom {
        margin-bottom: 35px;
    }
    .col-sm-margin-40, .col-sm-margin-40-left {
        margin-left: 40px;
    }
    .col-sm-margin-40, .col-sm-margin-40-right {
        margin-right: 40px;
    }
    .col-sm-margin-40-top {
        margin-top: 40px;
    }
    .col-sm-margin-40-bottom {
        margin-bottom: 40px;
    }
    .col-sm-margin-45, .col-sm-margin-45-left {
        margin-left: 45px;
    }
    .col-sm-margin-45, .col-sm-margin-45-right {
        margin-right: 45px;
    }
    .col-sm-margin-45-top {
        margin-top: 45px;
    }
    .col-sm-margin-45-bottom {
        margin-bottom: 45px;
    }
    .col-sm-margin-50, .col-sm-margin-50-left {
        margin-left: 50px;
    }
    .col-sm-margin-50, .col-sm-margin-50-right {
        margin-right: 50px;
    }
    .col-sm-margin-50-top {
        margin-top: 50px;
    }
    .col-sm-margin-50-bottom {
        margin-bottom: 50px;
    }
}
/*change 992px to 768px to accommodate a new breakpoint at 640*/
@media (min-width:48em) {
    .rwd:before {
        content: "md";
    }
    .container {
        max-width: 990px; /*990px;*/ /*970px;*/
    }
    .col-md-1,
    .col-md-2,
    .col-md-3,
    .col-md-4,
    .col-md-5,
    .col-md-6,
    .col-md-7,
    .col-md-8,
    .col-md-9,
    .col-md-10,
    .col-md-11 {
        float: left;
    }
    .col-md-1 {
        width: 8.333333333333332%;
    }
    .col-md-2 {
        width: 16.666666666666664%;
    }
    .col-md-3 {
        width: 25%;
    }
    .col-md-4 {
        width: 33.33333333333333%;
    }
    .col-md-5 {
        width: 41.66666666666667%;
    }
    .col-md-6 {
        width: 50%;
    }
    .col-md-7 {
        width: 58.333333333333336%;
    }
    .col-md-8 {
        width: 66.66666666666666%;
    }
    .col-md-9 {
        width: 75%;
    }
    .col-md-10 {
        width: 83.33333333333334%;
    }
    .col-md-11 {
        width: 91.66666666666666%;
    }
    .col-md-12 {
        width: 100%;
    }
    .col-md-push-0 {
        left: auto;
    }
    .col-md-push-1 {
        left: 8.333333333333332%;
    }
    .col-md-push-2 {
        left: 16.666666666666664%;
    }
    .col-md-push-3 {
        left: 25%;
    }
    .col-md-push-4 {
        left: 33.33333333333333%;
    }
    .col-md-push-5 {
        left: 41.66666666666667%;
    }
    .col-md-push-6 {
        left: 50%;
    }
    .col-md-push-7 {
        left: 58.333333333333336%;
    }
    .col-md-push-8 {
        left: 66.66666666666666%;
    }
    .col-md-push-9 {
        left: 75%;
    }
    .col-md-push-10 {
        left: 83.33333333333334%;
    }
    .col-md-push-11 {
        left: 91.66666666666666%;
    }
    .col-md-pull-0 {
        right: auto;
    }
    .col-md-pull-1 {
        right: 8.333333333333332%;
    }
    .col-md-pull-2 {
        right: 16.666666666666664%;
    }
    .col-md-pull-3 {
        right: 25%;
    }
    .col-md-pull-4 {
        right: 33.33333333333333%;
    }
    .col-md-pull-5 {
        right: 41.66666666666667%;
    }
    .col-md-pull-6 {
        right: 50%;
    }
    .col-md-pull-7 {
        right: 58.333333333333336%;
    }
    .col-md-pull-8 {
        right: 66.66666666666666%;
    }
    .col-md-pull-9 {
        right: 75%;
    }
    .col-md-pull-10 {
        right: 83.33333333333334%;
    }
    .col-md-pull-11 {
        right: 91.66666666666666%;
    }
    .col-md-offset-0 {
        margin-left: 0;
    }
    .col-md-offset-1 {
        margin-left: 8.333333333333332%;
    }
    .col-md-offset-2 {
        margin-left: 16.666666666666664%;
    }
    .col-md-offset-3 {
        margin-left: 25%;
    }
    .col-md-offset-4 {
        margin-left: 33.33333333333333%;
    }
    .col-md-offset-5 {
        margin-left: 41.66666666666667%;
    }
    .col-md-offset-6 {
        margin-left: 50%;
    }
    .col-md-offset-7 {
        margin-left: 58.333333333333336%;
    }
    .col-md-offset-8 {
        margin-left: 66.66666666666666%;
    }
    .col-md-offset-9 {
        margin-left: 75%;
    }
    .col-md-offset-10 {
        margin-left: 83.33333333333334%;
    }
    .col-md-offset-11 {
        margin-left: 91.66666666666666%;
    }
    .col-md-pad-0, .col-md-pad-0-left {
        padding-left: 0;
    }
    .col-md-pad-0, .col-md-pad-0-right {
        padding-right: 0;
    }
    .col-md-pad-0-top {
        padding-top: 0;
    }
    .col-md-pad-0-bottom {
        padding-bottom: 0;
    }
    .col-md-pad-5, .col-md-pad-5-left {
        padding-left: 5px;
    }
    .col-md-pad-5, .col-md-pad-5-right {
        padding-right: 5px;
    }
    .col-md-pad-5-top {
        padding-top: 5px;
    }
    .col-md-pad-5-bottom {
        padding-bottom: 5px;
    }
    .col-md-pad-10, .col-md-pad-10-left {
        padding-left: 10px;
    }
    .col-md-pad-10, .col-md-pad-10-right {
        padding-right: 10px;
    }
    .col-md-pad-10-top {
        padding-top: 10px;
    }
    .col-md-pad-10-bottom {
        padding-bottom: 10px;
    }
    .col-md-pad-15, .col-md-pad-15-left {
        padding-left: 15px;
    }
    .col-md-pad-15, .col-md-pad-15-right {
        padding-right: 15px;
    }
    .col-md-pad-15-top {
        padding-top: 15px;
    }
    .col-md-pad-15-bottom {
        padding-bottom: 15px;
    }
    .col-md-pad-20, .col-md-pad-20-left {
        padding-left: 20px;
    }
    .col-md-pad-20, .col-md-pad-20-right {
        padding-right: 20px;
    }
    .col-md-pad-20-top {
        padding-top: 20px;
    }
    .col-md-pad-20-bottom {
        padding-bottom: 20px;
    }
    .col-md-pad-25, .col-md-pad-25-left {
        padding-left: 25px;
    }
    .col-md-pad-25, .col-md-pad-25-right {
        padding-right: 25px;
    }
    .col-md-pad-25-top {
        padding-top: 25px;
    }
    .col-md-pad-25-bottom {
        padding-bottom: 25px;
    }
    .col-md-pad-30, .col-md-pad-30-left {
        padding-left: 30px;
    }
    .col-md-pad-30, .col-md-pad-30-right {
        padding-right: 30px;
    }
    .col-md-pad-30-top {
        padding-top: 30px;
    }
    .col-md-pad-30-bottom {
        padding-bottom: 30px;
    }
    .col-md-pad-35, .col-md-pad-35-left {
        padding-left: 35px;
    }
    .col-md-pad-35, .col-md-pad-35-right {
        padding-right: 35px;
    }
    .col-md-pad-35-top {
        padding-top: 35px;
    }
    .col-md-pad-35-bottom {
        padding-bottom: 35px;
    }
    .col-md-pad-40, .col-md-pad-40-left {
        padding-left: 40px;
    }
    .col-md-pad-40, .col-md-pad-40-right {
        padding-right: 40px;
    }
    .col-md-pad-40-top {
        padding-top: 40px;
    }
    .col-md-pad-40-bottom {
        padding-bottom: 40px;
    }
    .col-md-pad-45, .col-md-pad-45-left {
        padding-left: 45px;
    }
    .col-md-pad-45, .col-md-pad-45-right {
        padding-right: 45px;
    }
    .col-md-pad-45-top {
        padding-top: 45px;
    }
    .col-md-pad-45-bottom {
        padding-bottom: 45px;
    }
    .col-md-pad-50, .col-md-pad-50-left {
        padding-left: 50px;
    }
    .col-md-pad-50, .col-md-pad-50-right {
        padding-right: 50px;
    }
    .col-md-pad-50-top {
        padding-top: 50px;
    }
    .col-md-pad-50-bottom {
        padding-bottom: 50px;
    }
    .col-md-margin-0, .col-md-margin-0-left {
        margin-left: 0;
    }
    .col-md-margin-0, .col-md-margin-0-right {
        margin-right: 0;
    }
    .col-md-margin-0-top {
        margin-top: 0;
    }
    .col-md-margin-0-bottom {
        margin-bottom: 0;
    }
    .col-md-margin-5, .col-md-margin-5-left {
        margin-left: 5px;
    }
    .col-md-margin-5, .col-md-margin-5-right {
        margin-right: 5px;
    }
    .col-md-margin-5-top {
        margin-top: 5px;
    }
    .col-md-margin-5-bottom {
        margin-bottom: 5px;
    }
    .col-md-margin-10, .col-md-margin-10-left {
        margin-left: 10px;
    }
    .col-md-margin-10, .col-md-margin-10-right {
        margin-right: 10px;
    }
    .col-md-margin-10-top {
        margin-top: 10px;
    }
    .col-md-margin-10-bottom {
        margin-bottom: 10px;
    }
    .col-md-margin-15, .col-md-margin-15-left {
        margin-left: 15px;
    }
    .col-md-margin-15, .col-md-margin-15-right {
        margin-right: 15px;
    }
    .col-md-margin-15-top {
        margin-top: 15px;
    }
    .col-md-margin-15-bottom {
        margin-bottom: 15px;
    }
    .col-md-margin-20, .col-md-margin-20-left {
        margin-left: 20px;
    }
    .col-md-margin-20, .col-md-margin-20-right {
        margin-right: 20px;
    }
    .col-md-margin-20-top {
        margin-top: 20px;
    }
    .col-md-margin-20-bottom {
        margin-bottom: 20px;
    }
    .col-md-margin-25, .col-md-margin-25-left {
        margin-left: 25px;
    }
    .col-md-margin-25, .col-md-margin-25-right {
        margin-right: 25px;
    }
    .col-md-margin-25-top {
        margin-top: 25px;
    }
    .col-md-margin-25-bottom {
        margin-bottom: 25px;
    }
    .col-md-margin-30, .col-md-margin-30-left {
        margin-left: 30px;
    }
    .col-md-margin-30, .col-md-margin-30-right {
        margin-right: 30px;
    }
    .col-md-margin-30-top {
        margin-top: 30px;
    }
    .col-md-margin-30-bottom {
        margin-bottom: 30px;
    }
    .col-md-margin-35, .col-md-margin-35-left {
        margin-left: 35px;
    }
    .col-md-margin-35, .col-md-margin-35-right {
        margin-right: 35px;
    }
    .col-md-margin-35-top {
        margin-top: 35px;
    }
    .col-md-margin-35-bottom {
        margin-bottom: 35px;
    }
    .col-md-margin-40, .col-md-margin-40-left {
        margin-left: 40px;
    }
    .col-md-margin-40, .col-md-margin-40-right {
        margin-right: 40px;
    }
    .col-md-margin-40-top {
        margin-top: 40px;
    }
    .col-md-margin-40-bottom {
        margin-bottom: 40px;
    }
    .col-md-margin-45, .col-md-margin-45-left {
        margin-left: 45px;
    }
    .col-md-margin-45, .col-md-margin-45-right {
        margin-right: 45px;
    }
    .col-md-margin-45-top {
        margin-top: 45px;
    }
    .col-md-margin-45-bottom {
        margin-bottom: 45px;
    }
    .col-md-margin-50, .col-md-margin-50-left {
        margin-left: 50px;
    }
    .col-md-margin-50, .col-md-margin-50-right {
        margin-right: 50px;
    }
    .col-md-margin-50-top {
        margin-top: 50px;
    }
    .col-md-margin-50-bottom {
        margin-bottom: 50px;
    }
}
/*change 1200px to 992px to accommodate a new breakpoint at 640*/
@media (min-width:62em) {
    .rwd:before {
        content: "lg";
    }
    .container {
        max-width: 990px; /*990px;*/ /*1170px*/
    }
    .col-lg-1,
    .col-lg-2,
    .col-lg-3,
    .col-lg-4,
    .col-lg-5,
    .col-lg-6,
    .col-lg-7,
    .col-lg-8,
    .col-lg-9,
    .col-lg-10,
    .col-lg-11 {
        float: left;
    }
    .col-lg-1 {
        width: 8.333333333333332%;
    }
    .col-lg-2 {
        width: 16.666666666666664%;
    }
    .col-lg-3 {
        width: 25%;
    }
    .col-lg-4 {
        width: 33.33333333333333%;
    }
    .col-lg-5 {
        width: 41.66666666666667%;
    }
    .col-lg-6 {
        width: 50%;
    }
    .col-lg-7 {
        width: 58.333333333333336%;
    }
    .col-lg-8 {
        width: 66.66666666666666%;
    }
    .col-lg-9 {
        width: 75%;
    }
    .col-lg-10 {
        width: 83.33333333333334%;
    }
    .col-lg-11 {
        width: 91.66666666666666%;
    }
    .col-lg-12 {
        width: 100%;
    }
    .col-lg-push-0 {
        left: auto;
    }
    .col-lg-push-1 {
        left: 8.333333333333332%;
    }
    .col-lg-push-2 {
        left: 16.666666666666664%;
    }
    .col-lg-push-3 {
        left: 25%;
    }
    .col-lg-push-4 {
        left: 33.33333333333333%;
    }
    .col-lg-push-5 {
        left: 41.66666666666667%;
    }
    .col-lg-push-6 {
        left: 50%;
    }
    .col-lg-push-7 {
        left: 58.333333333333336%;
    }
    .col-lg-push-8 {
        left: 66.66666666666666%;
    }
    .col-lg-push-9 {
        left: 75%;
    }
    .col-lg-push-10 {
        left: 83.33333333333334%;
    }
    .col-lg-push-11 {
        left: 91.66666666666666%;
    }
    .col-lg-pull-0 {
        right: auto;
    }
    .col-lg-pull-1 {
        right: 8.333333333333332%;
    }
    .col-lg-pull-2 {
        right: 16.666666666666664%;
    }
    .col-lg-pull-3 {
        right: 25%;
    }
    .col-lg-pull-4 {
        right: 33.33333333333333%;
    }
    .col-lg-pull-5 {
        right: 41.66666666666667%;
    }
    .col-lg-pull-6 {
        right: 50%;
    }
    .col-lg-pull-7 {
        right: 58.333333333333336%;
    }
    .col-lg-pull-8 {
        right: 66.66666666666666%;
    }
    .col-lg-pull-9 {
        right: 75%;
    }
    .col-lg-pull-10 {
        right: 83.33333333333334%;
    }
    .col-lg-pull-11 {
        right: 91.66666666666666%;
    }
    .col-lg-offset-0 {
        margin-left: 0;
    }
    .col-lg-offset-1 {
        margin-left: 8.333333333333332%;
    }
    .col-lg-offset-2 {
        margin-left: 16.666666666666664%;
    }
    .col-lg-offset-3 {
        margin-left: 25%;
    }
    .col-lg-offset-4 {
        margin-left: 33.33333333333333%;
    }
    .col-lg-offset-5 {
        margin-left: 41.66666666666667%;
    }
    .col-lg-offset-6 {
        margin-left: 50%;
    }
    .col-lg-offset-7 {
        margin-left: 58.333333333333336%;
    }
    .col-lg-offset-8 {
        margin-left: 66.66666666666666%;
    }
    .col-lg-offset-9 {
        margin-left: 75%;
    }
    .col-lg-offset-10 {
        margin-left: 83.33333333333334%;
    }
    .col-lg-offset-11 {
        margin-left: 91.66666666666666%;
    }
    .col-lg-pad-0, .col-lg-pad-0-left {
        padding-left: 0;
    }
    .col-lg-pad-0, .col-lg-pad-0-right {
        padding-right: 0;
    }
    .col-lg-pad-0-top {
        padding-top: 0;
    }
    .col-lg-pad-0-bottom {
        padding-bottom: 0;
    }
    .col-lg-pad-5, .col-lg-pad-5-left {
        padding-left: 5px;
    }
    .col-lg-pad-5, .col-lg-pad-5-right {
        padding-right: 5px;
    }
    .col-lg-pad-5-top {
        padding-top: 5px;
    }
    .col-lg-pad-5-bottom {
        padding-bottom: 5px;
    }
    .col-lg-pad-10, .col-lg-pad-10-left {
        padding-left: 10px;
    }
    .col-lg-pad-10, .col-lg-pad-10-right {
        padding-right: 10px;
    }
    .col-lg-pad-10-top {
        padding-top: 10px;
    }
    .col-lg-pad-10-bottom {
        padding-bottom: 10px;
    }
    .col-lg-pad-15, .col-lg-pad-15-left {
        padding-left: 15px;
    }
    .col-lg-pad-15, .col-lg-pad-15-right {
        padding-right: 15px;
    }
    .col-lg-pad-15-top {
        padding-top: 15px;
    }
    .col-lg-pad-15-bottom {
        padding-bottom: 15px;
    }
    .col-lg-pad-20, .col-lg-pad-20-left {
        padding-left: 20px;
    }
    .col-lg-pad-20, .col-lg-pad-20-right {
        padding-right: 20px;
    }
    .col-lg-pad-20-top {
        padding-top: 20px;
    }
    .col-lg-pad-20-bottom {
        padding-bottom: 20px;
    }
    .col-lg-pad-25, .col-lg-pad-25-left {
        padding-left: 25px;
    }
    .col-lg-pad-25, .col-lg-pad-25-right {
        padding-right: 25px;
    }
    .col-lg-pad-25-top {
        padding-top: 25px;
    }
    .col-lg-pad-25-bottom {
        padding-bottom: 25px;
    }
    .col-lg-pad-30, .col-lg-pad-30-left {
        padding-left: 30px;
    }
    .col-lg-pad-30, .col-lg-pad-30-right {
        padding-right: 30px;
    }
    .col-lg-pad-30-top {
        padding-top: 30px;
    }
    .col-lg-pad-30-bottom {
        padding-bottom: 30px;
    }
    .col-lg-pad-35, .col-lg-pad-35-left {
        padding-left: 35px;
    }
    .col-lg-pad-35, .col-lg-pad-35-right {
        padding-right: 35px;
    }
    .col-lg-pad-35-top {
        padding-top: 35px;
    }
    .col-lg-pad-35-bottom {
        padding-bottom: 35px;
    }
    .col-lg-pad-40, .col-lg-pad-40-left {
        padding-left: 40px;
    }
    .col-lg-pad-40, .col-lg-pad-40-right {
        padding-right: 40px;
    }
    .col-lg-pad-40-top {
        padding-top: 40px;
    }
    .col-lg-pad-40-bottom {
        padding-bottom: 40px;
    }
    .col-lg-pad-45, .col-lg-pad-45-left {
        padding-left: 45px;
    }
    .col-lg-pad-45, .col-lg-pad-45-right {
        padding-right: 45px;
    }
    .col-lg-pad-45-top {
        padding-top: 45px;
    }
    .col-lg-pad-45-bottom {
        padding-bottom: 45px;
    }
    .col-lg-pad-50, .col-lg-pad-50-left {
        padding-left: 50px;
    }
    .col-lg-pad-50, .col-lg-pad-50-right {
        padding-right: 50px;
    }
    .col-lg-pad-50-top {
        padding-top: 50px;
    }
    .col-lg-pad-50-bottom {
        padding-bottom: 50px;
    }
    .col-lg-margin-0, .col-lg-margin-0-left {
        margin-left: 0;
    }
    .col-lg-margin-0, .col-lg-margin-0-right {
        margin-right: 0;
    }
    .col-lg-margin-0-top {
        margin-top: 0;
    }
    .col-lg-margin-0-bottom {
        margin-bottom: 0;
    }
    .col-lg-margin-5, .col-lg-margin-5-left {
        margin-left: 5px;
    }
    .col-lg-margin-5, .col-lg-margin-5-right {
        margin-right: 5px;
    }
    .col-lg-margin-5-top {
        margin-top: 5px;
    }
    .col-lg-margin-5-bottom {
        margin-bottom: 5px;
    }
    .col-lg-margin-10, .col-lg-margin-10-left {
        margin-left: 10px;
    }
    .col-lg-margin-10, .col-lg-margin-10-right {
        margin-right: 10px;
    }
    .col-lg-margin-10-top {
        margin-top: 10px;
    }
    .col-lg-margin-10-bottom {
        margin-bottom: 10px;
    }
    .col-lg-margin-15, .col-lg-margin-15-left {
        margin-left: 15px;
    }
    .col-lg-margin-15, .col-lg-margin-15-right {
        margin-right: 15px;
    }
    .col-lg-margin-15-top {
        margin-top: 15px;
    }
    .col-lg-margin-15-bottom {
        margin-bottom: 15px;
    }
    .col-lg-margin-20, .col-lg-margin-20-left {
        margin-left: 20px;
    }
    .col-lg-margin-20, .col-lg-margin-20-right {
        margin-right: 20px;
    }
    .col-lg-margin-20-top {
        margin-top: 20px;
    }
    .col-lg-margin-20-bottom {
        margin-bottom: 20px;
    }
    .col-lg-margin-25, .col-lg-margin-25-left {
        margin-left: 25px;
    }
    .col-lg-margin-25, .col-lg-margin-25-right {
        margin-right: 25px;
    }
    .col-lg-margin-25-top {
        margin-top: 25px;
    }
    .col-lg-margin-25-bottom {
        margin-bottom: 25px;
    }
    .col-lg-margin-30, .col-lg-margin-30-left {
        margin-left: 30px;
    }
    .col-lg-margin-30, .col-lg-margin-30-right {
        margin-right: 30px;
    }
    .col-lg-margin-30-top {
        margin-top: 30px;
    }
    .col-lg-margin-30-bottom {
        margin-bottom: 30px;
    }
    .col-lg-margin-35, .col-lg-margin-35-left {
        margin-left: 35px;
    }
    .col-lg-margin-35, .col-lg-margin-35-right {
        margin-right: 35px;
    }
    .col-lg-margin-35-top {
        margin-top: 35px;
    }
    .col-lg-margin-35-bottom {
        margin-bottom: 35px;
    }
    .col-lg-margin-40, .col-lg-margin-40-left {
        margin-left: 40px;
    }
    .col-lg-margin-40, .col-lg-margin-40-right {
        margin-right: 40px;
    }
    .col-lg-margin-40-top {
        margin-top: 40px;
    }
    .col-lg-margin-40-bottom {
        margin-bottom: 40px;
    }
    .col-lg-margin-45, .col-lg-margin-45-left {
        margin-left: 45px;
    }
    .col-lg-margin-45, .col-lg-margin-45-right {
        margin-right: 45px;
    }
    .col-lg-margin-45-top {
        margin-top: 45px;
    }
    .col-lg-margin-45-bottom {
        margin-bottom: 45px;
    }
    .col-lg-margin-50, .col-lg-margin-50-left {
        margin-left: 50px;
    }
    .col-lg-margin-50, .col-lg-margin-50-right {
        margin-right: 50px;
    }
    .col-lg-margin-50-top {
        margin-top: 50px;
    }
    .col-lg-margin-50-bottom {
        margin-bottom: 50px;
    }
}

/* To turn off rwd, add .no-rwd to the html element */
.no-rwd #container .container {
    max-width: 960px; /*990px;*/
    width: 960px !important; /*990px*/
}

.no-rwd .col-sm-1, .no-rwd .col-sm-2, .no-rwd .col-sm-3, .no-rwd .col-sm-4, .no-rwd .col-sm-5, .no-rwd .col-sm-6, .no-rwd .col-sm-7, .no-rwd .col-sm-8, .no-rwd .col-sm-9, .no-rwd .col-sm-10, .no-rwd .col-sm-11
.no-rwd .col-md-1, .no-rwd .col-md-2, .no-rwd .col-md-3, .no-rwd .col-md-4, .no-rwd .col-md-5, .no-rwd .col-md-6, .no-rwd .col-md-7, .no-rwd .col-md-8, .no-rwd .col-md-9, .no-rwd .col-md-10, .no-rwd .col-md-11
.no-rwd .col-lg-1, .no-rwd .col-lg-2, .no-rwd .col-lg-3, .no-rwd .col-lg-4, .no-rwd .col-lg-5, .no-rwd .col-lg-6, .no-rwd .col-lg-7, .no-rwd .col-lg-8, .no-rwd .col-lg-9, .no-rwd .col-lg-10, .no-rwd .col-lg-11 {
    float: left;
}
.no-rwd .col-sm-1 {
    width: 8.333333333333332%;
}
.no-rwd .col-sm-2 {
    width: 16.666666666666664%;
}
.no-rwd .col-sm-3 {
    width: 25%;
}
.no-rwd .col-sm-4 {
    width: 33.33333333333333%;
}
.no-rwd .col-sm-5 {
    width: 41.66666666666667%;
}
.no-rwd .col-sm-6 {
    width: 50%;
}
.no-rwd .col-sm-7 {
    width: 58.333333333333336%;
}
.no-rwd .col-sm-8 {
    width: 66.66666666666666%;
}
.no-rwd .col-sm-9 {
    width: 75%;
}
.no-rwd .col-sm-10 {
    width: 83.33333333333334%;
}
.no-rwd .col-sm-11 {
    width: 91.66666666666666%;
}
.no-rwd .col-sm-12 {
    width: 100%;
}
.no-rwd .col-sm-push-1 {
    left: 8.333333333333332%;
}
.no-rwd .col-sm-push-2 {
    left: 16.666666666666664%;
}
.no-rwd .col-sm-push-3 {
    left: 25%;
}
.no-rwd .col-sm-push-4 {
    left: 33.33333333333333%;
}
.no-rwd .col-sm-push-5 {
    left: 41.66666666666667%;
}
.no-rwd .col-sm-push-6 {
    left: 50%;
}
.no-rwd .col-sm-push-7 {
    left: 58.333333333333336%;
}
.no-rwd .col-sm-push-8 {
    left: 66.66666666666666%;
}
.no-rwd .col-sm-push-9 {
    left: 75%;
}
.no-rwd .col-sm-push-10 {
    left: 83.33333333333334%;
}
.no-rwd .col-sm-push-11 {
    left: 91.66666666666666%;
}
.no-rwd .col-sm-pull-1 {
    right: 8.333333333333332%;
}
.no-rwd .col-sm-pull-2 {
    right: 16.666666666666664%;
}
.no-rwd .col-sm-pull-3 {
    right: 25%;
}
.no-rwd .col-sm-pull-4 {
    right: 33.33333333333333%;
}
.no-rwd .col-sm-pull-5 {
    right: 41.66666666666667%;
}
.no-rwd .col-sm-pull-6 {
    right: 50%;
}
.no-rwd .col-sm-pull-7 {
    right: 58.333333333333336%;
}
.no-rwd .col-sm-pull-8 {
    right: 66.66666666666666%;
}
.no-rwd .col-sm-pull-9 {
    right: 75%;
}
.no-rwd .col-sm-pull-10 {
    right: 83.33333333333334%;
}
.no-rwd .col-sm-pull-11 {
    right: 91.66666666666666%;
}
.no-rwd .col-sm-offset-1 {
    margin-left: 8.333333333333332%;
}
.no-rwd .col-sm-offset-2 {
    margin-left: 16.666666666666664%;
}
.no-rwd .col-sm-offset-3 {
    margin-left: 25%;
}
.no-rwd .col-sm-offset-4 {
    margin-left: 33.33333333333333%;
}
.no-rwd .col-sm-offset-5 {
    margin-left: 41.66666666666667%;
}
.no-rwd .col-sm-offset-6 {
    margin-left: 50%;
}
.no-rwd .col-sm-offset-7 {
    margin-left: 58.333333333333336%;
}
.no-rwd .col-sm-offset-8 {
    margin-left: 66.66666666666666%;
}
.no-rwd .col-sm-offset-9 {
    margin-left: 75%;
}
.no-rwd .col-sm-offset-10 {
    margin-left: 83.33333333333334%;
}
.no-rwd .col-sm-offset-11 {
    margin-left: 91.66666666666666%;
}
.no-rwd .col-md-1 {
    width: 8.333333333333332%;
}
.no-rwd .col-md-2 {
    width: 16.666666666666664%;
}
.no-rwd .col-md-3 {
    width: 25%;
}
.no-rwd .col-md-4 {
    width: 33.33333333333333%;
}
.no-rwd .col-md-5 {
    width: 41.66666666666667%;
}
.no-rwd .col-md-6 {
    width: 50%;
}
.no-rwd .col-md-7 {
    width: 58.333333333333336%;
}
.no-rwd .col-md-8 {
    width: 66.66666666666666%;
}
.no-rwd .col-md-9 {
    width: 75%;
}
.no-rwd .col-md-10 {
    width: 83.33333333333334%;
}
.no-rwd .col-md-11 {
    width: 91.66666666666666%;
}
.no-rwd .col-md-12 {
    width: 100%;
}
.no-rwd .col-md-push-0 {
    left: auto;
}
.no-rwd .col-md-push-1 {
    left: 8.333333333333332%;
}
.no-rwd .col-md-push-2 {
    left: 16.666666666666664%;
}
.no-rwd .col-md-push-3 {
    left: 25%;
}
.no-rwd .col-md-push-4 {
    left: 33.33333333333333%;
}
.no-rwd .col-md-push-5 {
    left: 41.66666666666667%;
}
.no-rwd .col-md-push-6 {
    left: 50%;
}
.no-rwd .col-md-push-7 {
    left: 58.333333333333336%;
}
.no-rwd .col-md-push-8 {
    left: 66.66666666666666%;
}
.no-rwd .col-md-push-9 {
    left: 75%;
}
.no-rwd .col-md-push-10 {
    left: 83.33333333333334%;
}
.no-rwd .col-md-push-11 {
    left: 91.66666666666666%;
}
.no-rwd .col-md-pull-0 {
    right: auto;
}
.no-rwd .col-md-pull-1 {
    right: 8.333333333333332%;
}
.no-rwd .col-md-pull-2 {
    right: 16.666666666666664%;
}
.no-rwd .col-md-pull-3 {
    right: 25%;
}
.no-rwd .col-md-pull-4 {
    right: 33.33333333333333%;
}
.no-rwd .col-md-pull-5 {
    right: 41.66666666666667%;
}
.no-rwd .col-md-pull-6 {
    right: 50%;
}
.no-rwd .col-md-pull-7 {
    right: 58.333333333333336%;
}
.no-rwd .col-md-pull-8 {
    right: 66.66666666666666%;
}
.no-rwd .col-md-pull-9 {
    right: 75%;
}
.no-rwd .col-md-pull-10 {
    right: 83.33333333333334%;
}
.no-rwd .col-md-pull-11 {
    right: 91.66666666666666%;
}
.no-rwd .col-md-offset-0 {
    margin-left: 0;
}
.no-rwd .col-md-offset-1 {
    margin-left: 8.333333333333332%;
}
.no-rwd .col-md-offset-2 {
    margin-left: 16.666666666666664%;
}
.no-rwd .col-md-offset-3 {
    margin-left: 25%;
}
.no-rwd .col-md-offset-4 {
    margin-left: 33.33333333333333%;
}
.no-rwd .col-md-offset-5 {
    margin-left: 41.66666666666667%;
}
.no-rwd .col-md-offset-6 {
    margin-left: 50%;
}
.no-rwd .col-md-offset-7 {
    margin-left: 58.333333333333336%;
}
.no-rwd .col-md-offset-8 {
    margin-left: 66.66666666666666%;
}
.no-rwd .col-md-offset-9 {
    margin-left: 75%;
}
.no-rwd .col-md-offset-10 {
    margin-left: 83.33333333333334%;
}
.no-rwd .col-md-offset-11 {
    margin-left: 91.66666666666666%;
}
.no-rwd .col-lg-1 {
    width: 8.333333333333332%;
}
.no-rwd .col-lg-2 {
    width: 16.666666666666664%;
}
.no-rwd .col-lg-3 {
    width: 25%;
}
.no-rwd .col-lg-4 {
    width: 33.33333333333333%;
}
.no-rwd .col-lg-5 {
    width: 41.66666666666667%;
}
.no-rwd .col-lg-6 {
    width: 50%;
}
.no-rwd .col-lg-7 {
    width: 58.333333333333336%;
}
.no-rwd .col-lg-8 {
    width: 66.66666666666666%;
}
.no-rwd .col-lg-9 {
    width: 75%;
}
.no-rwd .col-lg-10 {
    width: 83.33333333333334%;
}
.no-rwd .col-lg-11 {
    width: 91.66666666666666%;
}
.no-rwd .col-lg-12 {
    width: 100%;
}
.no-rwd .col-lg-push-0 {
    left: auto;
}
.no-rwd .col-lg-push-1 {
    left: 8.333333333333332%;
}
.no-rwd .col-lg-push-2 {
    left: 16.666666666666664%;
}
.no-rwd .col-lg-push-3 {
    left: 25%;
}
.no-rwd .col-lg-push-4 {
    left: 33.33333333333333%;
}
.no-rwd .col-lg-push-5 {
    left: 41.66666666666667%;
}
.no-rwd .col-lg-push-6 {
    left: 50%;
}
.no-rwd .col-lg-push-7 {
    left: 58.333333333333336%;
}
.no-rwd .col-lg-push-8 {
    left: 66.66666666666666%;
}
.no-rwd .col-lg-push-9 {
    left: 75%;
}
.no-rwd .col-lg-push-10 {
    left: 83.33333333333334%;
}
.no-rwd .col-lg-push-11 {
    left: 91.66666666666666%;
}
.no-rwd .col-lg-pull-0 {
    right: auto;
}
.no-rwd .col-lg-pull-1 {
    right: 8.333333333333332%;
}
.no-rwd .col-lg-pull-2 {
    right: 16.666666666666664%;
}
.no-rwd .col-lg-pull-3 {
    right: 25%;
}
.no-rwd .col-lg-pull-4 {
    right: 33.33333333333333%;
}
.no-rwd .col-lg-pull-5 {
    right: 41.66666666666667%;
}
.no-rwd .col-lg-pull-6 {
    right: 50%;
}
.no-rwd .col-lg-pull-7 {
    right: 58.333333333333336%;
}
.no-rwd .col-lg-pull-8 {
    right: 66.66666666666666%;
}
.no-rwd .col-lg-pull-9 {
    right: 75%;
}
.no-rwd .col-lg-pull-10 {
    right: 83.33333333333334%;
}
.no-rwd .col-lg-pull-11 {
    right: 91.66666666666666%;
}
.no-rwd .col-lg-offset-0 {
    margin-left: 0;
}
.no-rwd .col-lg-offset-1 {
    margin-left: 8.333333333333332%;
}
.no-rwd .col-lg-offset-2 {
    margin-left: 16.666666666666664%;
}
.no-rwd .col-lg-offset-3 {
    margin-left: 25%;
}
.no-rwd .col-lg-offset-4 {
    margin-left: 33.33333333333333%;
}
.no-rwd .col-lg-offset-5 {
    margin-left: 41.66666666666667%;
}
.no-rwd .col-lg-offset-6 {
    margin-left: 50%;
}
.no-rwd .col-lg-offset-7 {
    margin-left: 58.333333333333336%;
}
.no-rwd .col-lg-offset-8 {
    margin-left: 66.66666666666666%;
}
.no-rwd .col-lg-offset-9 {
    margin-left: 75%;
}
.no-rwd .col-lg-offset-10 {
    margin-left: 83.33333333333334%;
}
.no-rwd .col-lg-offset-11 {
    margin-left: 91.66666666666666%;
}


/*
 * Floats
 */
.floatleft, .float-left{
    float: left;
}
.floatright, .float-right{
    float: right;
}

/*
 * RWD - applies the .hidden based on major breakpoints
 */
@media (max-width:39.9375em) {
    .xs-hidden {
        display: none !important; visibility: hidden;
    }
    .xs-visuallyhidden {
        border: 0; clip: rect(0 0 0 0); height: 1px; margin: -1px; overflow: hidden; padding: 0; position: absolute; width: 1px;
    }
}
@media (min-width:40em) and (max-width:47.9375em) {
    .sm-hidden {
        display: none !important; visibility: hidden;
    }
    .sm-visuallyhidden {
        border: 0; clip: rect(0 0 0 0); height: 1px; margin: -1px; overflow: hidden; padding: 0; position: absolute; width: 1px;
    }
}
@media (min-width:48em) and (max-width: 61.9375em) {
    .md-hidden {
        display: none !important; visibility: hidden;
    }
    .md-visuallyhidden {
        border: 0; clip: rect(0 0 0 0); height: 1px; margin: -1px; overflow: hidden; padding: 0; position: absolute; width: 1px;
    }
}
@media (min-width:62em) {
    .lg-hidden {
        display: none !important; visibility: hidden;
    }
    .lg-visuallyhidden {
        border: 0; clip: rect(0 0 0 0); height: 1px; margin: -1px; overflow: hidden; padding: 0; position: absolute; width: 1px;
    }
}
.hidden-xs-up {
    display: none !important; visibility: hidden;
}
@media (max-width: 39.9375em) {
    .hidden-xs-down {
        display: none !important; visibility: hidden;
    }
    .visuallyhidden-xs-down {
        border: 0; clip: rect(0 0 0 0); height: 1px; margin: -1px; overflow: hidden; padding: 0; position: absolute; width: 1px;
    }
}
@media (min-width: 40em) {
    .hidden-sm-up {
        display: none !important; visibility: hidden;
    }
    .visuallyhidden-sm-up {
        border: 0; clip: rect(0 0 0 0); height: 1px; margin: -1px; overflow: hidden; padding: 0; position: absolute; width: 1px;
    }
}
@media (max-width: 47.9375em) {
    .hidden-sm-down {
        display: none !important; visibility: hidden;
    }
    .visuallyhidden-sm-down {
        border: 0; clip: rect(0 0 0 0); height: 1px; margin: -1px; overflow: hidden; padding: 0; position: absolute; width: 1px;
    }
}
@media (min-width: 48em) {
    .hidden-md-up {
        display: none !important; visibility: hidden;
    }
    .visuallyhidden-md-up {
        border: 0; clip: rect(0 0 0 0); height: 1px; margin: -1px; overflow: hidden; padding: 0; position: absolute; width: 1px;
    }
}
@media (max-width: 61.9375em) {
    .hidden-md-down {
        display: none !important; visibility: hidden;
    }
    .visuallyhidden-md-down {
        border: 0; clip: rect(0 0 0 0); height: 1px; margin: -1px; overflow: hidden; padding: 0; position: absolute; width: 1px;
    }
}
@media (min-width: 62em) {
    .hidden-lg-up {
        display: none !important; visibility: hidden;
    }
    .visuallyhidden-lg-up {
        border: 0; clip: rect(0 0 0 0); height: 1px; margin: -1px; overflow: hidden; padding: 0; position: absolute; width: 1px;
    }
}
.hidden-lg-down {
    display: none !important; visibility: hidden;
}


@media print {

    .rwd:before {
        content: "sm";
    }
    .container {
        max-width: 768px; /*990px;*/ /*750px;*/
    }
    .col-sm-1,
    .col-sm-2,
    .col-sm-3,
    .col-sm-4,
    .col-sm-5,
    .col-sm-6,
    .col-sm-7,
    .col-sm-8,
    .col-sm-9,
    .col-sm-10,
    .col-sm-11 {
        float: left;
    }
    .col-sm-1 {
        width: 8.333333333333332%;
    }
    .col-sm-2 {
        width: 16.666666666666664%;
    }
    .col-sm-3 {
        width: 25%;
    }
    .col-sm-4 {
        width: 33.33333333333333%;
    }
    .col-sm-5 {
        width: 41.66666666666667%;
    }
    .col-sm-6 {
        width: 50%;
    }
    .col-sm-7 {
        width: 58.333333333333336%;
    }
    .col-sm-8 {
        width: 66.66666666666666%;
    }
    .col-sm-9 {
        width: 75%;
    }
    .col-sm-10 {
        width: 83.33333333333334%;
    }
    .col-sm-11 {
        width: 91.66666666666666%;
    }
    .col-sm-12 {
        width: 100%;
    }
    .col-sm-push-0 {
        left: auto;
    }
    .col-sm-push-1 {
        left: 8.333333333333332%;
    }
    .col-sm-push-2 {
        left: 16.666666666666664%;
    }
    .col-sm-push-3 {
        left: 25%;
    }
    .col-sm-push-4 {
        left: 33.33333333333333%;
    }
    .col-sm-push-5 {
        left: 41.66666666666667%;
    }
    .col-sm-push-6 {
        left: 50%;
    }
    .col-sm-push-7 {
        left: 58.333333333333336%;
    }
    .col-sm-push-8 {
        left: 66.66666666666666%;
    }
    .col-sm-push-9 {
        left: 75%;
    }
    .col-sm-push-10 {
        left: 83.33333333333334%;
    }
    .col-sm-push-11 {
        left: 91.66666666666666%;
    }
    .col-sm-pull-0 {
        right: auto;
    }
    .col-sm-pull-1 {
        right: 8.333333333333332%;
    }
    .col-sm-pull-2 {
        right: 16.666666666666664%;
    }
    .col-sm-pull-3 {
        right: 25%;
    }
    .col-sm-pull-4 {
        right: 33.33333333333333%;
    }
    .col-sm-pull-5 {
        right: 41.66666666666667%;
    }
    .col-sm-pull-6 {
        right: 50%;
    }
    .col-sm-pull-7 {
        right: 58.333333333333336%;
    }
    .col-sm-pull-8 {
        right: 66.66666666666666%;
    }
    .col-sm-pull-9 {
        right: 75%;
    }
    .col-sm-pull-10 {
        right: 83.33333333333334%;
    }
    .col-sm-pull-11 {
        right: 91.66666666666666%;
    }
    .col-sm-offset-0 {
        margin-left: 0;
    }
    .col-sm-offset-1 {
        margin-left: 8.333333333333332%;
    }
    .col-sm-offset-2 {
        margin-left: 16.666666666666664%;
    }
    .col-sm-offset-3 {
        margin-left: 25%;
    }
    .col-sm-offset-4 {
        margin-left: 33.33333333333333%;
    }
    .col-sm-offset-5 {
        margin-left: 41.66666666666667%;
    }
    .col-sm-offset-6 {
        margin-left: 50%;
    }
    .col-sm-offset-7 {
        margin-left: 58.333333333333336%;
    }
    .col-sm-offset-8 {
        margin-left: 66.66666666666666%;
    }
    .col-sm-offset-9 {
        margin-left: 75%;
    }
    .col-sm-offset-10 {
        margin-left: 83.33333333333334%;
    }
    .col-sm-offset-11 {
        margin-left: 91.66666666666666%;
    }
    .col-sm-pad-0, .col-sm-pad-0-left {
        padding-left: 0;
    }
    .col-sm-pad-0, .col-sm-pad-0-right {
        padding-right: 0;
    }
    .col-sm-pad-0-top {
        padding-top: 0;
    }
    .col-sm-pad-0-bottom {
        padding-bottom: 0;
    }
    .col-sm-pad-5, .col-sm-pad-5-left {
        padding-left: 5px;
    }
    .col-sm-pad-5, .col-sm-pad-5-right {
        padding-right: 5px;
    }
    .col-sm-pad-5-top {
        padding-top: 5px;
    }
    .col-sm-pad-5-bottom {
        padding-bottom: 5px;
    }
    .col-sm-pad-10, .col-sm-pad-10-left {
        padding-left: 10px;
    }
    .col-sm-pad-10, .col-sm-pad-10-right {
        padding-right: 10px;
    }
    .col-sm-pad-10-top {
        padding-top: 10px;
    }
    .col-sm-pad-10-bottom {
        padding-bottom: 10px;
    }
    .col-sm-pad-15, .col-sm-pad-15-left {
        padding-left: 15px;
    }
    .col-sm-pad-15, .col-sm-pad-15-right {
        padding-right: 15px;
    }
    .col-sm-pad-15-top {
        padding-top: 15px;
    }
    .col-sm-pad-15-bottom {
        padding-bottom: 15px;
    }
    .col-sm-pad-20, .col-sm-pad-20-left {
        padding-left: 20px;
    }
    .col-sm-pad-20, .col-sm-pad-20-right {
        padding-right: 20px;
    }
    .col-sm-pad-20-top {
        padding-top: 20px;
    }
    .col-sm-pad-20-bottom {
        padding-bottom: 20px;
    }
    .col-sm-pad-25, .col-sm-pad-25-left {
        padding-left: 25px;
    }
    .col-sm-pad-25, .col-sm-pad-25-right {
        padding-right: 25px;
    }
    .col-sm-pad-25-top {
        padding-top: 25px;
    }
    .col-sm-pad-25-bottom {
        padding-bottom: 25px;
    }
    .col-sm-pad-30, .col-sm-pad-30-left {
        padding-left: 30px;
    }
    .col-sm-pad-30, .col-sm-pad-30-right {
        padding-right: 30px;
    }
    .col-sm-pad-30-top {
        padding-top: 30px;
    }
    .col-sm-pad-30-bottom {
        padding-bottom: 30px;
    }
    .col-sm-pad-35, .col-sm-pad-35-left {
        padding-left: 35px;
    }
    .col-sm-pad-35, .col-sm-pad-35-right {
        padding-right: 35px;
    }
    .col-sm-pad-35-top {
        padding-top: 35px;
    }
    .col-sm-pad-35-bottom {
        padding-bottom: 35px;
    }
    .col-sm-pad-40, .col-sm-pad-40-left {
        padding-left: 40px;
    }
    .col-sm-pad-40, .col-sm-pad-40-right {
        padding-right: 40px;
    }
    .col-sm-pad-40-top {
        padding-top: 40px;
    }
    .col-sm-pad-40-bottom {
        padding-bottom: 40px;
    }
    .col-sm-pad-45, .col-sm-pad-45-left {
        padding-left: 45px;
    }
    .col-sm-pad-45, .col-sm-pad-45-right {
        padding-right: 45px;
    }
    .col-sm-pad-45-top {
        padding-top: 45px;
    }
    .col-sm-pad-45-bottom {
        padding-bottom: 45px;
    }
    .col-sm-pad-50, .col-sm-pad-50-left {
        padding-left: 50px;
    }
    .col-sm-pad-50, .col-sm-pad-50-right {
        padding-right: 50px;
    }
    .col-sm-pad-50-top {
        padding-top: 50px;
    }
    .col-sm-pad-50-bottom {
        padding-bottom: 50px;
    }
    .col-sm-margin-0, .col-sm-margin-0-left {
        margin-left: 0;
    }
    .col-sm-margin-0, .col-sm-margin-0-right {
        margin-right: 0;
    }
    .col-sm-margin-0-top {
        margin-top: 0;
    }
    .col-sm-margin-0-bottom {
        margin-bottom: 0;
    }
    .col-sm-margin-5, .col-sm-margin-5-left {
        margin-left: 5px;
    }
    .col-sm-margin-5, .col-sm-margin-5-right {
        margin-right: 5px;
    }
    .col-sm-margin-5-top {
        margin-top: 5px;
    }
    .col-sm-margin-5-bottom {
        margin-bottom: 5px;
    }
    .col-sm-margin-10, .col-sm-margin-10-left {
        margin-left: 10px;
    }
    .col-sm-margin-10, .col-sm-margin-10-right {
        margin-right: 10px;
    }
    .col-sm-margin-10-top {
        margin-top: 10px;
    }
    .col-sm-margin-10-bottom {
        margin-bottom: 10px;
    }
    .col-sm-margin-15, .col-sm-margin-15-left {
        margin-left: 15px;
    }
    .col-sm-margin-15, .col-sm-margin-15-right {
        margin-right: 15px;
    }
    .col-sm-margin-15-top {
        margin-top: 15px;
    }
    .col-sm-margin-15-bottom {
        margin-bottom: 15px;
    }
    .col-sm-margin-20, .col-sm-margin-20-left {
        margin-left: 20px;
    }
    .col-sm-margin-20, .col-sm-margin-20-right {
        margin-right: 20px;
    }
    .col-sm-margin-20-top {
        margin-top: 20px;
    }
    .col-sm-margin-20-bottom {
        margin-bottom: 20px;
    }
    .col-sm-margin-25, .col-sm-margin-25-left {
        margin-left: 25px;
    }
    .col-sm-margin-25, .col-sm-margin-25-right {
        margin-right: 25px;
    }
    .col-sm-margin-25-top {
        margin-top: 25px;
    }
    .col-sm-margin-25-bottom {
        margin-bottom: 25px;
    }
    .col-sm-margin-30, .col-sm-margin-30-left {
        margin-left: 30px;
    }
    .col-sm-margin-30, .col-sm-margin-30-right {
        margin-right: 30px;
    }
    .col-sm-margin-30-top {
        margin-top: 30px;
    }
    .col-sm-margin-30-bottom {
        margin-bottom: 30px;
    }
    .col-sm-margin-35, .col-sm-margin-35-left {
        margin-left: 35px;
    }
    .col-sm-margin-35, .col-sm-margin-35-right {
        margin-right: 35px;
    }
    .col-sm-margin-35-top {
        margin-top: 35px;
    }
    .col-sm-margin-35-bottom {
        margin-bottom: 35px;
    }
    .col-sm-margin-40, .col-sm-margin-40-left {
        margin-left: 40px;
    }
    .col-sm-margin-40, .col-sm-margin-40-right {
        margin-right: 40px;
    }
    .col-sm-margin-40-top {
        margin-top: 40px;
    }
    .col-sm-margin-40-bottom {
        margin-bottom: 40px;
    }
    .col-sm-margin-45, .col-sm-margin-45-left {
        margin-left: 45px;
    }
    .col-sm-margin-45, .col-sm-margin-45-right {
        margin-right: 45px;
    }
    .col-sm-margin-45-top {
        margin-top: 45px;
    }
    .col-sm-margin-45-bottom {
        margin-bottom: 45px;
    }
    .col-sm-margin-50, .col-sm-margin-50-left {
        margin-left: 50px;
    }
    .col-sm-margin-50, .col-sm-margin-50-right {
        margin-right: 50px;
    }
    .col-sm-margin-50-top {
        margin-top: 50px;
    }
    .col-sm-margin-50-bottom {
        margin-bottom: 50px;
    }

    .rwd:before {
        content: "md";
    }
    .container {
        max-width: 990px; /*990px;*/ /*970px;*/
    }
    .col-md-1,
    .col-md-2,
    .col-md-3,
    .col-md-4,
    .col-md-5,
    .col-md-6,
    .col-md-7,
    .col-md-8,
    .col-md-9,
    .col-md-10,
    .col-md-11 {
        float: left;
    }
    .col-md-1 {
        width: 8.333333333333332%;
    }
    .col-md-2 {
        width: 16.666666666666664%;
    }
    .col-md-3 {
        width: 25%;
    }
    .col-md-4 {
        width: 33.33333333333333%;
    }
    .col-md-5 {
        width: 41.66666666666667%;
    }
    .col-md-6 {
        width: 50%;
    }
    .col-md-7 {
        width: 58.333333333333336%;
    }
    .col-md-8 {
        width: 66.66666666666666%;
    }
    .col-md-9 {
        width: 75%;
    }
    .col-md-10 {
        width: 83.33333333333334%;
    }
    .col-md-11 {
        width: 91.66666666666666%;
    }
    .col-md-12 {
        width: 100%;
    }
    .col-md-push-0 {
        left: auto;
    }
    .col-md-push-1 {
        left: 8.333333333333332%;
    }
    .col-md-push-2 {
        left: 16.666666666666664%;
    }
    .col-md-push-3 {
        left: 25%;
    }
    .col-md-push-4 {
        left: 33.33333333333333%;
    }
    .col-md-push-5 {
        left: 41.66666666666667%;
    }
    .col-md-push-6 {
        left: 50%;
    }
    .col-md-push-7 {
        left: 58.333333333333336%;
    }
    .col-md-push-8 {
        left: 66.66666666666666%;
    }
    .col-md-push-9 {
        left: 75%;
    }
    .col-md-push-10 {
        left: 83.33333333333334%;
    }
    .col-md-push-11 {
        left: 91.66666666666666%;
    }
    .col-md-pull-0 {
        right: auto;
    }
    .col-md-pull-1 {
        right: 8.333333333333332%;
    }
    .col-md-pull-2 {
        right: 16.666666666666664%;
    }
    .col-md-pull-3 {
        right: 25%;
    }
    .col-md-pull-4 {
        right: 33.33333333333333%;
    }
    .col-md-pull-5 {
        right: 41.66666666666667%;
    }
    .col-md-pull-6 {
        right: 50%;
    }
    .col-md-pull-7 {
        right: 58.333333333333336%;
    }
    .col-md-pull-8 {
        right: 66.66666666666666%;
    }
    .col-md-pull-9 {
        right: 75%;
    }
    .col-md-pull-10 {
        right: 83.33333333333334%;
    }
    .col-md-pull-11 {
        right: 91.66666666666666%;
    }
    .col-md-offset-0 {
        margin-left: 0;
    }
    .col-md-offset-1 {
        margin-left: 8.333333333333332%;
    }
    .col-md-offset-2 {
        margin-left: 16.666666666666664%;
    }
    .col-md-offset-3 {
        margin-left: 25%;
    }
    .col-md-offset-4 {
        margin-left: 33.33333333333333%;
    }
    .col-md-offset-5 {
        margin-left: 41.66666666666667%;
    }
    .col-md-offset-6 {
        margin-left: 50%;
    }
    .col-md-offset-7 {
        margin-left: 58.333333333333336%;
    }
    .col-md-offset-8 {
        margin-left: 66.66666666666666%;
    }
    .col-md-offset-9 {
        margin-left: 75%;
    }
    .col-md-offset-10 {
        margin-left: 83.33333333333334%;
    }
    .col-md-offset-11 {
        margin-left: 91.66666666666666%;
    }
    .col-md-pad-0, .col-md-pad-0-left {
        padding-left: 0;
    }
    .col-md-pad-0, .col-md-pad-0-right {
        padding-right: 0;
    }
    .col-md-pad-0-top {
        padding-top: 0;
    }
    .col-md-pad-0-bottom {
        padding-bottom: 0;
    }
    .col-md-pad-5, .col-md-pad-5-left {
        padding-left: 5px;
    }
    .col-md-pad-5, .col-md-pad-5-right {
        padding-right: 5px;
    }
    .col-md-pad-5-top {
        padding-top: 5px;
    }
    .col-md-pad-5-bottom {
        padding-bottom: 5px;
    }
    .col-md-pad-10, .col-md-pad-10-left {
        padding-left: 10px;
    }
    .col-md-pad-10, .col-md-pad-10-right {
        padding-right: 10px;
    }
    .col-md-pad-10-top {
        padding-top: 10px;
    }
    .col-md-pad-10-bottom {
        padding-bottom: 10px;
    }
    .col-md-pad-15, .col-md-pad-15-left {
        padding-left: 15px;
    }
    .col-md-pad-15, .col-md-pad-15-right {
        padding-right: 15px;
    }
    .col-md-pad-15-top {
        padding-top: 15px;
    }
    .col-md-pad-15-bottom {
        padding-bottom: 15px;
    }
    .col-md-pad-20, .col-md-pad-20-left {
        padding-left: 20px;
    }
    .col-md-pad-20, .col-md-pad-20-right {
        padding-right: 20px;
    }
    .col-md-pad-20-top {
        padding-top: 20px;
    }
    .col-md-pad-20-bottom {
        padding-bottom: 20px;
    }
    .col-md-pad-25, .col-md-pad-25-left {
        padding-left: 25px;
    }
    .col-md-pad-25, .col-md-pad-25-right {
        padding-right: 25px;
    }
    .col-md-pad-25-top {
        padding-top: 25px;
    }
    .col-md-pad-25-bottom {
        padding-bottom: 25px;
    }
    .col-md-pad-30, .col-md-pad-30-left {
        padding-left: 30px;
    }
    .col-md-pad-30, .col-md-pad-30-right {
        padding-right: 30px;
    }
    .col-md-pad-30-top {
        padding-top: 30px;
    }
    .col-md-pad-30-bottom {
        padding-bottom: 30px;
    }
    .col-md-pad-35, .col-md-pad-35-left {
        padding-left: 35px;
    }
    .col-md-pad-35, .col-md-pad-35-right {
        padding-right: 35px;
    }
    .col-md-pad-35-top {
        padding-top: 35px;
    }
    .col-md-pad-35-bottom {
        padding-bottom: 35px;
    }
    .col-md-pad-40, .col-md-pad-40-left {
        padding-left: 40px;
    }
    .col-md-pad-40, .col-md-pad-40-right {
        padding-right: 40px;
    }
    .col-md-pad-40-top {
        padding-top: 40px;
    }
    .col-md-pad-40-bottom {
        padding-bottom: 40px;
    }
    .col-md-pad-45, .col-md-pad-45-left {
        padding-left: 45px;
    }
    .col-md-pad-45, .col-md-pad-45-right {
        padding-right: 45px;
    }
    .col-md-pad-45-top {
        padding-top: 45px;
    }
    .col-md-pad-45-bottom {
        padding-bottom: 45px;
    }
    .col-md-pad-50, .col-md-pad-50-left {
        padding-left: 50px;
    }
    .col-md-pad-50, .col-md-pad-50-right {
        padding-right: 50px;
    }
    .col-md-pad-50-top {
        padding-top: 50px;
    }
    .col-md-pad-50-bottom {
        padding-bottom: 50px;
    }
    .col-md-margin-0, .col-md-margin-0-left {
        margin-left: 0;
    }
    .col-md-margin-0, .col-md-margin-0-right {
        margin-right: 0;
    }
    .col-md-margin-0-top {
        margin-top: 0;
    }
    .col-md-margin-0-bottom {
        margin-bottom: 0;
    }
    .col-md-margin-5, .col-md-margin-5-left {
        margin-left: 5px;
    }
    .col-md-margin-5, .col-md-margin-5-right {
        margin-right: 5px;
    }
    .col-md-margin-5-top {
        margin-top: 5px;
    }
    .col-md-margin-5-bottom {
        margin-bottom: 5px;
    }
    .col-md-margin-10, .col-md-margin-10-left {
        margin-left: 10px;
    }
    .col-md-margin-10, .col-md-margin-10-right {
        margin-right: 10px;
    }
    .col-md-margin-10-top {
        margin-top: 10px;
    }
    .col-md-margin-10-bottom {
        margin-bottom: 10px;
    }
    .col-md-margin-15, .col-md-margin-15-left {
        margin-left: 15px;
    }
    .col-md-margin-15, .col-md-margin-15-right {
        margin-right: 15px;
    }
    .col-md-margin-15-top {
        margin-top: 15px;
    }
    .col-md-margin-15-bottom {
        margin-bottom: 15px;
    }
    .col-md-margin-20, .col-md-margin-20-left {
        margin-left: 20px;
    }
    .col-md-margin-20, .col-md-margin-20-right {
        margin-right: 20px;
    }
    .col-md-margin-20-top {
        margin-top: 20px;
    }
    .col-md-margin-20-bottom {
        margin-bottom: 20px;
    }
    .col-md-margin-25, .col-md-margin-25-left {
        margin-left: 25px;
    }
    .col-md-margin-25, .col-md-margin-25-right {
        margin-right: 25px;
    }
    .col-md-margin-25-top {
        margin-top: 25px;
    }
    .col-md-margin-25-bottom {
        margin-bottom: 25px;
    }
    .col-md-margin-30, .col-md-margin-30-left {
        margin-left: 30px;
    }
    .col-md-margin-30, .col-md-margin-30-right {
        margin-right: 30px;
    }
    .col-md-margin-30-top {
        margin-top: 30px;
    }
    .col-md-margin-30-bottom {
        margin-bottom: 30px;
    }
    .col-md-margin-35, .col-md-margin-35-left {
        margin-left: 35px;
    }
    .col-md-margin-35, .col-md-margin-35-right {
        margin-right: 35px;
    }
    .col-md-margin-35-top {
        margin-top: 35px;
    }
    .col-md-margin-35-bottom {
        margin-bottom: 35px;
    }
    .col-md-margin-40, .col-md-margin-40-left {
        margin-left: 40px;
    }
    .col-md-margin-40, .col-md-margin-40-right {
        margin-right: 40px;
    }
    .col-md-margin-40-top {
        margin-top: 40px;
    }
    .col-md-margin-40-bottom {
        margin-bottom: 40px;
    }
    .col-md-margin-45, .col-md-margin-45-left {
        margin-left: 45px;
    }
    .col-md-margin-45, .col-md-margin-45-right {
        margin-right: 45px;
    }
    .col-md-margin-45-top {
        margin-top: 45px;
    }
    .col-md-margin-45-bottom {
        margin-bottom: 45px;
    }
    .col-md-margin-50, .col-md-margin-50-left {
        margin-left: 50px;
    }
    .col-md-margin-50, .col-md-margin-50-right {
        margin-right: 50px;
    }
    .col-md-margin-50-top {
        margin-top: 50px;
    }
    .col-md-margin-50-bottom {
        margin-bottom: 50px;
    }

    .rwd:before {
        content: "lg";
    }
    .container {
        max-width: 990px; /*990px;*/ /*1170px*/
    }
    .col-lg-1,
    .col-lg-2,
    .col-lg-3,
    .col-lg-4,
    .col-lg-5,
    .col-lg-6,
    .col-lg-7,
    .col-lg-8,
    .col-lg-9,
    .col-lg-10,
    .col-lg-11 {
        float: left;
    }
    .col-lg-1 {
        width: 8.333333333333332%;
    }
    .col-lg-2 {
        width: 16.666666666666664%;
    }
    .col-lg-3 {
        width: 25%;
    }
    .col-lg-4 {
        width: 33.33333333333333%;
    }
    .col-lg-5 {
        width: 41.66666666666667%;
    }
    .col-lg-6 {
        width: 50%;
    }
    .col-lg-7 {
        width: 58.333333333333336%;
    }
    .col-lg-8 {
        width: 66.66666666666666%;
    }
    .col-lg-9 {
        width: 75%;
    }
    .col-lg-10 {
        width: 83.33333333333334%;
    }
    .col-lg-11 {
        width: 91.66666666666666%;
    }
    .col-lg-12 {
        width: 100%;
    }
    .col-lg-push-0 {
        left: auto;
    }
    .col-lg-push-1 {
        left: 8.333333333333332%;
    }
    .col-lg-push-2 {
        left: 16.666666666666664%;
    }
    .col-lg-push-3 {
        left: 25%;
    }
    .col-lg-push-4 {
        left: 33.33333333333333%;
    }
    .col-lg-push-5 {
        left: 41.66666666666667%;
    }
    .col-lg-push-6 {
        left: 50%;
    }
    .col-lg-push-7 {
        left: 58.333333333333336%;
    }
    .col-lg-push-8 {
        left: 66.66666666666666%;
    }
    .col-lg-push-9 {
        left: 75%;
    }
    .col-lg-push-10 {
        left: 83.33333333333334%;
    }
    .col-lg-push-11 {
        left: 91.66666666666666%;
    }
    .col-lg-pull-0 {
        right: auto;
    }
    .col-lg-pull-1 {
        right: 8.333333333333332%;
    }
    .col-lg-pull-2 {
        right: 16.666666666666664%;
    }
    .col-lg-pull-3 {
        right: 25%;
    }
    .col-lg-pull-4 {
        right: 33.33333333333333%;
    }
    .col-lg-pull-5 {
        right: 41.66666666666667%;
    }
    .col-lg-pull-6 {
        right: 50%;
    }
    .col-lg-pull-7 {
        right: 58.333333333333336%;
    }
    .col-lg-pull-8 {
        right: 66.66666666666666%;
    }
    .col-lg-pull-9 {
        right: 75%;
    }
    .col-lg-pull-10 {
        right: 83.33333333333334%;
    }
    .col-lg-pull-11 {
        right: 91.66666666666666%;
    }
    .col-lg-offset-0 {
        margin-left: 0;
    }
    .col-lg-offset-1 {
        margin-left: 8.333333333333332%;
    }
    .col-lg-offset-2 {
        margin-left: 16.666666666666664%;
    }
    .col-lg-offset-3 {
        margin-left: 25%;
    }
    .col-lg-offset-4 {
        margin-left: 33.33333333333333%;
    }
    .col-lg-offset-5 {
        margin-left: 41.66666666666667%;
    }
    .col-lg-offset-6 {
        margin-left: 50%;
    }
    .col-lg-offset-7 {
        margin-left: 58.333333333333336%;
    }
    .col-lg-offset-8 {
        margin-left: 66.66666666666666%;
    }
    .col-lg-offset-9 {
        margin-left: 75%;
    }
    .col-lg-offset-10 {
        margin-left: 83.33333333333334%;
    }
    .col-lg-offset-11 {
        margin-left: 91.66666666666666%;
    }
    .col-lg-pad-0, .col-lg-pad-0-left {
        padding-left: 0;
    }
    .col-lg-pad-0, .col-lg-pad-0-right {
        padding-right: 0;
    }
    .col-lg-pad-0-top {
        padding-top: 0;
    }
    .col-lg-pad-0-bottom {
        padding-bottom: 0;
    }
    .col-lg-pad-5, .col-lg-pad-5-left {
        padding-left: 5px;
    }
    .col-lg-pad-5, .col-lg-pad-5-right {
        padding-right: 5px;
    }
    .col-lg-pad-5-top {
        padding-top: 5px;
    }
    .col-lg-pad-5-bottom {
        padding-bottom: 5px;
    }
    .col-lg-pad-10, .col-lg-pad-10-left {
        padding-left: 10px;
    }
    .col-lg-pad-10, .col-lg-pad-10-right {
        padding-right: 10px;
    }
    .col-lg-pad-10-top {
        padding-top: 10px;
    }
    .col-lg-pad-10-bottom {
        padding-bottom: 10px;
    }
    .col-lg-pad-15, .col-lg-pad-15-left {
        padding-left: 15px;
    }
    .col-lg-pad-15, .col-lg-pad-15-right {
        padding-right: 15px;
    }
    .col-lg-pad-15-top {
        padding-top: 15px;
    }
    .col-lg-pad-15-bottom {
        padding-bottom: 15px;
    }
    .col-lg-pad-20, .col-lg-pad-20-left {
        padding-left: 20px;
    }
    .col-lg-pad-20, .col-lg-pad-20-right {
        padding-right: 20px;
    }
    .col-lg-pad-20-top {
        padding-top: 20px;
    }
    .col-lg-pad-20-bottom {
        padding-bottom: 20px;
    }
    .col-lg-pad-25, .col-lg-pad-25-left {
        padding-left: 25px;
    }
    .col-lg-pad-25, .col-lg-pad-25-right {
        padding-right: 25px;
    }
    .col-lg-pad-25-top {
        padding-top: 25px;
    }
    .col-lg-pad-25-bottom {
        padding-bottom: 25px;
    }
    .col-lg-pad-30, .col-lg-pad-30-left {
        padding-left: 30px;
    }
    .col-lg-pad-30, .col-lg-pad-30-right {
        padding-right: 30px;
    }
    .col-lg-pad-30-top {
        padding-top: 30px;
    }
    .col-lg-pad-30-bottom {
        padding-bottom: 30px;
    }
    .col-lg-pad-35, .col-lg-pad-35-left {
        padding-left: 35px;
    }
    .col-lg-pad-35, .col-lg-pad-35-right {
        padding-right: 35px;
    }
    .col-lg-pad-35-top {
        padding-top: 35px;
    }
    .col-lg-pad-35-bottom {
        padding-bottom: 35px;
    }
    .col-lg-pad-40, .col-lg-pad-40-left {
        padding-left: 40px;
    }
    .col-lg-pad-40, .col-lg-pad-40-right {
        padding-right: 40px;
    }
    .col-lg-pad-40-top {
        padding-top: 40px;
    }
    .col-lg-pad-40-bottom {
        padding-bottom: 40px;
    }
    .col-lg-pad-45, .col-lg-pad-45-left {
        padding-left: 45px;
    }
    .col-lg-pad-45, .col-lg-pad-45-right {
        padding-right: 45px;
    }
    .col-lg-pad-45-top {
        padding-top: 45px;
    }
    .col-lg-pad-45-bottom {
        padding-bottom: 45px;
    }
    .col-lg-pad-50, .col-lg-pad-50-left {
        padding-left: 50px;
    }
    .col-lg-pad-50, .col-lg-pad-50-right {
        padding-right: 50px;
    }
    .col-lg-pad-50-top {
        padding-top: 50px;
    }
    .col-lg-pad-50-bottom {
        padding-bottom: 50px;
    }
    .col-lg-margin-0, .col-lg-margin-0-left {
        margin-left: 0;
    }
    .col-lg-margin-0, .col-lg-margin-0-right {
        margin-right: 0;
    }
    .col-lg-margin-0-top {
        margin-top: 0;
    }
    .col-lg-margin-0-bottom {
        margin-bottom: 0;
    }
    .col-lg-margin-5, .col-lg-margin-5-left {
        margin-left: 5px;
    }
    .col-lg-margin-5, .col-lg-margin-5-right {
        margin-right: 5px;
    }
    .col-lg-margin-5-top {
        margin-top: 5px;
    }
    .col-lg-margin-5-bottom {
        margin-bottom: 5px;
    }
    .col-lg-margin-10, .col-lg-margin-10-left {
        margin-left: 10px;
    }
    .col-lg-margin-10, .col-lg-margin-10-right {
        margin-right: 10px;
    }
    .col-lg-margin-10-top {
        margin-top: 10px;
    }
    .col-lg-margin-10-bottom {
        margin-bottom: 10px;
    }
    .col-lg-margin-15, .col-lg-margin-15-left {
        margin-left: 15px;
    }
    .col-lg-margin-15, .col-lg-margin-15-right {
        margin-right: 15px;
    }
    .col-lg-margin-15-top {
        margin-top: 15px;
    }
    .col-lg-margin-15-bottom {
        margin-bottom: 15px;
    }
    .col-lg-margin-20, .col-lg-margin-20-left {
        margin-left: 20px;
    }
    .col-lg-margin-20, .col-lg-margin-20-right {
        margin-right: 20px;
    }
    .col-lg-margin-20-top {
        margin-top: 20px;
    }
    .col-lg-margin-20-bottom {
        margin-bottom: 20px;
    }
    .col-lg-margin-25, .col-lg-margin-25-left {
        margin-left: 25px;
    }
    .col-lg-margin-25, .col-lg-margin-25-right {
        margin-right: 25px;
    }
    .col-lg-margin-25-top {
        margin-top: 25px;
    }
    .col-lg-margin-25-bottom {
        margin-bottom: 25px;
    }
    .col-lg-margin-30, .col-lg-margin-30-left {
        margin-left: 30px;
    }
    .col-lg-margin-30, .col-lg-margin-30-right {
        margin-right: 30px;
    }
    .col-lg-margin-30-top {
        margin-top: 30px;
    }
    .col-lg-margin-30-bottom {
        margin-bottom: 30px;
    }
    .col-lg-margin-35, .col-lg-margin-35-left {
        margin-left: 35px;
    }
    .col-lg-margin-35, .col-lg-margin-35-right {
        margin-right: 35px;
    }
    .col-lg-margin-35-top {
        margin-top: 35px;
    }
    .col-lg-margin-35-bottom {
        margin-bottom: 35px;
    }
    .col-lg-margin-40, .col-lg-margin-40-left {
        margin-left: 40px;
    }
    .col-lg-margin-40, .col-lg-margin-40-right {
        margin-right: 40px;
    }
    .col-lg-margin-40-top {
        margin-top: 40px;
    }
    .col-lg-margin-40-bottom {
        margin-bottom: 40px;
    }
    .col-lg-margin-45, .col-lg-margin-45-left {
        margin-left: 45px;
    }
    .col-lg-margin-45, .col-lg-margin-45-right {
        margin-right: 45px;
    }
    .col-lg-margin-45-top {
        margin-top: 45px;
    }
    .col-lg-margin-45-bottom {
        margin-bottom: 45px;
    }
    .col-lg-margin-50, .col-lg-margin-50-left {
        margin-left: 50px;
    }
    .col-lg-margin-50, .col-lg-margin-50-right {
        margin-right: 50px;
    }
    .col-lg-margin-50-top {
        margin-top: 50px;
    }
    .col-lg-margin-50-bottom {
        margin-bottom: 50px;
    }
}


/*
 * Spacing and indents
 */
.indent-5 {
    padding: 0 5px;
 }
.indent-5-all-sides {
    padding: 5px;
 }
.indent, .indent-10 {
    padding: 0 10px;
}
.indent-all-sides, .indent-10-all-sides {
    padding: 10px;
}
.remove-indent, .remove-indent-10 {
    margin-left: -10px;
    margin-right: -10px;
}
.indent-10-all-sides > .remove-indent-10:first-child {
    margin-top: -10px;
}
.indent-15 {
    padding: 0 15px;
}
.indent-15-all-sides {
    padding: 15px;
}
.remove-indent-15 {
    margin-left: -15px;
    margin-right: -15px;
}
.indent-15-all-sides > .remove-indent-15:first-child {
    margin-top: -15px;
}
.indent-20 {
    padding: 0 20px;
}
.indent-20-all-sides {
    padding: 20px;
}
.remove-indent-20 {
    margin-left: -20px;
    margin-right: -20px;
}
.indent-20-all-sides > .remove-indent-20:first-child {
    margin-top: -20px;
}
.top-spacing-0 {
    margin-top: 0px;
}
.top-spacing-5 {
    margin-top: 5px;
}
.top-spacing-10 {
    margin-top: 10px;
}
.top-spacing-15 {
    margin-top: 15px;
}
.top-spacing-20 {
    margin-top: 20px;
}
.top-spacing-25 {
    margin-top: 25px;
}
.top-spacing-30 {
    margin-top: 30px;
}
.top-spacing-35 {
    margin-top: 35px;
}
.top-spacing-40 {
    margin-top: 40px;
}
.top-spacing-45 {
    margin-top: 45px;
}
.top-spacing-50 {
    margin-top: 50px;
}
.bottom-spacing-0 {
    margin-bottom: 0px;
}
.bottom-spacing-5 {
    margin-bottom: 5px;
}
.bottom-spacing-10 {
    margin-bottom: 10px;
}
.bottom-spacing-15 {
    margin-bottom: 15px;
}
.bottom-spacing-20 {
    margin-bottom: 20px;
}
.bottom-spacing-25 {
    margin-bottom: 25px;
}
.bottom-spacing-30 {
    margin-bottom: 30px;
}
.bottom-spacing-35 {
    margin-bottom: 35px;
}
.bottom-spacing-40 {
    margin-bottom: 40px;
}
.bottom-spacing-45 {
    margin-bottom: 45px;
}
.bottom-spacing-50 {
    margin-bottom: 50px;
}
.left-spacing-0 {
    margin-left: 0px;
}
.left-spacing-5 {
    margin-left: 5px;
}
.left-spacing-10 {
    margin-left: 10px;
}
.left-spacing-15 {
    margin-left: 15px;
}
.left-spacing-20 {
    margin-left: 20px;
}
.left-spacing-25 {
    margin-left: 25px;
}
.left-spacing-30 {
    margin-left: 30px;
}
.left-spacing-35 {
    margin-left: 35px;
}
.left-spacing-40 {
    margin-left: 40px;
}
.left-spacing-45 {
    margin-left: 45px;
}
.left-spacing-50 {
    margin-left: 50px;
}
.right-spacing-0 {
    margin-right: 0px;
}
.right-spacing-5 {
    margin-right: 5px;
}
.right-spacing-10 {
    margin-right: 10px;
}
.right-spacing-15 {
    margin-right: 15px;
}
.right-spacing-20 {
    margin-right: 20px;
}
.right-spacing-25 {
    margin-right: 25px;
}
.right-spacing-30 {
    margin-right: 30px;
}
.right-spacing-35 {
    margin-right: 35px;
}
.right-spacing-40 {
    margin-right: 40px;
}
.right-spacing-45 {
    margin-right: 45px;
}
.right-spacing-50 {
    margin-right: 50px;
}
.no-spacing {
    margin: 0;
    padding: 0;
}
.no-top-spacing {
    margin-top: 0;
    padding-top: 0;
}
.no-right-spacing {
    margin-right: 0;
    padding-right: 0;
}
.no-bottom-spacing {
    margin-bottom: 0;
    padding-bottom: 0;
}
.no-left-spacing {
    margin-left: 0;
    padding-left: 0;
}
.no-padding {
    padding: 0;
}
.no-top-padding {
    padding-top: 0;
}
.no-right-padding {
    padding-right: 0;
}
.no-bottom-padding {
    padding-bottom: 0;
}
.no-left-padding {
    padding-left: 0;
}

/*
 * Misc. Abstract Classes
 */
.rounded-corners {
    border-radius: 10px;
}
.top-box-shadow {
    box-shadow: 0px -4px 9px -3px #00000033;
    background: gray;
}
.bottom-box-shadow {
    box-shadow: 0px 5px 6px #0000004D;
}

/*
 * Princess Icons Library - to replace pc-icons
 */
.svg-library-wrapper {
    height: 0;
    overflow: hidden;
}
.svg-flag, .svg-icon {
    display: inline-block;
    vertical-align: middle;
}
.svg-flag {
    height: 54px;
    width: 54px;
}
.svg-icon {
    fill: currentColor;
    height:24px;
    width: 24px;
}
.svg-fill-blue {
    fill: #0054A0;
}
.svg-fill-white {
    fill: #fff;
}
.logo-jcb-card {
    width: 34px;
    height: 34px;
}
.logo-union-pay {
    width: 34px;
    height: 34px;
}
.svg-credit-card-icon {
    width: 38px;
}

/*
 * Princess icons
 */
.pc-icons {
    background: url(/images/global/sprite_pc-icons.svg) no-repeat transparent;
    direction: ltr;
    display: inline-block;
    overflow: hidden;
    text-align: left;
    text-indent: -999em;
    vertical-align: middle;
    margin: 0 .35em;
}
.pc-icons.inline {
    display: inline-block;
}
.no-svg .pc-icons {
    background-image:url(/images/global/sprite_pc-icons.png);
}
.lt-ie8 .pc-icons {
    display: inline;
    zoom: 1;
}
.pc-icons:hover, .pc-icons:focus {
    text-decoration: none;
}
.pc-icons.deactive {
    cursor: default;
    filter: alpha(opacity=40);
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=40)";
    opacity: .4;
}
/*example 'blue-circle-qmark describes the icon. 16 is the height.*/
/* 10px icons */
.blue-downarrow-10 {
    background-position: 0px 0px;
    height:10px;
    width:20px;
}
.blue-uparrow-10 {
    background-position: -25px 0px;
    height:10px;
    width:20px;
}
.white-downchevron-10 {
    background-position: -50px 0px;
    height:10px;
    width:15px;
}
.white-upchevron-10 {
    background-position: -70px 0px;
    height:10px;
    width:15px;
}
.white-pause-10 {
    background-position: -90px 0px;
    height:10px;
    width:9px;
}
.white-play-10 {
    background-position: -104px 0px;
    height:10px;
    width:8px;
}

/* 12px icons */
.green-checkmark-12 {
    background-position: 0 -20px;
    height:12px;
    width:15px;
}
.blue-pencil-12 {
    background-position: -195px -20px;
    height:12px;
    width:15px;
}
.white-pencil-12 {
    background-position: -271px -20px;
    height:12px;
    width:12px;
}
.blue-x-12 {
    background-position: -210px -20px;
    height:12px;
    width:15px;
}
.blue-plus-12 {
    background-position: -230px -20px;
    height:12px;
    width:15px;
}
.white-print-12 {
    background-position: -247px -20px;
    height:12px;
    width:18px;
}
.red-x-12 {
    background-position: -289px -20px;
    height:12px;
    width:12px;
}
.white-x-12 {
    background-position: -370px -20px;
    height:12px;
    width:12px;
}
.gray-qmark-12 {
    background-position: -306px -20px;
    height:12px;
    width:8px;
}
.blue-uparrow-12 {
    background-position: -334px -20px;
    height:12px;
    width:12px;
}
.blue-downarrow-12 {
    background-position: -319px -20px;
    height:12px;
    width:12px;
}
.blue-goback-12 {
    background-position: -351px -20px;
    height:12px;
    width:15px;
}
.blue-envelope-12 {
    background-position: -424px -20px;
    height: 12px;
    width: 18px;
}
.white-envelope-12 {
    background-position: -447px -20px;
    height: 12px;
    width: 18px;
}
.black-ada-12 {
    background-position: -470px -20px;
    height: 12px;
    width: 11px;
}
.white-ada-12 {
    background-position: -517px -20px;
    height: 12px;
    width: 11px;
}
.white-ship-12 {
    background-position: -487px -20px;
    height: 12px;
    width: 25px;
}

/* 14px icons */
.red-circle-x-14 {
    background-position: -19px -42px;
    height: 14px;
    width: 14px;
}
.white-circle-x-14 {
    background-position: -90px -42px;
    height: 14px;
    width: 14px;
}
.blue-circle-qmark-14 {
    background-position: 0 -42px;
    height: 14px;
    width: 15px;
}
.blue-circle-look-14 {
    background-position: -38px -42px;
    height: 14px;
    width: 14px;
}
.blue-circle-plus-14 {
    background-position: -119px -42px;
    height: 14px;
    width: 14px;
}
.white-outline-qmark-14 {
    background-position: -138px -42px;
    height: 14px;
    width: 14px;
}
.blue-search-14 {
    background-position: -157px -42px;
    height: 14px;
    width: 13px;
}
.white-search-14 {
    background-position: -174px -42px;
    height: 14px;
    width: 13px;
}
.white-heart-14 {
    background-position: -191px -42px;
    height: 14px;
    width: 16px;
}
.white-heart-outline-14 {
    background-position: -212px -42px;
    height: 14px;
    width: 16px;
}
.blue-heart-14 {
    background-position: -269px -42px;
    height: 14px;
    width: 16px;
}
.blue-heart-outline-14 {
    background-position: -290px -42px;
    height: 14px;
    width: 16px;
}
.user-14 {
    background-position: -233px -42px;
    height: 14px;
    width: 13px;
}
.blue-user-14 {
    background-position: -251px -42px;
    height: 14px;
    width: 13px;
}
.blue-chat-bubbles-14 {
    background-position: -311px -42px;
    height: 14px;
    width: 22px;
}
.white-chat-bubbles-14 {
    background-position: -337px -42px;
    height: 14px;
    width: 22px;
}
.white-hangtag-14 {
    background-position: -382px -42px;
    height: 14px;
    width: 14px;
}
.blue-hangtag-14 {
    background-position: -382px -42px;
    height: 14px;
    width: 14px;
}
.blue-envelope-14 {
    background-position: -401px -42px;
    height: 14px;
    width: 21px;
}
.white-envelope-14 {
    background-position: -426px -42px;
    height: 14px;
    width: 21px;
}
.white-phone-14 {
    background-position: -452px -42px;
    height: 14px;
    width: 11px;
}
.blue-qmark-icon-14 {
    background-position:0px -42px;
    height:14px;
    width:16px;
}

/* 16px icons */
.blue-circle-qmark-16 {
    background-position: 0 -66px;
    height: 16px;
    width: 16px;
}
.blue-circle-look-16 {
    background-position: -21px -66px;
    height: 16px;
    width: 16px;
}
.blue-circle-print-16 {
    background-position: -42px -66px;
    height: 16px;
    width: 16px;
}
.white-outline-qmark-16 {
    background-position: -63px -66px;
    height: 16px;
    width: 16px;
}
.white-heart-16 {
    background-position: -84px -66px;
    height: 16px;
    width: 19px;
}
.white-heart-outline-16 {
    background-position: -105px -66px;
    height: 16px;
    width: 19px;
}
.exclamation-red-icon-16 {
    background-position:-146px -66px;
    height:16px;
    width:20px;
}
.exclamation-orange-icon-16 {
    background-position:-126px -66px;
    height:16px;
    width:20px;
}
.blue-qmark-icon-16 {
    background-position:0px -60px;
    height:16px;
    width:20px;
}
.white-horn-16 {
    background-position: -170px -66px;
    height: 15px;
    width: 24px;
}
.white-qmark-16 {
    background-position: -199px -66px;
    height: 16px;
    width: 11px;
}
.white-circle-qmark-16 {
    background-position: -262px -66px;
    height: 16px;
    width: 16px;
}
.white-book-16 {
    background-position: -215px -66px;
    height: 16px;
    width: 12px;
}
.white-print-16 {
    background-position: -232px -66px;
    height: 16px;
    width: 25px;
}
.white-circle-phone-16 {
    background-position: -409px -66px;
    height: 16px;
    width: 16px;
}
.white-facebook-icon-16 {
    background-position: -283px -66px;
    height: 16px;
    width: 9px;
}
.white-pinterest-icon-16 {
    background-position: -297px -66px;
    height: 16px;
    width: 13px;
}
.white-google-plus-icon-16 {
    background-position: -315px -65px;
    height: 18px;
    width: 18px;
}
.white-twitter-icon-16 {
    background-position: -338px -66px;
    height: 16px;
    width: 20px;
}
.white-youtube-icon-16 {
    background-position: -363px -67px;
    height: 14px;
    width: 20px;
}
.white-instagram-icon-16 {
    background-position: -388px -66px;
    height: 16px;
    width: 16px;
}
.black-left-double-quotemark-16 {
    background-position: -430px -66px;
    height: 16px;
    width: 23px;
}


/* 18px icons */
.black-phone-18 {
    background-position: 0 -92px;
    height: 18px;
    width: 15px;
}
.blue-downarrow-18 {
    background-position: -21px -92px;
    height: 18px;
    width: 60px;
}
.blue-outline-uparrow-18 {
    background-position: -89px -92px;
    height: 18px;
    width: 60px;
}

/* 20px icons */
.refresh-20 {
    background-position: 0 -120px;
    height: 20px;
    width:18px;
}
.blue-phone-20 {
    background-position: -21px -120px;
    height:20px;
    width:17px;
}
.black-phone-20 {
    background-position: -40px -120px;
    height:20px;
    width:17px;
}
.white-phone-20 {
    background-position: -59px -120px;
    height:20px;
    width:17px;
}
.envelope-20, .blue-envelope-20 {
    background-position: -80px -120px;
    height: 20px;
    width: 30px;
}
.white-envelope-20 {
    background-position: -115px -120px;
    height: 20px;
    width: 30px;
}
.white-ship-20 {
    background-position: -150px -120px;
    height: 20px;
    width: 41px;
}
/* 22px icons */
.white-calendar-22 {
    background-position: -227px -150px;
    height: 22px;
    width: 22px;
}
.users-22 {
    background-position: -255px -150px;
    height: 22px;
    width: 29px;
}
.white-chat-bubble-22 {
    background-position: -290px -150px;
    height: 22px;
    width: 26px;
}
.white-chat-bubbles-22 {
    background-position: -616px -150px;
    height: 22px;
    width: 34px;
}
.blue-chat-bubbles-22 {
    background-position: -758px -150px;
    height: 22px;
    width: 34px;
}
.black-chat-bubbles-22 {
    background-position: -815px -150px;
    height: 22px;
    width: 34px;
}
.white-ciricle-check-22 {
    background-position: -321px -150px;
    height: 22px;
    width: 22px;
}
.blue-search-22 {
    background-position: -348px -150px;
    height: 22px;
    width: 21px;
}
.white-search-22 {
    background-position: -374px -150px;
    height: 22px;
    width: 21px;
}
.white-x-22 {
    background-position: -485px -150px;
    height: 22px;
    width: 20px;
}
.white-heart-22 {
    background-position: -400px -150px;
    height: 22px;
    width: 25px;
}
.white-heart-outline-22 {
    background-position: -430px -150px;
    height: 22px;
    width: 25px;
}
.blue-heart-22 {
    background-position: -673px -150px;
    height: 22px;
    width: 25px;
}
.blue-heart-outline-22 {
    background-position: -703px -150px;
    height: 22px;
    width: 25px;
}
.user-22 {
    background-position: -460px -150px;
    height: 22px;
    width: 20px;
}
.blue-user-22 {
    background-position: -733px -150px;
    height: 22px;
    width: 20px;
}
.white-dollar-sign-22 {
    background-position: -654px -150px;
    height: 22px;
    width: 14px;
}
.blue-dollar-sign-22 {
    background-position: -796px -150px;
    height: 22px;
    width: 14px;
}
.white-hangtag-22 {
    background-position: -881px -150px;
    height: 22px;
    width: 22px;
}
.blue-hangtag-22 {
    background-position: -854px -150px;
    height: 22px;
    width: 22px;
}
.blue-phone-22 {
    background-position: -908px -150px;
    height: 22px;
    width: 17px;
}
.captains-circle-gold-22 {
    background-position: -511px -150px;
    height: 22px;
    width: 23px;
}
.captains-circle-ruby-22 {
    background-position: -536px -150px;
    height: 22px;
    width: 23px;
}
.captains-circle-platinum-22 {
    background-position: -563px -150px;
    height: 22px;
    width: 23px;
}
.captains-circle-elite-22 {
    background-position: -588px -150px;
    height: 22px;
    width: 23px;
}
/* 23px icons */
.blue-checkmark-23 {
    background-position: -199px -150px;
    height:23px;
    width:23px;
}
/* 24px icons */
.ada-24 {
    background-position: 0 -182px;
    height: 24px;
    width: 24px;
}
.visa-24 {
    background-position: -72px -182px;
    height: 24px;
    width: 38px;
}
.mc-24 {
    background-position: -115px -182px;
    height: 24px;
    width: 38px;
}
.amex-24 {
    background-position: -201px -182px;
    height: 24px;
    width: 38px;
}
.blue-ship-24 {
    background-position: -246px -182px;
    height: 24px;
    width: 50px;
}
.discover-24 {
    background-position: -29px -182px;
    height: 24px;
    width: 38px;
}
.dinners-24 {
    background-position: -158px -182px;
    height: 24px;
    width: 38px;
}
.white-leftchevron-24 {
    background-position: -318px -182px;
    height: 24px;
    width: 13px;
}
.white-rightchevron-24 {
    background-position: -300px -182px;
    height: 24px;
    width: 13px;
}
.black-leftchevron-24 {
    background-position: -354px -182px;
    height: 24px;
    width: 13px;
}
.black-rightchevron-24 {
    background-position: -336px -182px;
    height: 24px;
    width: 13px;
}
.pcl-cc-24 {
    background: transparent url(/images/global/visa-cc.jpg) no-repeat 0 0;
    background-size: 36px 22px;
    border: 1px solid #bfbfbf;
    height: 24px;
    width: 38px;
}
.warning-triangle-bang-24 {
    background-position: 0 -252px;
    height: 28px;
    width: 30px;
}

/* 28px icons */
.envelope-28 {
    background-position: -35px -252px;
    height: 28px;
    width: 51px;
}
.chat-online-28 {
    background-position: -94px -251px;
    height: 29px;
    width: 110px;
}
.chat-offline-28 {
    background-position: -211px -251px;
    height: 29px;
    width: 110px;
}
.green-envelope-28 {
    background-position: -327px -252px;
    height: 28px;
    width: 41px;
}
.gray-shorex-28 {
    background-position: -374px -252px;
    height: 28px;
    width: 54px;
}
.gray-info-28 {
    background-position: -433px -252px;
    height: 28px;
    width: 28px;
}
/* 30px icons */
.blue-circle-email-30 {
    background-position: 0 -290px;
    height: 30px;
    width: 30px;
}
.blue-circle-print-30 {
    background-position: -35px -290px;
    height: 30px;
    width: 30px;
}
/* 32px icons */
.green-circle-checkmark-32 {
    background-position: 0px -330px;
    height: 32px;
    width: 32px;
}
.gray-circle-checkmark-32 {
    background-position: -97px -330px;
    height: 32px;
    width: 32px;
}
.virtual-tour-32 {
    background-position: -40px -330px;
    height: 32px;
    width: 43px;
}
/* 36px icons */
.shorex-36 {
    background-position: 0px -372px;
    height: 36px;
    width: 69px;
}
.cruise-with-me-36 {
    background-position: -74px -372px;
    height: 36px;
    width: 163px;
}
.blue-ship-36 {
    background-position: -242px -372px;
    height: 36px;
    width: 74px;
}
/* 42px icons */
.brochure-42 {
    background-position: 0px -418px;
    height: 42px;
    width: 37px;
}
.map-42 {
    background-position: -42px -418px;
    height: 42px;
    width: 45px;
}
/* 48px icons */
.gold-laurel-left-48 {
    background-position: -92px -418px;
    height: 48px;
    width: 18px;
}
.gold-laurel-right-48 {
    background-position: -115px -418px;
    height: 48px;
    width: 18px;
}
/* Touch icons */
.touch-icon {
    background: transparent;
    border: none;
    cursor:pointer;
    display: block;
    height: 100%;
    line-height: 1;
    margin: 0;
    overflow: hidden;
    padding: 8px 5px 20px;
    position: absolute;
    text-align: center;
    top: 0;
    width: 50px;
}
.touch-icon .touch-text {
    bottom: 5px;
    color: #0054A0;
    display: block;
    font-size: 11px;
    left: 0;
    position: absolute;
    right: 0;
    text-align: center;
}
.touch-icon .touch-bar {
    background: #0054A0;
    display: block;
    height: 3px;
    pointer-events: none;
    position: relative;
    margin: 0 auto 3px;
    -webkit-transition: all 100ms linear 0s;
    -moz-transition: all 100ms linear 0s;
    -o-transition: all 100ms linear 0s;
    transition: all 100ms linear 0s;
    width: 20px;
}
.cp-icon .top-bar {
    top: 6px;
}
.cp-icon .bottom-bar {
    bottom: -7px;
}
.cp-icon .middle-bar {
    display: none;
}
.isActive .top-bar {
    top: 6px;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}
.isActive .bottom-bar {
    bottom: 0px;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
}



/*
 * Tabbed Navigation
 */
.tab-nav {
    margin: 2em auto -1px;
    /*border-bottom: 1px solid #b2b2b2;*/
    overflow: hidden;
    z-index: 10;
    position: relative;
}
.tab-content {
    position: relative;
    z-index: 1;
}
.tab-btn {
    -webkit-border-radius: 6px;
    -webkit-border-bottom-right-radius: 0;
    -webkit-border-bottom-left-radius: 0;
    -moz-border-radius: 6px;
    -moz-border-radius-bottomright: 0;
    -moz-border-radius-bottomleft: 0;
    border-radius: 6px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border-bottom-color: #fff;
    display: block;
    float: left;
    margin-bottom: -1px;
    padding: 8px 12px;
    -moz-background-clip: padding;     /* Firefox 3.6 */
    -webkit-background-clip: padding;  /* Safari 4? Chrome 6? */
    background-clip: padding-box;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}
/*
.lt-ie8 .tab-btn {
    margin-bottom: -4px;
}
*/
.tab-nav > .tab-btn + .tab-btn {
    margin-left: .25em;
}
.tab-btn.selected, .tab-btn.selected:hover, .tab-btn.selected:focus {
    background: #fff;
    border: solid 1px #b2b2b2;
    border-bottom-color: #fff;
    color: #000;
    cursor: default;
}
.tab-content {
    border: 1px solid #b2b2b2;
    padding: 20px;
}
.js .tab-panel {
    display: none;
}
/*
.tab-panel.selected {
    display: block;
}
*/

/*
 * Accordion
 */
.accordion-wrapper, .rwd-accordion-wrapper {
    margin: 1em 0;
}
.accordion-simple.accordion-wrapper, .accordion-simple.rwd-accordion-wrapper {
    border: 1px solid #d0d1d2;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    overflow:hidden;
}
.accordion, .rwd-accordion {
    background: #e6e6e6;
    border-top: 1px solid #b2b2b2;
    margin-top: .75em;
    padding: 7px 0;
    position: relative;
}
.accordion-simple .accordion, .accordion-simple .rwd-accordion {
    border-top:none;
    margin:0;
    padding:0;
}
.accordion + .accordion, .rwd-accordion + .rwd-accordion {
    border-top: none;
    margin-top: 0;
    padding-top: 0;
}
.accordion-simple .accordion + .accordion, .accordion-simple .rwd-accordion + .rwd-accordion {
    border-top: 1px solid #d0d1d2;
}
.accordion-header, .rwd-accordion-header  {
    background: #fff;
    border-bottom: 1px solid #b2b2b2;
    overflow: hidden;
}
.accordion-simple .accordion-header, .accordion-simple .rwd-accordion-header {
    border-bottom:0;
}
.selected > .accordion-header, .accordion-header:hover, .accordion-header:focus, .selected > .rwd-accordion-header, .rwd-accordion-header:hover, .rwd-accordion-header:focus {
    background: url(/images/book/sprite_reserve.png) 0 -500px repeat-x #e5f3fd;
    background: #e5f3fd;
    background: -moz-linear-gradient(top, #d3e8f5 0%, #e5f3fd 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#d3e8f5), color-stop(100%,#e5f3fd));
    background: -webkit-linear-gradient(top, #d3e8f5 0%,#e5f3fd 100%);
    background: -o-linear-gradient(top, #d3e8f5 0%,#e5f3fd 100%);
    background: -ms-linear-gradient(top, #d3e8f5 0%,#e5f3fd 100%);
    background: linear-gradient(to bottom, #d3e8f5 0%,#e5f3fd 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d3e8f5', endColorstr='#e5f3fd',GradientType=0 );
}
.accordion-header:hover, .rwd-accordion-header:hover,
.accordion-header:focus, .rwd-accordion-header:focus {
    cursor: pointer;
}
.deactive > .accordion-header:hover, .deactive > .rwd-accordion-header:hover,
.deactive > .accordion-header:focus, .deactive > .rwd-accordion-header:focus {
    background: #fff;
    cursor: default;
}
.selected > .accordion-header, .selected > .rwd-accordion-header {
    border-bottom: none;
}
.accordion-content, .rwd-accordion-content {
    background: #e5f3fd;
    border-bottom: 1px solid #b3b3b3;
}
.accordion-simple .accordion-content, .accordion-simple .rwd-accordion-content {
    background: #dbdbdb;
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2RiZGJkYiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwJSIgc3RvcC1jb2xvcj0iI2Y0ZjRmNCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgPC9saW5lYXJHcmFkaWVudD4KICA8cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2dyYWQtdWNnZy1nZW5lcmF0ZWQpIiAvPgo8L3N2Zz4=);
    background: -moz-linear-gradient(top,  #dbdbdb 0%, #f4f4f4 10%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#dbdbdb), color-stop(10%,#f4f4f4));
    background: -webkit-linear-gradient(top,  #dbdbdb 0%,#f4f4f4 10%);
    background: -o-linear-gradient(top,  #dbdbdb 0%,#f4f4f4 10%);
    background: -ms-linear-gradient(top,  #dbdbdb 0%,#f4f4f4 10%);
    background: linear-gradient(to bottom,  #dbdbdb 0%,#f4f4f4 10%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#dbdbdb', endColorstr='#f4f4f4',GradientType=0 );
    border-bottom: none;
    border-top: #d0d1d2 solid 1px;
}
.accordion-content {
    overflow: hidden;
    padding: 15px;
}
.accordion-content > *, .rwd-accordion-content > * {
    margin-top: 0;
}
.js .accordion-content, .js .rwd-accordion-content {
    display: none;
}
/*
.selected > .accordion-content, .selected > .accordion-results {
    display: block;
}
*/
.accordion-header-select, .rwd-accordion-header-select {
    text-align: center;
    /*padding-top: 10px;
    padding-bottom: 10px;*/
}
.accordion-header-select {
    float: right;
    width: 65px;
    /*padding-left: 10px;
    padding-right: 15px;*/
    padding: 10px 15px 10px 10px;
}
.rwd-accordion-header-select {
    padding-top: 15px;
    padding-bottom: 15px;
}
.accordion-header-select > .button, .rwd-accordion-header-select > .button {
    padding: 10px;
    display: inline-block;
}
.lt-ie8 .accordion-header-select > .button, .lt-ie8 .rwd-accordion-header-select > .button {
    display: inline;
    zoom: 1;
}
.accordion-loading, .rwd-accordion-loading {
    display: none;
    line-height: 38px;
    margin: .25em 0;
    padding: 10px;
}
.accordion-loading > img, .rwd-accordion-loading > img {
    vertical-align: middle;
}
.accordion-header-desc, .rwd-accordion-header-desc {
    padding-top: 10px;
    padding-bottom: 15px;
}
.accordion-simple .accordion-header-desc, .accordion-simple .rwd-accordion-header-desc {
    padding:8px 10px;
}
.accordion-header-desc {
    padding-left: 15px;
    padding-right: 10px;
    float: left;
    width: 765px;
}
.accordion-header-desc > p, .accordion-header-desc > ul, .rwd-accordion-header-desc > p, .rwd-accordion-header-desc > ul {
    margin-bottom: 0;
}
.rwd-accordion-close-btn {
    display: none;
    font-weight: 700;
    margin: 0;
    padding: 5px 10px;
    text-align: center;
    width: 100%;
    position: absolute;
    bottom: 10px;
}
.rwd-accordion-close-btn:after {
    border: 8px solid transparent;
    border-bottom-color: #0054A0;
    content: ' ';
    display: inline-block;
    font-size: 0;
    height: 0;
    line-height: 0;
    margin: -4px 0 0 6px;
    position: relative;
    width: 0;
}
.accordion-simple h3 {
    margin:0;
    text-transform: none;
}
.accordion-simple h3:before {
    content:'';
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid #0054A0;
    display:inline-block;
    height: 0;
    position:relative;
    margin-right:10px;
    width: 0;
}
.accordion-simple .selected h3:before {
    content:'';
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #0054A0;
    border-top:0;
    height: 0;
    position:relative;
    margin-right:10px;
    width: 0;
}
@media (max-width: 639px) {
    .rwd-accordion-content {
        padding-bottom: 36px;
    }
    .accordion-simple .rwd-accordion-content {
        padding-bottom: 15px;
    }
    .rwd-accordion.selected .rwd-accordion-close-btn {
        display: inline-block;
    }
    .rwd-accordion-header-select {
        padding-top: 0;
    }
    .rwd-accordion-header-select > .button {
        display: inline-block;
        padding: 5px 10px;
        width: 100%;
    }
    .rwd-accordion-header-select > .button:after {
        border: 8px solid transparent;
        border-top-color: #fff;
        content: ' ';
        display: inline-block;
        font-size: 0;
        height: 0;
        line-height: 0;
        margin: 0 0 -8px 6px;
        position: relative;
        width: 0;
    }
    .rwd-accordion-header-select > .selected:after {
        border-top-color: transparent;
        border-bottom-color: #0054A0;
        margin: -4px 0 0 6px;
        position: relative;
    }
}

/*
 * Accordion : FAQ Modifier (.faq-default)
 */
.rwd-accordion-wrapper.faq-default {
    margin: 0;
    border-left: 0;
    border-right: 0;
    border-radius: 0;
}
.rwd-accordion-wrapper.faq-default .rwd-accordion-header > h2> button,
.rwd-accordion-wrapper.faq-default .rwd-accordion-header-desc > h2,
.rwd-accordion-wrapper.faq-default .rwd-accordion-header-desc > h3,
.rwd-accordion-wrapper.faq-default .rwd-accordion-header-desc > div {
    color: #0054A0;
    padding-right: 20px;
    font-family:  var(--red-hat-text);
    font-weight: 400;
    letter-spacing: 2px;
}
.rwd-accordion-wrapper.faq-default .rwd-accordion-header > h2 {
    padding: 1px;
}
.rwd-accordion-wrapper.faq-default .rwd-accordion {
    background: none;
    margin: 0;
    padding: 0;
}
.rwd-accordion-wrapper.faq-default .rwd-accordion-header-select {
    padding: 0;
}
.rwd-accordion-wrapper.faq-default .selected > .accordion-header, .rwd-accordion-wrapper.faq-default .accordion-header:hover, .rwd-accordion-wrapper.faq-default .accordion-header:focus, .rwd-accordion-wrapper.faq-default .selected > .rwd-accordion-header, .rwd-accordion-wrapper.faq-default .rwd-accordion-header:hover, .rwd-accordion-wrapper.faq-default .rwd-accordion-header:focus {
    background: none;
    filter: none;
}
.rwd-accordion-wrapper.faq-default .accordion-content, .rwd-accordion-wrapper.faq-default .rwd-accordion-content {
    background: #f1f1f1;
    padding-bottom: 5px;
}
.rwd-accordion-wrapper.faq-default .rwd-accordion-header-desc h3:before {
    display: none;
}
.rwd-accordion-wrapper.faq-default .rwd-accordion-header-desc {
    padding: 9px 32px 9px 16px;
}
.rwd-accordion-wrapper.faq-default .rwd-accordion-header-desc:after {
    content: '';
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid #0054A0;
    display: inline-block;
    height: 0;
    position: absolute;
    width: 0;
    top: 42%;
    right: 1.25em;
}
.rwd-accordion-wrapper.faq-default .selected .rwd-accordion-header-desc:after {
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #0054A0;
    border-top: 0;
}
.rwd-accordion-wrapper.faq-default .accordion-simple .rwd-accordion + .rwd-accordion {
    border-color: #b2b2b2;
}
.rwd-accordion-wrapper.faq-default button.rwd-accordion-header-desc {
    text-align: left;
    margin: 0;
}
.rwd-accordion-wrapper.faq-default button.rwd-accordion-header-desc:hover,
.rwd-accordion-wrapper.faq-default button.rwd-accordion-header-desc:focus {
    text-decoration: underline;
}
.rwd-accordion-wrapper.faq-default button.rwd-accordion-header-desc:focus {
    outline-offset: -2px;
    box-shadow: 0 0 0 2px inset rgb(0 0 0 / 80%);
}


/*
 * Accordion : FAQ Modifier V2 (.faq-default-v2)
 */
.faq-default-v2 button.trigger {
    cursor: pointer;
    display: block;
    padding: 9px 40px 9px 16px;
    margin: 0;
    text-align: left;
    text-decoration: none;
    font-family:  var(--red-hat-text);
    font-size: 1.142857142857143em;
    font-weight: 400;
    letter-spacing: 2px;
    background-color: #fff;
    border-top: 1px;
    border-bottom: 1px solid #d0d1d2;
    background-repeat: no-repeat;
    background-position: 5px 7px;
    color: #1E4E8D;
}
.faq-default-v2 button.trigger:after {
    content: '';
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid #0054A0;
    display: inline-block;
    height: 0;
    position: absolute;
    width: 0;
    top: 42%;
    right: 1.25em;
}
.faq-default-v2 button.trigger.active:after {
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #0054A0;
    border-top: 0;
}
.faq-default-v2 button.trigger:hover,
.faq-default-v2 button.trigger:focus {
    text-decoration: underline;
}


/* ==|== non-semantic helper classes ========================================
   Please define your styles before this section.
   ========================================================================== */
/* For no operation */
.no-op, .noop, .mutex {
    /* this helper class is used to prevent conflict actions from excuting in js. switch cursor to default*/
    cursor: default !important;
}
/* For image replacement */
.ir { display: block; border: 0; text-indent: -999em; overflow: hidden; background-color: transparent; background-repeat: no-repeat; text-align: left; direction: ltr; }
.ir br { display: none; }

/* Simple Hide */
.hide { display: none; }

/* Hide from both screenreaders and browsers: h5bp.com/u */
.hidden { display: none !important; visibility: hidden; }

/* Hide only visually, but have it available for screenreaders: h5bp.com/v */
.visuallyhidden { border: 0; clip: rect(0 0 0 0); height: 1px; margin: -1px; overflow: hidden; padding: 0; position: absolute; width: 1px; }

/* Extends the .visuallyhidden class to allow the element to be focusable when navigated to via the keyboard: h5bp.com/p */
.visuallyhidden.focusable:active, .visuallyhidden.focusable:focus,
.sm-visuallyhidden.focusable:active, .sm-visuallyhidden.focusable:focus,
.md-visuallyhidden.focusable:active, .md-visuallyhidden.focusable:focus,
.lg-visuallyhidden.focusable:active, .lg-visuallyhidden.focusable:focus,
.visuallyhidden-xs-down.focusable:active, .visuallyhidden-xs-down.focusable:focus,
.visuallyhidden-sm-up.focusable:active, .visuallyhidden-sm-up.focusable:focus,
.visuallyhidden-sm-down.focusable:active, .visuallyhidden-sm-down.focusable:focus,
.visuallyhidden-md-up.focusable:active, .visuallyhidden-md-up.focusable:focus,
.visuallyhidden-md-down.focusable:active, .visuallyhidden-md-down.focusable:focus,
.visuallyhidden-lg-up.focusable:active, .visuallyhidden-lg-up.focusable:focus
{ clip: auto; height: auto; margin: 0; overflow: visible; position: static; width: auto; }

/* Hide visually and from screenreaders, but maintain layout */
.invisible { visibility: hidden; }
.isvisible { visibility: visible; }

/* Contain floats: h5bp.com/q */
.clearfix:before, .clearfix:after, .row:before, .row:after { content: ""; display: table; }
.clearfix:after, .row:after { clear: both; }
.clearfix, .row { zoom: 1; }

/* Simiple clear to be used on sibling elements */
.simipleclear, .simpleclear {
    clear:both;
}
.clear { clear: both; display: block; visibility: hidden; width: 0; height: 0; overflow: hidden; zoom: 1; }


/* ==|== print styles =======================================================
   Print styles.
   Inlined to avoid required HTTP connection: h5bp.com/r
   ========================================================================== */

@media print {
  * { background: transparent !important; /*color: black !important;*/ text-shadow: none !important; filter:none !important; -ms-filter: none !important; } /* Black prints faster: h5bp.com/s */
  a, a:visited { text-decoration: underline; }
  #container { box-shadow: unset; }
  #content {max-width: 100% !important; width: auto !important;}
  pre, blockquote { border: 1px solid #999; page-break-inside: avoid; }
  thead { display: table-header-group; } /* h5bp.com/t */
  tr, img { page-break-inside: avoid; }
  img { max-width: 100% !important; }
  @page { margin: 1.25cm; }
  p, h2, h3 { orphans: 3; widows: 3; }
  h2, h3 { page-break-after: avoid; }
  .print-doc .col-xs-12 {margin-left: 0 !important; margin-right: 0 !important; padding-left: 0 !important; padding-right: 0 !important;}
  .no-print {display: none !important;}
}

/* Destination background images - there are two versions of background images. Full-bg is used in fnc and polar bear, where header-bg is used in cp and other parts of the site, */
#container.full-bg, #container.header-bg {
    background-image: none;
    background-attachment:fixed;
    background-repeat: no-repeat;
}
#container.full-bg {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}
#container.header-bg {
    background-position: 50% 0;
}
#container.full-bg.a {
    background-image: url("/images/global/destinations/background/a_bg.jpg");
}
#container.header-bg.a {
    background-image: url("/images/global/destinations/background_header/a_bg.jpg");
}
#container.full-bg.o {
    background-image: url("/images/global/destinations/background/o_bg.jpg");
}
#container.header-bg.o {
    background-image: url("/images/global/destinations/background_header/o_bg.jpg");
}
#container.full-bg.z {
    background-image: url("/images/global/destinations/background/z_bg.jpg");
}
#container.header-bg.z {
    background-image: url("/images/global/destinations/background_header/z_bg.jpg");
}
#container.full-bg.l {
    background-image: url("/images/global/destinations/background/l_bg.jpg");
}
#container.header-bg.l {
    background-image: url("/images/global/destinations/background_header/l_bg.jpg");
}
#container.full-bg.n {
    background-image: url("/images/global/destinations/background/n_bg.jpg");
}
#container.header-bg.n {
    background-image: url("/images/global/destinations/background_header/n_bg.jpg");
}
#container.full-bg.c {
    background-image: url("/images/global/destinations/background/c_bg.jpg");
}
#container.header-bg.c {
    background-image: url("/images/global/destinations/background_header/c_bg.jpg");
}
#container.full-bg.e {
    background-image: url("/images/global/destinations/background/e_bg.jpg");
}
#container.header-bg.e {
    background-image: url("/images/global/destinations/background_header/e_bg.jpg");
}
#container.full-bg.r {
    background-image: url("/images/global/destinations/background/r_bg.jpg");
}
#container.header-bg.r {
    background-image: url("/images/global/destinations/background_header/r_bg.jpg");
}
#container.full-bg.v {
    background-image: url("/images/global/destinations/background/v_bg.jpg");
}
#container.header-bg.v {
    background-image: url("/images/global/destinations/background_header/v_bg.jpg");
}
#container.full-bg.h {
    background-image: url("/images/global/destinations/background/h_bg.jpg");
}
#container.header-bg.h {
    background-image: url("/images/global/destinations/background_header/h_bg.jpg");
}
#container.full-bg.k {
    background-image: url("/images/global/destinations/background/k_bg.jpg");
}
#container.header-bg.k {
    background-image: url("/images/global/destinations/background_header/k_bg.jpg");
}
#container.full-bg.m {
    background-image: url("/images/global/destinations/background/m_bg.jpg");
}
#container.header-bg.m {
    background-image: url("/images/global/destinations/background_header/m_bg.jpg");
}
#container.full-bg.t {
    background-image: url("/images/global/destinations/background/t_bg.jpg");
}
#container.header-bg.t {
    background-image: url("/images/global/destinations/background_header/t_bg.jpg");
}
#container.full-bg.j {
    background-image: url("/images/global/destinations/background/j_bg.jpg");
}
#container.header-bg.j {
    background-image: url("/images/global/destinations/background_header/j_bg.jpg");
}
#container.full-bg.s {
    background-image: url("/images/global/destinations/background/s_bg.jpg");
}
#container.header-bg.s {
    background-image: url("/images/global/destinations/background_header/s_bg.jpg");
}
#container.full-bg.i {
    background-image: url("/images/global/destinations/background/i_bg.jpg");
}
#container.header-bg.i {
    background-image: url("/images/global/destinations/background_header/i_bg.jpg");
}
#container.full-bg.f {
    background-image: url("/images/global/destinations/background/f_bg.jpg");
}
#container.header-bg.f {
    background-image: url("/images/global/destinations/background_header/f_bg.jpg");
}
#container.full-bg.w {
    background-image: url("/images/global/destinations/background/w_bg.jpg");
}
#container.header-bg.w {
    background-image: url("/images/global/destinations/background_header/w_bg.jpg");
}
#container.full-bg.a-tour {
    background-image: url("/images/global/destinations/background/a_tour_bg.jpg");
}
#container.header-bg.a-tour {
    background-image: url("/images/global/destinations/background_header/a_tour_bg.jpg");
}
#container.full-bg.o-tour {
    background-image: url("/images/global/destinations/background/o_tour_bg.jpg");
}
#container.header-bg.o-tour {
    background-image: url("/images/global/destinations/background_header/o_tour_bg.jpg");
}
#container.full-bg.z-tour {
    background-image: url("/images/global/destinations/background/z_tour_bg.jpg");
}
#container.header-bg.z-tour {
    background-image: url("/images/global/destinations/background_header/z_tour_bg.jpg");
}
#container.full-bg.l-tour {
    background-image: url("/images/global/destinations/background/l_tour_bg.jpg");
}
#container.header-bg.l-tour {
    background-image: url("/images/global/destinations/background_header/l_tour_bg.jpg");
}
#container.full-bg.n-tour {
    background-image: url("/images/global/destinations/background/n_tour_bg.jpg");
}
#container.header-bg.n-tour {
    background-image: url("/images/global/destinations/background_header/n_tour_bg.jpg");
}
#container.full-bg.c-tour {
    background-image: url("/images/global/destinations/background/c_tour_bg.jpg");
}
#container.header-bg.c-tour {
    background-image: url("/images/global/destinations/background_header/c_tour_bg.jpg");
}
#container.full-bg.e-tour {
    background-image: url("/images/global/destinations/background/e_tour_bg.jpg");
}
#container.header-bg.e-tour {
    background-image: url("/images/global/destinations/background_header/e_tour_bg.jpg");
}
#container.full-bg.r-tour {
    background-image: url("/images/global/destinations/background/r_tour_bg.jpg");
}
#container.header-bg.r-tour {
    background-image: url("/images/global/destinations/background_header/r_tour_bg.jpg");
}
#container.full-bg.v-tour {
    background-image: url("/images/global/destinations/background/v_tour_bg.jpg");
}
#container.header-bg.v-tour {
    background-image: url("/images/global/destinations/background_header/v_tour_bg.jpg");
}
#container.full-bg.h-tour {
    background-image: url("/images/global/destinations/background/h_tour_bg.jpg");
}
#container.header-bg.h-tour {
    background-image: url("/images/global/destinations/background_header/h_tour_bg.jpg");
}
#container.full-bg.k-tour {
    background-image: url("/images/global/destinations/background/k_tour_bg.jpg");
}
#container.header-bg.k-tour {
    background-image: url("/images/global/destinations/background_header/k_tour_bg.jpg");
}
#container.full-bg.m-tour {
    background-image: url("/images/global/destinations/background/m_tour_bg.jpg");
}
#container.header-bg.m-tour {
    background-image: url("/images/global/destinations/background_header/m_tour_bg.jpg");
}
#container.full-bg.t-tour {
    background-image: url("/images/global/destinations/background/t_tour_bg.jpg");
}
#container.header-bg.t-tour {
    background-image: url("/images/global/destinations/background_header/t_tour_bg.jpg");
}
#container.full-bg.j-tour {
    background-image: url("/images/global/destinations/background/j_tour_bg.jpg");
}
#container.header-bg.j-tour {
    background-image: url("/images/global/destinations/background_header/j_tour_bg.jpg");
}
#container.full-bg.s-tour {
    background-image: url("/images/global/destinations/background/s_tour_bg.jpg");
}
#container.header-bg.s-tour {
    background-image: url("/images/global/destinations/background_header/s_tour_bg.jpg");
}
#container.full-bg.i-tour {
    background-image: url("/images/global/destinations/background/i_tour_bg.jpg");
}
#container.header-bg.i-tour {
    background-image: url("/images/global/destinations/background_header/i_tour_bg.jpg");
}
#container.full-bg.f-tour {
    background-image: url("/images/global/destinations/background/f_tour_bg.jpg");
}
#container.header-bg.f-tour {
    background-image: url("/images/global/destinations/background_header/f_tour_bg.jpg");
}
#container.full-bg.w-tour {
    background-image: url("/images/global/destinations/background/w_bg.jpg");
}
#container.header-bg.w-tour {
    background-image: url("/images/global/destinations/background_header/w_bg.jpg");
}

@media (max-width: 767px) {
    #container.full-bg, #container.header-bg {
        background-image: none;
    }
    #container.mobile-header-bg {
        -webkit-background-size: 100%;
        -moz-background-size: 100%;
        -o-background-size: 100%;
        background-size: 100%;
        background-attachment:fixed;
        background-position: 50% 0;
        background-repeat:no-repeat;
    }
    #container.mobile-header-bg.a{
        background-image: url("/images/global/destinations/background_header/a_m_bg.jpg");
    }
    #container.mobile-header-bg.o{
        background-image: url("/images/global/destinations/background_header/o_m_bg.jpg");
    }
    #container.mobile-header-bg.z{
        background-image: url("/images/global/destinations/background_header/z_m_bg.jpg");
    }
    #container.mobile-header-bg.l{
        background-image: url("/images/global/destinations/background_header/l_m_bg.jpg");
    }
    #container.mobile-header-bg.n{
        background-image: url("/images/global/destinations/background_header/n_m_bg.jpg");
    }
    #container.mobile-header-bg.c{
        background-image: url("/images/global/destinations/background_header/c_m_bg.jpg");
    }
    #container.mobile-header-bg.e{
        background-image: url("/images/global/destinations/background_header/e_m_bg.jpg");
    }
    #container.mobile-header-bg.r{
        background-image: url("/images/global/destinations/background_header/r_m_bg.jpg");
    }
    #container.mobile-header-bg.v{
        background-image: url("/images/global/destinations/background_header/v_m_bg.jpg");
    }
    #container.mobile-header-bg.h{
        background-image: url("/images/global/destinations/background_header/h_m_bg.jpg");
    }
    #container.mobile-header-bg.k{
        background-image: url("/images/global/destinations/background_header/k_m_bg.jpg");
    }
    #container.mobile-header-bg.m{
        background-image: url("/images/global/destinations/background_header/m_m_bg.jpg");
    }
    #container.mobile-header-bg.t{
        background-image: url("/images/global/destinations/background_header/t_m_bg.jpg");
    }
    #container.mobile-header-bg.j{
        background-image: url("/images/global/destinations/background_header/j_m_bg.jpg");
    }
    #container.mobile-header-bg.s{
        background-image: url("/images/global/destinations/background_header/s_m_bg.jpg");
    }
    #container.mobile-header-bg.i{
        background-image: url("/images/global/destinations/background_header/i_m_bg.jpg");
    }
    #container.mobile-header-bg.f{
        background-image: url("/images/global/destinations/background_header/f_m_bg.jpg");
    }
    #container.mobile-header-bg.w{
        background-image: url("/images/global/destinations/background_header/w_m_bg.jpg");
    }
    #container.mobile-header-bg.a-tour{
        background-image: url("/images/global/destinations/background_header/a_tour_bg.jpg");
    }
    #container.mobile-header-bg.o-tour{
        background-image: url("/images/global/destinations/background_header/o_tour_bg.jpg");
    }
    #container.mobile-header-bg.z-tour{
        background-image: url("/images/global/destinations/background_header/z_tour_bg.jpg");
    }
    #container.mobile-header-bg.l-tour{
        background-image: url("/images/global/destinations/background_header/l_tour_bg.jpg");
    }
    #container.mobile-header-bg.n-tour{
        background-image: url("/images/global/destinations/background_header/n_tour_bg.jpg");
    }
    #container.mobile-header-bg.c-tour{
        background-image: url("/images/global/destinations/background_header/c_tour_bg.jpg");
    }
    #container.mobile-header-bg.e-tour{
        background-image: url("/images/global/destinations/background_header/e_tour_bg.jpg");
    }
    #container.mobile-header-bg.r-tour{
        background-image: url("/images/global/destinations/background_header/r_tour_bg.jpg");
    }
    #container.mobile-header-bg.v-tour{
        background-image: url("/images/global/destinations/background_header/v_tour_bg.jpg");
    }
    #container.mobile-header-bg.h-tour{
        background-image: url("/images/global/destinations/background_header/h_tour_bg.jpg");
    }
    #container.mobile-header-bg.k-tour{
        background-image: url("/images/global/destinations/background_header/k_tour_bg.jpg");
    }
    #container.mobile-header-bg.m-tour{
        background-image: url("/images/global/destinations/background_header/m_tour_bg.jpg");
    }
    #container.mobile-header-bg.t-tour{
        background-image: url("/images/global/destinations/background_header/t_tour_bg.jpg");
    }
    #container.mobile-header-bg.j-tour{
        background-image: url("/images/global/destinations/background_header/j_tour_bg.jpg");
    }
    #container.mobile-header-bg.s-tour{
        background-image: url("/images/global/destinations/background_header/s_tour_bg.jpg");
    }
    #container.mobile-header-bg.i-tour{
        background-image: url("/images/global/destinations/background_header/i_tour_bg.jpg");
    }
    #container.mobile-header-bg.f-tour{
        background-image: url("/images/global/destinations/background_header/f_tour_bg.jpg");
    }
    #container.mobile-header-bg.w-tour{
        background-image: url("/images/global/destinations/background_header/w_bg.jpg");
    }
    .article-header.header-border-radius {
        -webkit-border-radius: 0;
        border-radius: 0;
        border-top: 2px solid #fff;
        border-bottom: 2px solid #fff;
    }
}

/*
 * Audio Description
 */
.audio-desc-icon {
    padding: 5px 5px 5px 0;
}
.audio-desc-icon svg {
    color: #000;
    height: 30px;
    width: 30px;
}
.audio-desc-toggle, .audio-desc-icon, .radio-toggle, .radio-toggle * {
    display: inline-block;
    vertical-align: middle;
}
.audio-desc-toggle .radio-toggle * {
    margin-left: 5px;
}
.audio-desc-bar {
    background: #fff;
    border: 1px solid #ccc;
    border-top: 0;
    text-align: left;
    padding-left: 15px;
    display: none;
}

/*
 * Mobile Headline
 */
@media (max-width: 39.9375em) {
    .headline-gray-e6-bg-mobile,
    .full-width .headline-bar > .headline-gray-e6-bg-mobile:before {
        background: #e6e6e6;
    }
    .headline-white-fff-bg-mobile,
    .full-width .headline-bar > .headline-white-fff-bg-mobile:before {
        background: #fff;
    }
}
