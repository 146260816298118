/*
 * Global Variables - being defined at :root allows for the colors to be utilized across the docroot.
 */
:root {

    /*
        NOTE: For all font variables and classes, the entire font-family of GOTHAM is
        no longer licensed, and is removed. The only remnants of gotham in all of p.com
        should be our class and variable names, which will refer to Red Hat and it's variants
        during the transition.
     */
                /* Legacy Font Variables TODO: Remove these. */
    --gotham-xn-book: var(--roboto-condensed);
    --gotham-xn-medium: var(--roboto-condensed);
    --gotham-book: var(--red-hat-text);
    --gotham-book-it: var(--red-hat-text-it);
    --gotham-bold: var(--red-hat-text-bold);
    --gotham-bold-it: var(--red-hat-text-bold-it);
    
    /* COLORS */
    --princess-blue: #003595;
    --sand-light-500: #f9f7f6;
    --sand-light-700: #f6f4f2;
    --steel-grey: #6c6c78;
    --steel-grey-light-200: #e2e2e4;
    --steel-grey-light-300: #d3d3d7;
    --steel-grey-light-900: #7b7b86;

                /* Modernized Font Variables */
    /* Red Hat - Body texts, subtitles, buttons, etc.*/
    /* Source Sans 3 is used as a backup to support certain superscript tags that are unsupported by our primary fonts.*/
    --proxima-nova: 'Proxima Nova', 'Source Sans 3 Regular', Arial, Helvetica, sans-serif; 
    --proxima-nova-bold: 'Proxima Nova Bold', 'Source Sans 3 Regular', Arial, Helvetica, sans-serif;

    /* Adobe fonts - Primary font family stack for proxima-nova */
    --proxima-nova-adobe: 'proxima-nova', 'Source Sans 3 Regular', Arial, Helvetica, sans-serif;

    /* Used for large fonts, mostly headers. */
    --domaine-display-regular: 'Domaine Display Regular', 'Source Sans 3 Regular', Arial, Helvetica, sans-serif;
    --domaine-display-bold: 'Domaine Display Bold', 'Source Sans 3 Regular', Arial, Helvetica, sans-serif;
    --red-hat-text: 'Red Hat Text Regular', 'Source Sans 3 Regular', Arial, Helvetica, sans-serif;
    --red-hat-text-it: 'Red Hat Text Italic', 'Source Sans 3 Regular', Arial, Helvetica, sans-serif;
    --red-hat-text-bold: 'Red Hat Text Bold', 'Source Sans 3 Regular', Arial, Helvetica, sans-serif;
    --red-hat-text-bold-it: 'Red Hat Text Bold Italic', 'Source Sans 3 Regular', Arial, Helvetica, sans-serif;
    --red-hat-text-medium: 'Red Hat Text Medium', 'Source Sans 3 Regular', Arial, Helvetica, sans-serif;
    --red-hat-text-medium-it: 'Red Hat Text Medium Italic', 'Source Sans 3 Regular', Arial, Helvetica, sans-serif;
    /* Roboto Condensed - Elements with tight spacing. 'Trademark' is also used here because Roboto Condensed has support for the
       Trademark superscript symbol which does not meet our design specs. More of the implementation is inside the roboto-condensed-font-face.css file. */
    --roboto-condensed: 'Trademark', 'Roboto Condensed Regular', 'Source Sans 3 Regular', Arial, Helvetica, sans-serif;
    --roboto-condensed-bold: 'Trademark', 'Roboto Condensed Bold', 'Source Sans 3 Regular', Arial, Helvetica, sans-serif;
    /* Lora - Headers */
    --lora-medium-it: 'Lora Medium Italic', 'Source Sans 3 Regular', Arial, Helvetica, sans-serif;
    --lora-bold-it: 'Lora Bold Italic', 'Source Sans 3 Regular', Arial, Helvetica, sans-serif;

    --default-sans-serif: 'Source Sans 3 Regular', Arial, Helvetica, sans-serif;

    /* Font Weight Variables for adobe fonts */
    --font-weight-regular: 400;
    --font-weight-medium: 500;
    --font-weight-semibold: 600;
    --font-weight-bold: 700;

    /* Font Style Variables for adobe fonts */
    --font-style-normal: normal;
    --font-style-italic: italic;

        /*
            Tokenized Variable Names.
            NOTE: to make things less confusing during this transitional period, variable and class naming
            based on token will be a future enhancement for when pcl-ui-core becomes the new front-end standard.
            TODO: tokenize all variable and font classes based on categorical use case, similar to what's writting below this.
        */
    /*
     * Title text setting options
     */
    --title-font-family: var(--lora-medium-it);
    --title-style-normal: normal;
    --title-style-italic: italic;
    --title-weight: 400;
    --title-weight-bold: 700;
    --title-letter-spacing: 0;
    --title-line-height: 1.2;

    /*
     * Subtitle text setting options
     */
    --subtitle-font-family: var(--red-hat-text);
    --subtitle-style-normal: normal;
    --subtitle-style-italic: italic;
    --subtitle-weight-regular: 400;
    --subtitle-weight-bold: 700;
    --subtitle-letter-spacing: 0;
    --subtitle-line-height: 1.2;

    /*
     * Body Text Options
     */
    --body-font-family: var(--red-hat-text);
    --body-style-normal: normal;
    --body-style-italic: italic;
    --body-weight-regular: 400;
    --body-weight-bold: 700;
    --body-letter-spacing: 0;
    --body-line-height: 1.3;

    /*
     * Base font settings put on <body>
     */
    --base-font-size: 87.5%;                /* base font-size is 14px */
    --base-font-style: normal;
    --base-font-weight: 400;
    --base-line-height: 1.3;
    --body-letter-spacing: 0.1px;

    /*
     * Font scaling based on a base unit of 14px
     */
    --font-size-m3:  0.785714285714286em;   /*11px*/
    --font-size-m2:  0.857142857142857em;   /*12px*/
    --font-size-m1:  0.928571428571429em;   /*13px*/
    --font-size-0:   1em;                   /*14px*/
    --font-size-p1:  1.071428571428571em;   /*15px*/
    --font-size-p2:  1.142857142857143em;   /*16px*/
    --font-size-p3:  1.214285714285714em;   /*17px*/
    --font-size-p4:  1.285714285714286em;   /*18px*/
    --font-size-p5:  1.357142857142857em;   /*19px*/
    --font-size-p6:  1.428571428571429em;   /*20px*/
    --font-size-p7:  1.5em;                 /*21px*/
    --font-size-p8:  1.571428571428571em;   /*22px*/
    --font-size-p9:  1.642857142857143em;   /*23px*/
    --font-size-p10: 1.769230769230769em;   /*24px*/
    --font-size-p11: 1.846153846153846em;   /*25px*/
    --font-size-p12: 1.857142857142857em;   /*26px*/
    --font-size-p13: 1.928571428571429em;   /*27px*/
    --font-size-p14: 2em;                   /*28px*/
    --font-size-p15: 2.071428571428571em;   /*29px*/
    --font-size-p16: 2.142857142857143em;   /*30px*/
    --font-size-p17: 2.214285714285714em;   /*31px*/
    --font-size-p18: 2.285714285714286em;   /*32px*/
    --font-size-p19: 2.357142857142857em;   /*33px*/
    --font-size-p20: 2.428571428571429em;   /*34px*/
    --font-size-p21: 2.5em;                 /*35px*/
    --font-size-p22: 2.571428571428571em;   /*36px*/
    --font-size-p23: 2.642857142857143em;   /*37px*/



    /*
    * Branded colors
    */
    --primary-blue: #003595;                /** updated - name from primary and color */
    --primary-blue-dark: #002568;           /** updated - name from princess-blue-alt and color */
    --primary-dark: #020215;                /** updated - name from neutral-grey-dark and color */
    --primary-medium: #6C6C78;              /** new - name from neutral-grey-dark and color */
    --primary-light: #F2EFED;               /** updated - name from neutral-warm-light and color */
    --primary-extra-light: #F8F6F2;         /** added - name from neutral-warm-light and color */

    /*
     * Colors to communicate state
     */
    --primary-cta: linear-gradient(180deg, #1A5AD7, #003595);
    --primary-cta-hover: linear-gradient(180deg, #1A5AD7, #002568);
    --secondary-cta: linear-gradient(180deg, #ffffff, #f1f1f1);
    --secondary-cta-hover: linear-gradient(180deg, #ffffff, #f9f9f9);
    --success: #007a7c;                     /** added */
    --success-dark: #035c67;                /** added */
    --caution: #d4673c;                     /** added */
    --caution-dark: #b54d24;                /** added */
    --error: #b92d2d;                       /** added */
    --error-dark: #9a2626;                  /** added */
    --error-bg: #fdfce3;                    /** updated - name from bg-error */
    --inactive: rgba(255, 255, 255, .7);   /** added - name from bg-error */

    /*
     * Colors to communicate loyality level status
     */
    --loyalty-blue: #003595;           /** updated */
    --loyalty-gold: #cc9f53;           /** updated */
    --loyalty-ruby: #8e1537;           /** updated */
    --loyalty-platinum: #7b868c;       /** updated */
    --loyalty-elite: #000000;          /** updated */

    /*
     * Accent Treatments
     */
    --accent-backdrop: #787c86;             /** added */
    --accent-form: #e5f3fd;                 /* updated - name from bg-form */
    --accent-gold: #cc9f53;                 /** added */
    --accent-silver: #cdd1d5;               /** added */
    --accent-pink: #EA0063;                 /** added */
    --accent-pink-dark: #D7005B;

    /*
     * Transparency treatments
     */
    --transparent-dark: rgba(2, 2, 21, .7);                     /* added */
    --transparent-medium: rgba(2, 2, 21, .5);              /* added */
    --transparent-light: rgba(255, 255, 255, .7);               /* added */
    --transparent-glow: radial-gradient(50% 71.67% at 50% 100%, rgba(195, 186, 180, 0.4) 0%, rgba(242, 239, 237, 0.2) 89.06%);          /** added */
    --transparent-wave: radial-gradient(128.8% 146.38% at 8.67% 69.32%, rgba(195, 186, 180, 0.16) 0%, rgba(242, 239, 237, 0.4) 100%);   /** added */
    --transparent-glass: linear-gradient(333.35deg, rgba(255, 255, 255, 0.3) 31.34%, rgba(255, 255, 255, 0.2) 75.44%);                  /** added */

    /*
     * Auxiliary colors to fill gaps in old colors that are not available in new brand colors
     */
    --auxiliary-blue-dark: #09213d;     /** updated - name from bg-extra-dark and color */
    --auxiliary-blue: #1A5AD7;     /** updated - name from bg-extra-dark and color */
    --auxiliary-blue-light: #e5f3fd;    /** updated - name from bg-light and color */
    --auxiliary-blue-extra-light: #f7fbfe;    /** updated - name from bg-light and color */
    --auxiliary-green: #6d9d5b;                   /** updated - color */
    --auxiliary-green-light: #EBF3F3;
    /*--auxiliary-green-dark: #035c67;              /** updated - name from secondary-dark and color */
    --auxiliary-red: #af3636;                        /** added */
    --auxiliary-red-dark: #912d2d;                   /** added */
    --auxiliary-orange: #FCB215;                   /** added */
    --auxiliary-secondary-blue: #009dd2;

    /*
     * Neutrals colors for backgrounds, borders, and text treatments
     */
    --neutral-black: #000;                  /* keep */
    --neutral-white: #fff;                  /* keep */

    --neutral-dark: #333537;                /* keep */
    --neutral-medium: #cccccc;              /* updated - name from neutral-grey and color */
    --neutral-light: #e2e3e4;               /* updated - name from neutral-header-bg and color */

    --neutral-cool-dark: #54575a;           /* keep */
    --neutral-cool-medium: #939598;         /* updated - name from neutral-cool-medium and color */
    --neutral-cool-light: #f1f1f1;          /* keep */
    --neutral-cool-extra-light: #f9f9f9;    /* keep */

    --neutral-warm-medium: #eae5dc;          /* updated - color */
    --neutral-warm-light: #f3efee;           /* updated - color */

    /*
     * Removed variable names

    --ocean-blue: #003087;                    removing
    --font-blue: #0054A0;                     removing
    --primary: #6d9d5b;                       removing
    --primary-dark: #587f49;                  removing
    --primary-alt: #4a6b3e;                   removing
    -secondary: #cc9f53;                      removing - color
    -secondary-dark: #b18234;                 removing - name from secondary-alt and color
    -primary-extra-dark: #09213d;          removing - name from princess-blue-alt and color
    -primary-extra-light: #f7fbfe;         removing - name from bg-form
    --bg-extra-dark: #09213d;              removing
    --bg-dark: #0d2f56;                    removing
    --bg-form: #e5f3fd;                    removing
    --bg-light: #f7fbfe;                   removing
    --bg-light-alt: #ebf3f3;               removing


    /*
     * Design Treatments
     */
    --border-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    --top-box-shadow: 0px -4px 9px -3px #00000033;
    --btm-box-shadow: 0px 5px 6px #0000004D;

    --size-display-headline-small: 44px;
    --size-display-headline-xsmall: 32px;
}

@media (max-width: 600px) {
    :root {
        --size-display-headline-small: 32px;
        --size-display-headline-xsmall: 24px;
    }
}
