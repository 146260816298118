/*
 * Info Bar at top of page, also used for violator bar
 */
.info-bar-wrapper {
    background: #333;
    position: relative;
    width: 100%;
    z-index: 200;
}
.info-bar {
    color: #e6e6e6;
    display: block;
    font-size: 1.076923076923077em; /* 14px */
    padding: .5em 1em .5em;
    text-align: center;
    text-decoration: none;
    width: 100%;
}
.info-bar:hover,
.info-bar:focus {
    text-decoration: underline;
}
.info-bar:focus {
    outline-offset: -3px;
    outline-color: rgba(255,255,255,0.55);
}
.info-bar-wrapper.lto-promo {
    background: #c60006;
}
.info-bar-wrapper.lto-promo .info-bar {
    color: #fff;
}
/* turn off other info-bars that might be on the page when the violator bar is displayed */
.info-bar-wrapper.lto-promo ~ .info-bar-wrapper {
    display: none;
}
@media (min-width: 480px) {
    .info-bar {
        font-size: 1.230769230769231em; /* 16px */
    }
}
@media (min-width: 60em) {
    .info-bar {
        font-size: 1.384615384615385em; /* 18px */
    }
}
/* extra spacing for "button" in violator bar */
.lto-btn {
    padding: 0 .5em;
}
