@charset "utf-8";
/*
 * Hide the old action toolbar for any pages that might still have the markup
 */
.actions-widget {
    display: none !important;
    visibility: hidden !important;
}

/*
 * Action Toolbar CSS
 */
.action-toolbar-wrapper {
    bottom: 0;
    color: white;
    font-family: var(--red-hat-text);
    left: 0;
    margin: 0 auto;
    min-width: 320px;
    position: fixed;
    right: 0;
    width: 100%;
    z-index: 1000;
}
.action-toolbar-row {
    background-color: #000;
    margin: 0 auto;
    max-width: 1700px;
    position: relative;
    text-align: center;
}
.action-toolbar {
    position: relative;
    z-index: 1000;
    margin: 0 auto;
}
/* action toolbar menu layout */
.action-toolbar-menu, .action-toolbar-share-menu {
    padding: 0;
    margin: 0;
    font-size: 0;
}
.action-toolbar-menu-lowered {
    position: absolute;
    bottom: -62px;
}
.action-toolbar-item, .action-toolbar-share-item {
    display: inline-block;
    font-size: .875rem;
    letter-spacing: 0;
}
.action-toolbar-link, .action-toolbar-share-link {
    background: transparent;
    border: none;
    color: #fff;
    display: inline-block;
    margin: 0;
    text-align: left;
    text-decoration: none;
}
.action-toolbar-link {
    padding: 5px 4px;
    letter-spacing: 1px;
}
.action-toolbar-share-link {
    padding: 5px;
}
/* increase the padding on the action-toolbar-link when space permits */
@media (min-width: 22.5em) {
    .action-toolbar-link {
        padding-left: 8px;
        padding-right: 8px;
    }
}
/* increase the padding on the action-toolbar-link when space permits */
@media (min-width: 26.25em) {
    .action-toolbar-link {
        padding-left: 15px;
        padding-right: 15px;
    }
}
.action-toolbar-link:hover, .action-toolbar-share-link:hover,
.action-toolbar-link:focus, .action-toolbar-share-link:focus {
    background-color: #333;
}
.action-toolbar-chat-link.chat-is-online,
.action-toolbar-brochure-link {
    background: #2a8338;
}
.action-toolbar-chat-link.chat-is-online:hover, .action-toolbar-chat-link.chat-is-online:focus,
.action-toolbar-brochure-link:hover, .action-toolbar-brochure-link:focus {
    background: #21682d;
}
.action-toolbar-feedback-link {
    background: #0054A0;
}
.action-toolbar-feedback-link:hover, .action-toolbar-feedback-link:focus {
    background: #004178;
}
.action-toolbar-item + .action-toolbar-item {
    border-left: 1px solid #fff;
}
.action-toolbar-item.first-visible {
    border-left: none;
}
.action-toolbar-share-icon {
    height: 18px;
    margin-top: -2px;
}
/* action toolbar icons */
.action-toolbar-icon {
    margin-left: 0;
    display: none;
    visibility: hidden;
}
@media print {
    .action-toolbar-wrapper{
        display: none !important;
        visibility: hidden;
    }
}
/* turning this off for now, since only Search & Booking flow using the chat button.
   will need to revisit when the chat button is turned on for AU or US.
@media screen and (max-width: 35.4375em) {
    /* make it invisible for small screens
    .action-toolbar-text > .action-toolbar-secondary-text {
        height: 1px; margin: -1px; overflow: hidden; padding: 0; position: absolute; width: 1px;
    }
}
*/
/* action toolbar share menu layout */
.action-toolbar-wrapper .action-toolbar-share-btn {
    font-family: var(--red-hat-text);
    letter-spacing: 1px;
    line-height: 1.38461538461538;
}
@media screen and (max-width: 47.9375em) {
        .action-toolbar-menu {
            transition: padding-bottom .5s;
            display: flex;
        }
        .action-toolbar-menu-lowered {
            position: initial;
            bottom: initial;
        }
        .action-toolbar-item {
            flex-grow: 1;
        }
        .action-toolbar-link {
            width: 100%;
            text-align: center;
        }
        .action-toolbar-secondary-text {
            display: none;
        }
    .action-toolbar-share-expanded {
        padding-bottom: 29px;
    }
    .action-toolbar-share-menu {
        background: #333;
        height: 0;
        left: 0;
        overflow: hidden;
        position: absolute;
        top: 29px;
        transition: height .5s;
        width: 100%;
    }
    .action-toolbar-share-expanded .action-toolbar-share-menu {
        height: 29px;
    }
    .action-toolbar-share-expanded .action-toolbar-share-btn {
        background: #333;
    }
}
@media screen and (min-width: 48em) {
    .action-toolbar-share-btn {
        padding-right: 0;
    }
    .action-toolbar-share-btn:hover {
        background: none;
        cursor: default;
    }
    .action-toolbar-share-menu {
        display: inline-block;
    }
}
/* action toolbar display button */
.action-toolbar-btn {
    background-color: #000;
    border: none;
    border-radius: 3px 3px 0 0;
    bottom: 0;
    color: #fff;
    margin: 0;
    font-size: 1.07692307692308em;
    padding: 2px 15px;
    position: absolute;
    text-align: center;
    z-index: 1000;
}
/* for screens smaller than a tablet align action-toolbar-btn on the left side of the screen & hide text */
@media screen and (max-width: 47.9375em) {
        .action-toolbar-btn {
            display: none;
        }
    .action-toolbar-btn-text {
        height: 1px; margin: -1px; overflow: hidden; padding: 0; position: absolute; width: 1px;
    }
}
/* for tablet and desktop align action-toolbar-btn on the right side of the screen */
@media screen and (min-width: 48em) {
    .action-toolbar-btn {
        right: 10px;
    }
}
.action-toolbar-btn:hover {
    background-color: #333;
}
.action-toolbar-btn > span {
    display: block;
    font-family:  var(--red-hat-text);
    margin: 0 auto;
}
.action-toolbar-btn-icon {
    transform: rotate(90deg);
    width: 15px;
}
/* apply spacing between icon and hide text */
.action-toolbar-btn-icon + .action-toolbar-hide-text {
    margin-top: .25em;
    padding-bottom: .25em;
}
/* turn off show text by default */
.action-toolbar-btn > .action-toolbar-show-text {
    display: none;
}
    /* update the display of the action-toolbar-btn when the menu has a class of action-toolbar-menu-lowered */
    .action-toolbar-menu-lowered + .action-toolbar-btn > .action-toolbar-show-text, .action-toolbar-menu-lowered + .action-toolbar-btn > .action-toolbar-btn-icon {
        display: block;
    }
    .action-toolbar-menu-lowered + .action-toolbar-btn > .action-toolbar-btn-icon {
        margin: 0 auto;
        transform: rotate(-90deg);
    }
.action-toolbar-menu-lowered + .action-toolbar-btn > .action-toolbar-hide-text {
    display: none;
    margin-top: 0;
    padding-bottom: 0;
}
/* rules to control the display of buttons in the toolbar */
.disable-toolbar .action-toolbar-wrapper,
.disable-toolbar-share .action-toolbar-share,
.disable-toolbar-brochure .action-toolbar-brochure,
.disable-toolbar-chat .action-toolbar-chat,
.disable-toolbar-contact .action-toolbar-contact,
.disable-toolbar-feedback .action-toolbar-feedback {
    display: none;
}
.qualtrics-feedback .mfp-content {
    min-height: 2500px;
}

.qualtrics-feedback .mfp-content, .qualtrics-feedback .mfp-close-wrapper {
    max-width: 640px;
}
.qualtrics-feedback .mfp-close-wrapper {
    padding-left: 15px;
    padding-right: 15px;
    margin: 0 auto;
}
@media (min-width: 410px) {
    .qualtrics-feedback .mfp-content {
        min-height: 2250px;
    }
}

@media (min-width: 670px) {
    .qualtrics-feedback .mfp-close-wrapper {
        padding-left: 0;
        padding-right: 0;
    }
    .qualtrics-feedback .mfp-content {
        min-height: 1900px;
    }
   
}
