@charset "utf-8";

/*
 * Horizontal Carousel
 */
.horz-carousel-container {
    padding: 15px 0;
}
.horz-carousel-row {
    position: relative;
    padding: 15px 40px;
    max-width: 1040px;
    margin: 0 auto;
}
.horz-carousel-row.not-ready {
    background: url(/images/global/ajax_loader.gif) no-repeat center center;
    min-height: 180px;
}
.horz-carousel-row.not-ready .horz-carousel-item, .horz-carousel-row.not-ready .horz-carousel-controls {
    display: none;
}
.horz-carousel {
    overflow: hidden;
    position: relative;
    max-width: 960px;
    width: 100%;
}
.horz-carousel-item {
    overflow: hidden;
    padding: 0 5px;
    text-align: center;
    display: inline-block;
    float: none;
}
.horz-carousel-item > a {
    display: block;
    white-space: normal;
}
.horz-carousel-thumb {
    position: relative;
}
.horz-carousel-thumb > img {
    border: 1px solid #999999;
    max-width: 100%;
}
.horz-carousel-controls {
    background: rgba(0,84,160,.3);
    bottom: 50%;
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr='#AA0054a0', endColorstr='#AA0054a0');
    border: 0;
    cursor: pointer;
    height: 64px;
    margin-bottom: -32px;
    padding: 5px;
    position: absolute;
    width: 29px;
    display: none;
}
.horz-carousel-controls:hover  {
    background: rgba(66,114,158,.4);
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr='#AA42729e', endColorstr='#AA42729e');
}
.horz-carousel-prev {
    left: 5px;
}
.horz-carousel-next {
    right: 5px;
}
.horz-carousel-controls .arrow {
    background-image: url(/images/home/arrows.png);
    height: 100%;
    width: 100%;
}
.horz-carousel-prev .arrow {
    background-position: top left;
    margin: 0;
}
.horz-carousel-next .arrow {
    background-position: top right;
    margin: 0;
}