/*
    Comprehensive list of all font-face definitions for
    Roboto Condensed font family. This font is only used for instances of tight spacing.
*/
@font-face {
    font-family: 'Roboto Condensed Regular';
    src: url('/css/global/fonts/roboto-condensed/ttf/RobotoCondensed-Regular.ttf');
    font-weight: 400;
    font-display: swap; 
}
@font-face {
    font-family: 'Roboto Condensed Bold';
    src: url('/css/global/fonts/roboto-condensed/ttf/RobotoCondensed-Bold.ttf');
    font-weight: 600;
    font-display: swap; 
}