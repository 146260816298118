/*
    Comprehensive list of all font-face definitions for
    Source Sans 3 Font Family. This font is primarily used as a backup font
    for unsupported superscripts by our primary fonts.
*/
@font-face {
    font-family: 'Source Sans 3 Regular';
    src: url('/css/global/fonts/source-sans-3/ttf/SourceSans3-Regular.ttf');
    font-weight: 400;
    font-display: swap;
}

/* Handles overwriting the fallback of not using Roboto Condensed as font family for TrademarkⓇ Symbol, which is way too big. */
@font-face {
    font-family: 'Trademark';
    src: url('/css/global/fonts/source-sans-3/ttf/SourceSans3-Regular.ttf');
    unicode-range: U+00AE;
}