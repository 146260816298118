/*
    Comprehensive list of all font-face definitions for
    Red Hat Text font family. This font is used for a majority of the other use cases
    like body texts, subtitles, etc.
*/
@font-face {
    font-family: 'Red Hat Text Regular';
    src: url('/css/global/fonts/red-hat-text/ttf/RedHatText-Regular.ttf');
    font-weight: 400;
    font-display: swap;
}
@font-face {
    font-family: 'Red Hat Text Italic';
    src: url('/css/global/fonts/red-hat-text/ttf/RedHatText-Italic.ttf');
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: 'Red Hat Text Bold';
    src: url('/css/global/fonts/red-hat-text/ttf/RedHatText-SemiBold.ttf');
    font-weight: 600;
    font-display: swap; 
}
@font-face {
    font-family: 'Red Hat Text Bold Italic';
    src: url('/css/global/fonts/red-hat-text/ttf/RedHatText-SemiBoldItalic.ttf');
    font-weight: 600;
    font-style: italic;
    font-display: swap; 
}
@font-face {
    font-family: 'Red Hat Text Medium';
    src: url('/css/global/fonts/red-hat-text/ttf/RedHatText-Medium.ttf');
    font-weight: 500;
    font-display: swap;
}
@font-face {
    font-family: 'Red Hat Text Medium Italic';
    src: url('/css/global/fonts/red-hat-text/ttf/RedHatText-MediumItalic.ttf');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}