/*
    Comprehensive list of all font-face definitions for
    Lora font family. Lora is only used for Headers, consistently being italicized.
*/
@font-face {
    font-family: 'Lora Medium Italic';
    src: url('/css/global/fonts/lora/static/Lora-MediumItalic.ttf');
    font-weight: 400;
    font-display: swap; 
}
@font-face {
    font-family: 'Lora Bold Italic';
    src: url('/css/global/fonts/lora/static/Lora-SemiBoldItalic.ttf');
    font-weight: 500;
    font-display: swap; 
}

/* TODO: necessary?
   Set up Lora variable font for modern browsers */
@font-face {
    font-family: 'Lora';
    src: url('/css/global/fonts/lora/variable/Lora-VariableFont_wght.ttf') format('tff'),
         url('/css/global/fonts/lora/variable/Lora-Italic-VariableFont_wght.ttf') format('tff');         
    font-weight: 400 700; /* NUMERIC_RANGE_LOW_TO_HIGH */        
    font-display: swap;  
}

