/*
    Comprehensive list of all the font-face definitions for
    Domaine Display font family. This font is used for a majority of the other use cases
    like body texts, subtitles, etc.
*/
@font-face {
    font-family: 'Domaine Display Regular';
    src: url('/css/global/fonts/domaine-display/ttf/DomaineDisplay-Regular.ttf');
    font-weight: 400;
    font-display: swap;
}
@font-face {
    font-family: 'Domaine Display Medium';
    src: url('/css/global/fonts/domaine-display/ttf/DomaineDisplay-Medium.ttf');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: 'Domaine Display Bold';
    src: url('/css/global/fonts/domaine-display/ttf/DomaineDisplay-Bold.ttf');
    font-weight: 700;
    font-display: swap;
}
