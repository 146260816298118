/*
 * Tab Navigation for v4 Page Templates
 */

/* Outer Wrappers & Containers*/
.tab-nav-wrapper {
    font-family: var(--gotham-xn-medium);
    letter-spacing: 0.76px;
    margin: auto;
    max-width: 100vw;
    position: relative;
}
.article-tabs .tab-nav-wrapper {
    padding: 0;
    padding-top: 15px;
    background-color: var(--neutral-cool-light); /*var updated*/
}
.mfp-content .tab-nav-wrapper {
    left: 0;
    margin-left: 0;
    max-width: 960px;
    width: 100%;
}

.tab-nav-row {
    text-align: center;
    position: relative;
    overflow: hidden;
    margin: auto;
}

.article-tabs .tab-nav-row {
    overflow: visible;
}

.tab-nav-menu {
    font-size: 0;
    padding-left: 0;
    white-space: nowrap;
    overflow: visible;
    display: inline-block;
}
.tab-nav-menu.scrollable {
    padding-left: 32px;
}
.tab-nav-menu.invisible {
    opacity: 0; /* still makes it invisible while the formatting is resolve */
    visibility: visible; /*overrides the default style for invisible */
}

/* Item Wrappers */
.tab-nav-item {
    border-radius: 10px 10px 0px 0px;
    position: relative;
    margin-left: 10px;
    font-size: 16px;
    display: inline-block;
    background: rgba(255,255,255,0.3);
    box-shadow: var(--top-box-shadow);
    color: var(--primary-blue); /*var updated*/
}
/* Removes empty tabs given certain scenarios where they are not populated */
li.tab-nav-item:empty {
    display: none;
    visibility: hidden;
}
.tab-nav-item:hover {
    background: var(--neutral-cool-extra-light); /*var updated*/
}
.tab-nav-item:hover > .tab-nav-link .tab-nav-text {
    text-decoration: none;
}
.tab-nav-item.active,
.tab-nav-item.expanded{
    font-family: var(--roboto-condensed);
    background: var(--neutral-white); /*var updated*/
    text-decoration: none;
}

/* Inner Spans */
.tab-nav-item span:first-child {
    font-size: 15px;
    text-decoration: underline;
}
.tab-nav-item .active > span {
    color: var(--primary-dark); /*var updated*/
    text-decoration: none;
}

/* Inner Links */
.tab-nav-link {
    color: var(--primary-blue); /*var updated*/
    display: block;
    padding: 0.3125em 0.9375em;
    text-decoration: none;
    border-radius: inherit;
}
.article-tabs .tab-nav-link {
    padding: 15px;
}
.transparent-tab-nav .tab-nav-link {
    background-color: transparent;
    color: var(--primary-blue); /*var updated*/
}
.tab-nav-item > .tab-nav-link.active,
.tab-nav-item.expanded > .tab-nav-link {
    z-index: 11;
    background: var(--neutral-white); /*var updated*/
    color: var(--primary-dark); /*var updated*/
}
.tab-nav-item > .tab-nav-link.active:hover {
    background: var(--neutral-white); /*var updated*/
}
.transparent-tab-nav .tab-nav-item > .tab-nav-link.active {
    background-color: var(--neutral-medium); /*var updated*/
    color: var(--neutral-white); /*var updated*/
}
.tab-nav-menu .tab-subnav-menu .tab-nav-link {
    width: 100%;
    margin: auto;
}
.tab-nav-menu .has-subnav-menu .tab-nav-link[aria-expanded="true"] {
    border-radius: 10px 10px 0 0;
    background: var(--neutral-white); /*var updated*/
}
.tab-subnav-menu .tab-nav-link .tab-nav-text{
    font-size: 14px;
}
.tab-nav-text {
    position: relative;
    color: inherit;
}

/* Mobile */
@media screen and (max-width: 767px) {
    .tab-nav-wrapper {
        padding: 0;
    }
    .article-tabs .tab-nav-wrapper{
        background-color: var(--neutral-white); /*var updated*/
    }
    .tab-nav-menu {
        margin: auto;
    }
    .article-tabs .tab-nav-menu {
        display: block;
    }
    .tab-nav-menu .tab-nav-item {
        display: block;
        padding: 5px 10px 5px 10px;
        box-shadow: none;
        border-radius: 0;
        border-bottom: 2px solid var(--neutral-white); /*var updated*/
        background-color: var(--neutral-cool-light); /*var updated*/
        margin: 0;
    }

    .tab-nav-item span {
        font-size: 16px;
    }
    .article-tabs .tab-nav-menu .tab-nav-link {
        border-radius: 10px;
        padding-bottom: 5px;
        padding-top: 5px;
        margin: auto;
        max-width: 300px
    }
    .tab-nav-item > .tab-nav-link.active,
    .tab-nav-item.expanded > .tab-nav-link {
        box-shadow: 0px 0px 6px #00000033;
    }
    .tab-nav-item.expanded > .tab-nav-link {
        border-radius: 10px 10px 0 0;
    }
    .tab-subnav-menu .tab-subnav-item {
        border-radius: 0;
    }
    .tab-nav-menu.expanded > .tab-nav-link[aria-expanded="true"] {
        box-shadow: 0px 0px 6px #00000033;
    }
    /* setting the tab-nav-row min-height to 42px to eliminate page bounce when tab-nav-links are loaded with p13n */
    .article-tabs .tab-nav-row {
        min-height: 40px;
    }
}
/* What are these for? */
/*@media screen and (min-width: 106.25em) {*/
/*    .tab-nav-wrapper {*/
/*        margin-left: -850px;*/
/*    }*/
/*}*/
/*@media screen and (max-width: 47.9375em) {*/
/*    .tab-nav-wrapper {*/
/*        padding-left: 10px;*/
/*        padding-right: 10px;*/
/*    }*/
/*}*/
@media screen and (min-width: 48em) {
    .tab-nav-row.scrollable {
        padding-right: 52px;
    }
    /* setting the tab-nav-row min-height to 42px to eliminate page bounce when tab-nav-links are loaded with p13n */
    .article-tabs .tab-nav-row {
        min-height: 40px;
    }
}

/* scroll buttons */
.tab-nav-scroll {
    background-color: var(--neutral-medium); /*var updated*/
    border: none;
    height: 100%;
    margin: 0;
    overflow: hidden;
    padding-left: 0;
    padding-right: 0;
    position: absolute;
    top: 0;
    width: 30px; /* matches the padding on the wrapper */
    z-index: 15;
}
.transparent-tab-nav .tab-nav-scroll {
    background-color: #f1f1f1;
}
.tab-nav-scroll:hover, .tab-nav-scroll:focus {
    background-color: var(--neutral-cool-dark); /*var updated*/
    color: #fff;
}
.tab-nav-scroll .pc-icons {
    display: block;
    height: 24px;
    margin: 0 8px;
    width: 13px;
}
.scroll-nav-left .svg-icon-chevron {
    transform: rotate(180deg);
}
.scroll-nav-left .pc-icons {
    background-position: -318px -182px;
}
.scroll-nav-right .pc-icons {
    background-position: -300px -182px;
}
.transparent-tab-nav .scroll-nav-left > .pc-icons {
    background-position: -354px -182px;
}
.transparent-tab-nav .scroll-nav-right > .pc-icons {
    background-position: -336px -182px;
}
.transparent-tab-nav .scroll-nav-left:hover > .pc-icons,
.transparent-tab-nav .scroll-nav-left:focus > .pc-icons {
    background-position: -318px -182px;
}
.transparent-tab-nav .scroll-nav-right:hover > .pc-icons,
.transparent-tab-nav .scroll-nav-right:focus > .pc-icons {
    background-position: -300px -182px;
}
.scroll-nav-left {
    left: 0;
}
.scroll-nav-right {
    right: 0;
}

/* Second level of tab nav, definitions for dropdown elements.*/
.tab-subnav-wrapper {
    background: var(--neutral-white); /*var updated*/
    border-radius: 0 0 10px 10px;
    padding: 10px;
    left: 50%;
    margin-left: -150px;
    position: absolute;
    top: -999em;
    z-index: 500;
    width: 300px;
}
.tab-subnav-menu {
    list-style: none;
    margin: 0 auto;
    padding: 0;
    color: #000;
}
.tab-subnav-item {
    background: var(--neutral-cool-light); /*var updated*/
}
.tab-subnav-item:hover {
    background: var(--neutral-cool-extra-light); /*var updated*/
}

.tab-subnav-item + .tab-subnav-item {
    border-top: 1px solid #d7d7d7;
}

.tab-subnav-item > .tab-nav-link {
    color: var(--primary-blue); /*var updated*/
    white-space: nowrap;
    padding-bottom: .3125em;
    padding-top: .3125em;
    margin-left: 8px;
    margin-right: 8px;
    border-radius: 10px;
    width: 100%;
    text-decoration: none;
}

.tab-nav-item.expanded > .tab-subnav-wrapper {
    box-shadow: var(--btm-box-shadow);
    top: inherit;
}

/* arrow to indicate second level of tab nav */
.tab-nav-item .has-subnav-menu {
    padding-right: 30px;
}

/* Logic for subtab nav menu for displaying/hiding close icon vs. chevron */
.tab-nav-link .icon-down-arrow,
.tab-nav-link .icon-close-x {
    position: absolute;
}
.has-subnav-menu.tab-nav-link[aria-expanded="false"] .icon-close-x {
    display: none;
}
.has-subnav-menu.tab-nav-link[aria-expanded="true"] .icon-close-x {
    display: inline-block;
}
.has-subnav-menu.tab-nav-link[aria-expanded="false"] .icon-down-arrow {
    display: inline-block;
}
.has-subnav-menu.tab-nav-link[aria-expanded="true"] .icon-down-arrow {
    display: none;
}
.no-js .tab-nav-item:hover .has-subnav-menu .tab-nav-text:after,
.no-js .tab-nav-item:focus .has-subnav-menu .tab-nav-text:after,
.tab-nav-item.expanded .has-subnav-menu .tab-nav-text:after {
    border-top-color: #fff;
}