    /*
        Comprehensive list of all font-face definitions for
        the Proxima Nova font family. This font is used for a majority of the other use cases
        like body texts, subtitles, etc.
    */
    @font-face {
        font-family: 'Proxima Nova';
        src: url('/css/global/fonts/proxima-nova/ttf/ProximaNova-Regular.ttf') format('truetype');
        font-weight: 400;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Proxima Nova Italic';
        src: url('/css/global/fonts/proxima-nova/ttf/ProximaNova-Italic.ttf') format('truetype');
        font-weight: 400;
        font-style: italic;
        font-display: swap;
    }

    @font-face {
        font-family: 'Proxima Nova Medium';
        src: url('/css/global/fonts/proxima-nova/ttf/ProximaNova-Medium.ttf') format('truetype');
        font-weight: 500;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Proxima Nova Medium Italic';
        src: url('/css/global/fonts/proxima-nova/ttf/ProximaNova-MediumItalic.ttf') format('truetype');
        font-weight: 500;
        font-style: italic;
        font-display: swap;
    }

    @font-face {
        font-family: 'Proxima Nova Semibold';
        src: url('/css/global/fonts/proxima-nova/ttf/ProximaNova-Semibold.ttf') format('truetype');
        font-weight: 600;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Proxima Nova Semibold Italic';
        src: url('/css/global/fonts/proxima-nova/ttf/ProximaNova-SemiboldItalic.ttf') format('truetype');
        font-weight: 600;
        font-style: italic;
        font-display: swap;
    }

    @font-face {
        font-family: 'Proxima Nova Bold';
        src: url('/css/global/fonts/proxima-nova/ttf/ProximaNova-Bold.ttf') format('truetype');
        font-weight: 700;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Proxima Nova Bold Italic';
        src: url('/css/global/fonts/proxima-nova/ttf/ProximaNova-BoldItalic.ttf') format('truetype');
        font-weight: 700;
        font-style: italic;
        font-display: swap;
    }